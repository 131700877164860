import {GRAPHQL_AUTH_MODE} from "@aws-amplify/auth";
import {isAuthenticated} from "api/auth";
import {API, graphqlOperation} from 'aws-amplify'
import * as customQueries from "./customQueries/index";
import * as mocks from '../mocks'
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import * as subscriptions from 'graphql/subscriptions';
import * as models from 'models'
import {schema as modelSchema} from 'models/schema'
import {errorAlert, successAlert} from '../alerts'

export default class Api {
   constructor(modelName) {
      const {
         model,
         booleans,
         additionalFields
      } = models[modelName]
      const {
         fields,
         pluralName
      } = modelSchema['models'][modelName]

      this._name = modelName
      this._pluralName = pluralName
      this._model = model
      this._fields = {...fields, ...additionalFields}
      this._booleans = booleans ?? {}
      this._searchQuery = queries[`search${this._pluralName}`]
      this._mock = mocks[modelName] ?? null
   }

   getFields(includeMock = false) {
      const fields = Object.keys(this._fields)
         .reduce((carry, field) => {
            if (['String', 'Int'].includes(this._fields[field].type)) {
               carry[field] = this._fields[field].type === 'String'
                  ? ''
                  : 0
            }

            return carry
         }, {})

      const fieldsWithBooleans = Object.keys(this._booleans)
         .reduce((carry, field) => {
            carry[field] = this._booleans[field]

            return carry
         }, fields)

      return includeMock
         ? {...fieldsWithBooleans, ...this._mock}
         : fieldsWithBooleans
   }

   getFieldType(field) {
      return `${this._fields[field]?.type}${this._fields[field]?.isArray ? " Array" : ""}` ?? "null"
   }

   getFieldRequired(field) {
      return this._fields[field]?.isRequired
   }

   getMock() {
      return this._mock
   }

   getInitialValues(id) {
      if (!!id) {
         return this.getById(id)
            .then((record) => {
               const {
                  _version,
                  _deleted
               } = record

               const initialValues = Object.keys(this._fields)
                  .reduce((carry, field) => {
                     if (['ID', 'String', 'Int', 'AWSDateTime'].includes(this._fields[field].type)) {
                        carry[field] = record[field]
                     }

                     return carry
                  }, {
                     _version,
                     _deleted
                  })

               return Object.keys(this._booleans).reduce((carry, field) => {
                  carry[field] = record[field]

                  return carry
               }, initialValues)
            })
      }

      return Promise.resolve(this.getFields())
   }

   async getById(id, contain = []) {
      return this.runQuery(`get${this._name}`, {id}, "GET", contain)

   }

   async getByField(field, value, {
      limit = 100,
      nextToken = null,
      contain = []
   } = {
      limit: 100,
      nextToken: null,
      contain: []
   }) {
      return this.runQuery(`list${this._pluralName}`, {
         filter: {[field]: {eq: value}},
         limit,
         nextToken
      }, "LIST", contain)
   }

   async getList({
      filter,
      limit = 100,
      nextToken = null,
      contain = []
   } = {
      limit: 100,
      nextToken: null,
      contain: []
   }) {
      return this.runQuery(`list${this._pluralName}`, {
         filter,
         limit,
         nextToken
      }, "LIST", contain)
   }

   async mutate(values, contain = [], showAlert = "true") {
      const {
         createdAt,
         updatedAt,
         _deleted,
         _lastChangedAt,
         __typename,
         ...input
      } = values

      const queryName = input?.id
         ? `update${this._name}`
         : `create${this._name}`

      return this.runQuery(queryName, {input}, "MUTATION", contain, showAlert)
   }

   async remove({
      id,
      _version
   }, contain = [], showAlert = "true") {
      return this.runQuery(`delete${this._name}`, {
         input: {
            id,
            _version
         }
      }, "MUTATION", contain, showAlert)
   }

   async subscribe(action, filter = {}) {
      const authMode = await this.getAuthMode();

      const subscription = API
         .graphql({
            ...graphqlOperation(subscriptions[`on${action}${this._name}`], {filter}),
            authMode
         })
         .subscribe({
            next: ({
               provider,
               value
            }) => console.log({
               provider,
               value
            }),
            error: (error) => console.warn(error)
         })

      return subscription.unsubscribe();
   }

   async search(variables = {}) {
      const authMode = await this.getAuthMode();

      return API
         .graphql({
            ...graphqlOperation(this._searchQuery, {
               ...variables
            }),
            authMode
         })
         .then(({data}) => data[`search${this._pluralName}`].items)
   }

   async runQuery(queryName, variables = {}, type = "QUERY", contain = [], showAlert = "true") {
      variables = this.addDeletedFilter(variables)

      const queryArray = this.getQueryArray(type);

      const query = contain.reduce((query, child) => {
         query = this.combineQueries({
            model: this._name,
            queryName,
            query
         }, {
            ...child,
            query: queryArray[child.queryName]
         })

         return query
      }, queryArray[queryName])

      try {
         const options = {
            query,
            variables,
            authMode: await this.getAuthMode()
         }

         switch (type) {
            case "LIST":
               return this.runListQuery(options, queryName)
            case "MUTATION":
               return this.runMutation(options, queryName, showAlert)
            case "GET":
            default:
               return this.runGetQuery(options, queryName)
         }
      }
      catch (error) {
         console.error(error)
         if (showAlert) {
            errorAlert(error)
         }
         return error
      }
   }


   async runCustomQuery(queryName, variables = {}, includeNextToken = false) {
      variables = this.addDeletedFilter(variables)

      try {
         const {
            query,
            queryKey,
            type
         } = customQueries[queryName]
         const options = {
            query,
            variables,
            authMode: await this.getAuthMode()
         }

         return type === 'GET'
            ? this.runGetQuery(options, queryKey)
            : this.runListQuery(options, queryKey, includeNextToken)
      }
      catch (error) {
         console.error(error)
         errorAlert(error)

         return error
      }
   }

   async runGetQuery(options, queryKey) {
      return await API
         .graphql(options)
         .then(({data: {[queryKey]: data}}) => {
            return data
         })
   }

   async runListQuery(options, queryKey, includeNextToken) {
      let limit = options.variables?.limit ?? 100
      let results = []

      while (results.length < limit) {
         const newResults = await API
            .graphql(options)
            .then(({
               data: {
                  [queryKey]: {
                     nextToken,
                     items
                  }
               }
            }) => {
               options.variables.nextToken = nextToken

               return items
            })

         results = [
            ...results,
            ...newResults
         ]

         if (!options.variables.nextToken) {
            break
         }
      }

      if (includeNextToken) {
         return {
            list: results,
            nextToken: options.variables.nextToken
         }
      }

      return results
   }

   async runMutation(options, queryKey, showAlert) {
      return await API
         .graphql(options)
         .then(({data: {[queryKey]: data}}) => {
            if (showAlert) {
               let message = ""

               if (queryKey.includes("create")) {
                  message = `${this._name} successfully created`
               } else if (queryKey.includes(("delete"))) {
                  message = `${this._name} successfully removed`
               } else {
                  message = `${this._name} successfully updated`
               }

               successAlert({
                  message,
                  event: queryKey,
                  data: {severity: 'success'},
                  stack: ""
               })
            }

            return data
         })
   }

   addDeletedFilter(variables) {
      const notDeleted = {_deleted: {ne: true}}

      if (typeof variables.filter === "undefined") {
         variables.filter = notDeleted
      } else if (typeof variables.filter.and === "undefined") {
         if (! variables.filter?._deleted) {
            variables.filter = {
               and: [
                  variables.filter, notDeleted]
            }
         }
      } else {
         const {
            and,
            ...filter
         } = variables.filter

         const notAdded = and.reduce((notAdded, andFilter) => {
            if(andFilter?._deleted) {
               return false
            }

            return notAdded
         }, true)

         if(notAdded) {
            if (Object.keys(filter).length > 0) {
               variables.filter = {
                  and: [filter, ...and, notDeleted]
               }
            } else {
               variables.filter = {
                  and: [...and, notDeleted]
               }
            }
         }
      }

      return variables
   }

   // could use tests to cover cases
   combineQueries(parent, child) {
      const lowerCaseModel = child.model[0].toLowerCase() + child.model.slice(1)
      const isList = parent.query.includes(`${lowerCaseModel}s`)
      const childIsList = [
         `list${child.model}s`, `sync${child.model}s`, `${lowerCaseModel}sBy${parent.model}`].includes(child.queryName)
      const parentIsList = [`list${parent.model}s`, `sync${parent.model}s`].includes(parent.queryName)

      const childQuery = (childIsList
         ? child.query.replace(/query([^}]+)items \{/, "items {")
         : child.query.replace(/query([^}]+)\(id: \$id\) \{(\s+)id/, `${isList
            ? "items "
            : ""}{$2id`))
         .replace(/_lastChangedAt(\s+)}((\s+)+)(\s+)}((\s+)+)$/, `_lastChangedAt${isList
            ? "$1}"
            : ""}`)


      const pattern = parentIsList
         ? new RegExp(`(\\s+)createdAt`, "g")
         : new RegExp(`(\\s+)${lowerCaseModel}s {([^}]+)}`, "g")

      const replacement = parentIsList
         ? `$1${lowerCaseModel}s {$1${childQuery}$1}$1createdAt}`
         : `$1${lowerCaseModel}s {$1${childQuery}$1}`

      return parent.query
         .replace(pattern, replacement)

   }

   getQueryArray(type) {
      return ["QUERY", "GET", "LIST", "SYNC"].includes(type)
         ? queries
         : ["MUTATION", "CREATE", "UPDATE", "DELETE"].includes(type)
            ? mutations
            : subscriptions;
   }

   getAuthMode() {
      return isAuthenticated()
         .then((authenticated) => authenticated
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM)
   }
}
