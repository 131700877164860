import {Button, Flex, Text, TextField} from "@aws-amplify/ui-react";
import {Dialog} from "@mui/material";
import React, {useState} from 'react'
import {sendForgotPassword} from "../../../../api/auth";
import {DialogHeading} from "../../../common/DialogHeading";
import {getInputError, isValid} from "../../../common/validationUtilities";
import ResetPasswordVerification from "./ResetPasswordVerification";

export default function ResetPassword({
   open,
   setOpen,
   setSignInOpen,
   setVerifyEmailOpen
}) {
   const [values, setValues] = useState({email: ''})
   const [error, setError] = useState("")
   const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false)
   const [sendingCode, setSendingCode] = useState(false)

   const handleChange = (event) => {
      setValues((currentValues) => {
         return {
            ...currentValues,
            [event.target.name]: event.target.value
         }
      })
      setError("")
   }
   const handleSubmit = (event) => {
      event.preventDefault()
      setSendingCode(true)

      if (isValid(event.target.form)) {
         if(values.email === '') {
            setError("Email is required")
            setSendingCode(false)
            return
         }

         sendForgotPassword(values)
            .then(() => {
               setSendingCode(false)
               setOpen(false)
               setForgotPasswordOpen(true)
               setError("")
            })
            .catch((error) => {
               setSendingCode(false)
               switch (error.code) {
                  case "InvalidParameterException":
                     setOpen(false)
                     setVerifyEmailOpen(true)
                     break
                  case "UserNotFoundException":
                     setOpen(false)
                     setForgotPasswordOpen(true)
                     break
                  case "LimitExceededException":
                     setError(error.message)
                     break
                  default:
                     setError("Sorry, we have encountered an error")
               }
            })
      } else {
         setError(getInputError(event.target.form[0]))
         setSendingCode(false)
      }
   }

   return <>
      <ResetPasswordVerification
         setSignInOpen={setSignInOpen}
         open={forgotPasswordOpen}
         setOpen={setForgotPasswordOpen}
         email={values.email}
      />
      <Dialog
         fullWidth
         maxWidth="md"
         open={open}
         onClose={() => setOpen(false)}
         aria-labelledby="alert-dialog-title"
      >
         <Flex
            direction="column"
            width="100%"
            gap="1rem"
         >
            <DialogHeading
               heading="Reset Password"
               onClose={() => setOpen(false)}
            />

            <form onSubmit={handleSubmit}>
               <Flex
                  direction="column"
                  width="100%"
                  gap="1rem"
                  padding="2rem"
                  as="form"
               >
                  <TextField
                     name="email"
                     label={
                        <Text>
                           Email
                           <Text
                              as="span"
                              fontSize="0.8rem"
                              color="red"
                              aria-hidden={true}
                           >
                              {' '} (required)
                           </Text>
                        </Text>
                     }
                     placeholder="Enter your Email"
                     autoComplete="username"
                     type="email"
                     isRequired={true}
                     value={values.email}
                     onChange={handleChange}
                     hasError={error !== ""}
                     errorMessage={error}
                  />

                  <Button
                     variation="primary"
                     size="small"
                     autoFocus
                     onClick={handleSubmit}
                     disabled={sendingCode}
                     type="submit"
                  >
                     {sendingCode
                        ? "Sending Code ..."
                        : "Send Code"}
                  </Button>

                  <Button
                     variation="link"
                     size="small"
                     onClick={() => {
                        setOpen(false)
                        setSignInOpen(true)
                     }}
                  >
                     Back to Sign In
                  </Button>
               </Flex>
            </form>
         </Flex>
      </Dialog>
   </>
}
