import {Navigate, useLocation} from "react-router-dom";
import {useRoles} from "../components/common/authenticator/useRoles";

export function RequireRole({children, rolesToFind}) {
    const location = useLocation()
    const {hasRole} = useRoles()

    if(!hasRole(rolesToFind)) {
        return <Navigate to="/" state={{from: location}} replace/>
    }

    return children
}
