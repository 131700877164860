import * as React from 'react'
import {createRoot} from 'react-dom/client'
import {Amplify, Analytics} from 'aws-amplify';
import {BrowserRouter} from 'react-router-dom'
import AppRouter from './routing/AppRouter'
import reportWebVitals from './reportWebVitals'
import awsconfig from './aws-exports'
import '@aws-amplify/ui-react/styles.css';
import './App.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import AuthProviders from "./components/common/authenticator/AuthProviders";

Amplify
    .configure(awsconfig)

Analytics
    .autoTrack('session', {
        enable: true,
    })
Analytics
    .autoTrack('pageView', {
        enable: true,
        eventName: 'pageView',
        type: 'SPA',
        getUrl: () => {
            return window.location.origin + window.location.pathname;
        }
    });

const root = createRoot(document.getElementById('root') as Element)

root.render(
    <React.StrictMode>
        <AuthProviders>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </AuthProviders>
    </React.StrictMode>
)

serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
