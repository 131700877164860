import {Flex} from "@aws-amplify/ui-react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import * as React from "react";
import {NavLink} from "react-router-dom";

export default function MenuItem (props) {
    const {state, title, path} = props;
    if (state === 'Arrow')
    {
        return (
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                color="rgba(255,255,255,1)"
                gap="0px">
                <NavLink
                    to={path ?? ''}
                    fontWeight="500"
                    padding="0px 0px 0px 0px"
                    style={{color: "white", textDecoration: "none"}}
                >{title}</NavLink>
                <KeyboardArrowRightIcon/>
            </Flex>
        )
    }

    return(
        <Flex
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            color="rgba(255,255,255,1)">
            <NavLink
                to={path ?? ''}
                fontWeight="500"
                padding="0px 0px 0px 0px"
                style={({ isActive }) => {
                    return {
                        textDecoration: isActive ? "underline": "none",
                        color: "white",
                    };
                }}
            >{title}</NavLink>
        </Flex>
    )
}
