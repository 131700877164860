// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, Distributor, Author, Product, Variant, Price, Link, Question, Review, Rating, Article, ArticleApproval, ArticleSection, ArticleComponent, Body, Discussion, Comment, Vote, Image, Video, VideoCaption, Iso, Iso2022, Topic, UserSubscription, Activity, SubscribedActivity, DisabledUserNotifications, Import, ProductImport, VariantImport, Mapping, IsoMapping, FieldMapping, TempProductIso, SavedProduct, BookmarkedArticle, FollowedDiscussion, AuthorUser, ArticleAuthor, ProductIso, ProductIso2022, ArticleIso, ArticleIso2022 } = initSchema(schema);

export {
  User,
  Distributor,
  Author,
  Product,
  Variant,
  Price,
  Link,
  Question,
  Review,
  Rating,
  Article,
  ArticleApproval,
  ArticleSection,
  ArticleComponent,
  Body,
  Discussion,
  Comment,
  Vote,
  Image,
  Video,
  VideoCaption,
  Iso,
  Iso2022,
  Topic,
  UserSubscription,
  Activity,
  SubscribedActivity,
  DisabledUserNotifications,
  Import,
  ProductImport,
  VariantImport,
  Mapping,
  IsoMapping,
  FieldMapping,
  TempProductIso,
  SavedProduct,
  BookmarkedArticle,
  FollowedDiscussion,
  AuthorUser,
  ArticleAuthor,
  ProductIso,
  ProductIso2022,
  ArticleIso,
  ArticleIso2022
};