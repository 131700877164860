/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      distributorId
      firstName
      lastName
      email
      profile
      displayName
      roles
      atMostInterestedIn
      location
      displayLocation
      enabled
      createdBy
      savedProducts {
        nextToken
        startedAt
        __typename
      }
      bookmarkedArticles {
        nextToken
        startedAt
        __typename
      }
      followedDiscussions {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      articleApprovals {
        nextToken
        startedAt
        __typename
      }
      discussions {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      profileImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bannerImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      disabledUserNotifications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userProfileImageId
      userBannerImageId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      distributorId
      firstName
      lastName
      email
      profile
      displayName
      roles
      atMostInterestedIn
      location
      displayLocation
      enabled
      createdBy
      savedProducts {
        nextToken
        startedAt
        __typename
      }
      bookmarkedArticles {
        nextToken
        startedAt
        __typename
      }
      followedDiscussions {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      articleApprovals {
        nextToken
        startedAt
        __typename
      }
      discussions {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      profileImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bannerImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      disabledUserNotifications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userProfileImageId
      userBannerImageId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      distributorId
      firstName
      lastName
      email
      profile
      displayName
      roles
      atMostInterestedIn
      location
      displayLocation
      enabled
      createdBy
      savedProducts {
        nextToken
        startedAt
        __typename
      }
      bookmarkedArticles {
        nextToken
        startedAt
        __typename
      }
      followedDiscussions {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      articleApprovals {
        nextToken
        startedAt
        __typename
      }
      discussions {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      profileImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bannerImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      disabledUserNotifications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userProfileImageId
      userBannerImageId
      __typename
    }
  }
`;
export const createDistributor = /* GraphQL */ `
  mutation CreateDistributor(
    $input: CreateDistributorInput!
    $condition: ModelDistributorConditionInput
  ) {
    createDistributor(input: $input, condition: $condition) {
      id
      name
      description
      abbreviation
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      products {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      links {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      mappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDistributor = /* GraphQL */ `
  mutation UpdateDistributor(
    $input: UpdateDistributorInput!
    $condition: ModelDistributorConditionInput
  ) {
    updateDistributor(input: $input, condition: $condition) {
      id
      name
      description
      abbreviation
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      products {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      links {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      mappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDistributor = /* GraphQL */ `
  mutation DeleteDistributor(
    $input: DeleteDistributorInput!
    $condition: ModelDistributorConditionInput
  ) {
    deleteDistributor(input: $input, condition: $condition) {
      id
      name
      description
      abbreviation
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      products {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      links {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      mappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAuthor = /* GraphQL */ `
  mutation CreateAuthor(
    $input: CreateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    createAuthor(input: $input, condition: $condition) {
      id
      name
      about
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      users {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAuthor = /* GraphQL */ `
  mutation UpdateAuthor(
    $input: UpdateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    updateAuthor(input: $input, condition: $condition) {
      id
      name
      about
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      users {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAuthor = /* GraphQL */ `
  mutation DeleteAuthor(
    $input: DeleteAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    deleteAuthor(input: $input, condition: $condition) {
      id
      name
      about
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      users {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      distributorId
      hasImage
      hasIso2022
      hasDistributor
      productKey
      name
      listPrice
      manufacturer
      gtin
      asin
      sku
      submittedMakerSeller
      status
      description
      weightGrams
      variantOptions
      keywords
      details
      technicalSpecifications
      videoUrl
      internalNotes
      latestImageAddedAt
      createdAt
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      userSaves {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      vendorLinks {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      distributorId
      hasImage
      hasIso2022
      hasDistributor
      productKey
      name
      listPrice
      manufacturer
      gtin
      asin
      sku
      submittedMakerSeller
      status
      description
      weightGrams
      variantOptions
      keywords
      details
      technicalSpecifications
      videoUrl
      internalNotes
      latestImageAddedAt
      createdAt
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      userSaves {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      vendorLinks {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      distributorId
      hasImage
      hasIso2022
      hasDistributor
      productKey
      name
      listPrice
      manufacturer
      gtin
      asin
      sku
      submittedMakerSeller
      status
      description
      weightGrams
      variantOptions
      keywords
      details
      technicalSpecifications
      videoUrl
      internalNotes
      latestImageAddedAt
      createdAt
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      userSaves {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      vendorLinks {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVariant = /* GraphQL */ `
  mutation CreateVariant(
    $input: CreateVariantInput!
    $condition: ModelVariantConditionInput
  ) {
    createVariant(input: $input, condition: $condition) {
      id
      productId
      distributorId
      variantKey
      listPrice
      gtin
      asin
      sku
      status
      description
      weightGrams
      prices {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVariant = /* GraphQL */ `
  mutation UpdateVariant(
    $input: UpdateVariantInput!
    $condition: ModelVariantConditionInput
  ) {
    updateVariant(input: $input, condition: $condition) {
      id
      productId
      distributorId
      variantKey
      listPrice
      gtin
      asin
      sku
      status
      description
      weightGrams
      prices {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVariant = /* GraphQL */ `
  mutation DeleteVariant(
    $input: DeleteVariantInput!
    $condition: ModelVariantConditionInput
  ) {
    deleteVariant(input: $input, condition: $condition) {
      id
      productId
      distributorId
      variantKey
      listPrice
      gtin
      asin
      sku
      status
      description
      weightGrams
      prices {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPrice = /* GraphQL */ `
  mutation CreatePrice(
    $input: CreatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    createPrice(input: $input, condition: $condition) {
      id
      productId
      variantId
      distributorId
      price
      linkText
      url
      pricingNotes
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      variant {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePrice = /* GraphQL */ `
  mutation UpdatePrice(
    $input: UpdatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    updatePrice(input: $input, condition: $condition) {
      id
      productId
      variantId
      distributorId
      price
      linkText
      url
      pricingNotes
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      variant {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePrice = /* GraphQL */ `
  mutation DeletePrice(
    $input: DeletePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    deletePrice(input: $input, condition: $condition) {
      id
      productId
      variantId
      distributorId
      price
      linkText
      url
      pricingNotes
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      variant {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLink = /* GraphQL */ `
  mutation CreateLink(
    $input: CreateLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    createLink(input: $input, condition: $condition) {
      id
      productId
      distributorId
      linkText
      url
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLink = /* GraphQL */ `
  mutation UpdateLink(
    $input: UpdateLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    updateLink(input: $input, condition: $condition) {
      id
      productId
      distributorId
      linkText
      url
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLink = /* GraphQL */ `
  mutation DeleteLink(
    $input: DeleteLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    deleteLink(input: $input, condition: $condition) {
      id
      productId
      distributorId
      linkText
      url
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      userId
      productId
      parentQuestionId
      body
      answers {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      userId
      productId
      parentQuestionId
      body
      answers {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      userId
      productId
      parentQuestionId
      body
      answers {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      ratingId
      userId
      productId
      parentReviewId
      body
      responses {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentReview {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      ratingId
      userId
      productId
      parentReviewId
      body
      responses {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentReview {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      ratingId
      userId
      productId
      parentReviewId
      body
      responses {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentReview {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      userId
      productId
      reviewId
      rating
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      userId
      productId
      reviewId
      rating
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      userId
      productId
      reviewId
      rating
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      type
      mainImageId
      articleApprovalId
      title
      prefix
      key
      published
      wordCount
      authorUserIds
      mainImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      userBookmarks {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleApproval {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      type
      mainImageId
      articleApprovalId
      title
      prefix
      key
      published
      wordCount
      authorUserIds
      mainImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      userBookmarks {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleApproval {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      type
      mainImageId
      articleApprovalId
      title
      prefix
      key
      published
      wordCount
      authorUserIds
      mainImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      userBookmarks {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleApproval {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createArticleApproval = /* GraphQL */ `
  mutation CreateArticleApproval(
    $input: CreateArticleApprovalInput!
    $condition: ModelArticleApprovalConditionInput
  ) {
    createArticleApproval(input: $input, condition: $condition) {
      id
      articleId
      approvedByUserId
      notes
      dateApproved
      approvedBy {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticleApproval = /* GraphQL */ `
  mutation UpdateArticleApproval(
    $input: UpdateArticleApprovalInput!
    $condition: ModelArticleApprovalConditionInput
  ) {
    updateArticleApproval(input: $input, condition: $condition) {
      id
      articleId
      approvedByUserId
      notes
      dateApproved
      approvedBy {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticleApproval = /* GraphQL */ `
  mutation DeleteArticleApproval(
    $input: DeleteArticleApprovalInput!
    $condition: ModelArticleApprovalConditionInput
  ) {
    deleteArticleApproval(input: $input, condition: $condition) {
      id
      articleId
      approvedByUserId
      notes
      dateApproved
      approvedBy {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createArticleSection = /* GraphQL */ `
  mutation CreateArticleSection(
    $input: CreateArticleSectionInput!
    $condition: ModelArticleSectionConditionInput
  ) {
    createArticleSection(input: $input, condition: $condition) {
      id
      articleId
      articleSectionId
      title
      sortWeight
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleComponents {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentArticleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticleSection = /* GraphQL */ `
  mutation UpdateArticleSection(
    $input: UpdateArticleSectionInput!
    $condition: ModelArticleSectionConditionInput
  ) {
    updateArticleSection(input: $input, condition: $condition) {
      id
      articleId
      articleSectionId
      title
      sortWeight
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleComponents {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentArticleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticleSection = /* GraphQL */ `
  mutation DeleteArticleSection(
    $input: DeleteArticleSectionInput!
    $condition: ModelArticleSectionConditionInput
  ) {
    deleteArticleSection(input: $input, condition: $condition) {
      id
      articleId
      articleSectionId
      title
      sortWeight
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleComponents {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentArticleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createArticleComponent = /* GraphQL */ `
  mutation CreateArticleComponent(
    $input: CreateArticleComponentInput!
    $condition: ModelArticleComponentConditionInput
  ) {
    createArticleComponent(input: $input, condition: $condition) {
      id
      articleSectionId
      sortWeight
      bodies {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      articleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticleComponent = /* GraphQL */ `
  mutation UpdateArticleComponent(
    $input: UpdateArticleComponentInput!
    $condition: ModelArticleComponentConditionInput
  ) {
    updateArticleComponent(input: $input, condition: $condition) {
      id
      articleSectionId
      sortWeight
      bodies {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      articleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticleComponent = /* GraphQL */ `
  mutation DeleteArticleComponent(
    $input: DeleteArticleComponentInput!
    $condition: ModelArticleComponentConditionInput
  ) {
    deleteArticleComponent(input: $input, condition: $condition) {
      id
      articleSectionId
      sortWeight
      bodies {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      articleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBody = /* GraphQL */ `
  mutation CreateBody(
    $input: CreateBodyInput!
    $condition: ModelBodyConditionInput
  ) {
    createBody(input: $input, condition: $condition) {
      id
      articleComponentId
      body
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBody = /* GraphQL */ `
  mutation UpdateBody(
    $input: UpdateBodyInput!
    $condition: ModelBodyConditionInput
  ) {
    updateBody(input: $input, condition: $condition) {
      id
      articleComponentId
      body
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBody = /* GraphQL */ `
  mutation DeleteBody(
    $input: DeleteBodyInput!
    $condition: ModelBodyConditionInput
  ) {
    deleteBody(input: $input, condition: $condition) {
      id
      articleComponentId
      body
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDiscussion = /* GraphQL */ `
  mutation CreateDiscussion(
    $input: CreateDiscussionInput!
    $condition: ModelDiscussionConditionInput
  ) {
    createDiscussion(input: $input, condition: $condition) {
      id
      userId
      topicId
      title
      body
      popularity
      userFollows {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      topic {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDiscussion = /* GraphQL */ `
  mutation UpdateDiscussion(
    $input: UpdateDiscussionInput!
    $condition: ModelDiscussionConditionInput
  ) {
    updateDiscussion(input: $input, condition: $condition) {
      id
      userId
      topicId
      title
      body
      popularity
      userFollows {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      topic {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDiscussion = /* GraphQL */ `
  mutation DeleteDiscussion(
    $input: DeleteDiscussionInput!
    $condition: ModelDiscussionConditionInput
  ) {
    deleteDiscussion(input: $input, condition: $condition) {
      id
      userId
      topicId
      title
      body
      popularity
      userFollows {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      topic {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      userId
      articleId
      discussionId
      parentCommentId
      comment
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentComment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      userId
      articleId
      discussionId
      parentCommentId
      comment
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentComment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      userId
      articleId
      discussionId
      parentCommentId
      comment
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentComment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVote = /* GraphQL */ `
  mutation CreateVote(
    $input: CreateVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    createVote(input: $input, condition: $condition) {
      id
      userId
      commentId
      reviewId
      questionId
      articleId
      discussionId
      vote
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      question {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateVote = /* GraphQL */ `
  mutation UpdateVote(
    $input: UpdateVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    updateVote(input: $input, condition: $condition) {
      id
      userId
      commentId
      reviewId
      questionId
      articleId
      discussionId
      vote
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      question {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteVote = /* GraphQL */ `
  mutation DeleteVote(
    $input: DeleteVoteInput!
    $condition: ModelVoteConditionInput
  ) {
    deleteVote(input: $input, condition: $condition) {
      id
      userId
      commentId
      reviewId
      questionId
      articleId
      discussionId
      vote
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      question {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      productId
      articleId
      articleComponentId
      discussionId
      reviewId
      distributorId
      profileUserId
      bannerUserId
      url
      prefix
      key
      altText
      title
      width
      height
      position
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      bannerUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      productId
      articleId
      articleComponentId
      discussionId
      reviewId
      distributorId
      profileUserId
      bannerUserId
      url
      prefix
      key
      altText
      title
      width
      height
      position
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      bannerUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      productId
      articleId
      articleComponentId
      discussionId
      reviewId
      distributorId
      profileUserId
      bannerUserId
      url
      prefix
      key
      altText
      title
      width
      height
      position
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      bannerUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      productId
      articleComponentId
      discussionId
      reviewId
      distributorId
      prefix
      videoKey
      transcriptKey
      title
      length
      language
      asl
      openCaptions
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      productId
      articleComponentId
      discussionId
      reviewId
      distributorId
      prefix
      videoKey
      transcriptKey
      title
      length
      language
      asl
      openCaptions
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      productId
      articleComponentId
      discussionId
      reviewId
      distributorId
      prefix
      videoKey
      transcriptKey
      title
      length
      language
      asl
      openCaptions
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVideoCaption = /* GraphQL */ `
  mutation CreateVideoCaption(
    $input: CreateVideoCaptionInput!
    $condition: ModelVideoCaptionConditionInput
  ) {
    createVideoCaption(input: $input, condition: $condition) {
      id
      videoId
      distributorId
      captionKey
      language
      video {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVideoCaption = /* GraphQL */ `
  mutation UpdateVideoCaption(
    $input: UpdateVideoCaptionInput!
    $condition: ModelVideoCaptionConditionInput
  ) {
    updateVideoCaption(input: $input, condition: $condition) {
      id
      videoId
      distributorId
      captionKey
      language
      video {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVideoCaption = /* GraphQL */ `
  mutation DeleteVideoCaption(
    $input: DeleteVideoCaptionInput!
    $condition: ModelVideoCaptionConditionInput
  ) {
    deleteVideoCaption(input: $input, condition: $condition) {
      id
      videoId
      distributorId
      captionKey
      language
      video {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIso = /* GraphQL */ `
  mutation CreateIso(
    $input: CreateIsoInput!
    $condition: ModelIsoConditionInput
  ) {
    createIso(input: $input, condition: $condition) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIso = /* GraphQL */ `
  mutation UpdateIso(
    $input: UpdateIsoInput!
    $condition: ModelIsoConditionInput
  ) {
    updateIso(input: $input, condition: $condition) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIso = /* GraphQL */ `
  mutation DeleteIso(
    $input: DeleteIsoInput!
    $condition: ModelIsoConditionInput
  ) {
    deleteIso(input: $input, condition: $condition) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIso2022 = /* GraphQL */ `
  mutation CreateIso2022(
    $input: CreateIso2022Input!
    $condition: ModelIso2022ConditionInput
  ) {
    createIso2022(input: $input, condition: $condition) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIso2022 = /* GraphQL */ `
  mutation UpdateIso2022(
    $input: UpdateIso2022Input!
    $condition: ModelIso2022ConditionInput
  ) {
    updateIso2022(input: $input, condition: $condition) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIso2022 = /* GraphQL */ `
  mutation DeleteIso2022(
    $input: DeleteIso2022Input!
    $condition: ModelIso2022ConditionInput
  ) {
    deleteIso2022(input: $input, condition: $condition) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      title
      description
      discussions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      title
      description
      discussions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      title
      description
      discussions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserSubscription = /* GraphQL */ `
  mutation CreateUserSubscription(
    $input: CreateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    createUserSubscription(input: $input, condition: $condition) {
      id
      articleId
      productId
      commentId
      discussionId
      userId
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserSubscription = /* GraphQL */ `
  mutation UpdateUserSubscription(
    $input: UpdateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    updateUserSubscription(input: $input, condition: $condition) {
      id
      articleId
      productId
      commentId
      discussionId
      userId
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserSubscription = /* GraphQL */ `
  mutation DeleteUserSubscription(
    $input: DeleteUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    deleteUserSubscription(input: $input, condition: $condition) {
      id
      articleId
      productId
      commentId
      discussionId
      userId
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      message
      url
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      message
      url
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      message
      url
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSubscribedActivity = /* GraphQL */ `
  mutation CreateSubscribedActivity(
    $input: CreateSubscribedActivityInput!
    $condition: ModelSubscribedActivityConditionInput
  ) {
    createSubscribedActivity(input: $input, condition: $condition) {
      id
      userId
      userSubscriptionId
      activityId
      read
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      activity {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscription {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSubscribedActivity = /* GraphQL */ `
  mutation UpdateSubscribedActivity(
    $input: UpdateSubscribedActivityInput!
    $condition: ModelSubscribedActivityConditionInput
  ) {
    updateSubscribedActivity(input: $input, condition: $condition) {
      id
      userId
      userSubscriptionId
      activityId
      read
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      activity {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscription {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSubscribedActivity = /* GraphQL */ `
  mutation DeleteSubscribedActivity(
    $input: DeleteSubscribedActivityInput!
    $condition: ModelSubscribedActivityConditionInput
  ) {
    deleteSubscribedActivity(input: $input, condition: $condition) {
      id
      userId
      userSubscriptionId
      activityId
      read
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      activity {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscription {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDisabledUserNotifications = /* GraphQL */ `
  mutation CreateDisabledUserNotifications(
    $input: CreateDisabledUserNotificationsInput!
    $condition: ModelDisabledUserNotificationsConditionInput
  ) {
    createDisabledUserNotifications(input: $input, condition: $condition) {
      id
      userId
      resource
      activityType
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDisabledUserNotifications = /* GraphQL */ `
  mutation UpdateDisabledUserNotifications(
    $input: UpdateDisabledUserNotificationsInput!
    $condition: ModelDisabledUserNotificationsConditionInput
  ) {
    updateDisabledUserNotifications(input: $input, condition: $condition) {
      id
      userId
      resource
      activityType
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDisabledUserNotifications = /* GraphQL */ `
  mutation DeleteDisabledUserNotifications(
    $input: DeleteDisabledUserNotificationsInput!
    $condition: ModelDisabledUserNotificationsConditionInput
  ) {
    deleteDisabledUserNotifications(input: $input, condition: $condition) {
      id
      userId
      resource
      activityType
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createImport = /* GraphQL */ `
  mutation CreateImport(
    $input: CreateImportInput!
    $condition: ModelImportConditionInput
  ) {
    createImport(input: $input, condition: $condition) {
      id
      distributorId
      importId
      prefix
      key
      status
      startTime
      endTime
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateImport = /* GraphQL */ `
  mutation UpdateImport(
    $input: UpdateImportInput!
    $condition: ModelImportConditionInput
  ) {
    updateImport(input: $input, condition: $condition) {
      id
      distributorId
      importId
      prefix
      key
      status
      startTime
      endTime
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteImport = /* GraphQL */ `
  mutation DeleteImport(
    $input: DeleteImportInput!
    $condition: ModelImportConditionInput
  ) {
    deleteImport(input: $input, condition: $condition) {
      id
      distributorId
      importId
      prefix
      key
      status
      startTime
      endTime
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProductImport = /* GraphQL */ `
  mutation CreateProductImport(
    $input: CreateProductImportInput!
    $condition: ModelProductImportConditionInput
  ) {
    createProductImport(input: $input, condition: $condition) {
      id
      productId
      importId
      distributorId
      productKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProductImport = /* GraphQL */ `
  mutation UpdateProductImport(
    $input: UpdateProductImportInput!
    $condition: ModelProductImportConditionInput
  ) {
    updateProductImport(input: $input, condition: $condition) {
      id
      productId
      importId
      distributorId
      productKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProductImport = /* GraphQL */ `
  mutation DeleteProductImport(
    $input: DeleteProductImportInput!
    $condition: ModelProductImportConditionInput
  ) {
    deleteProductImport(input: $input, condition: $condition) {
      id
      productId
      importId
      distributorId
      productKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVariantImport = /* GraphQL */ `
  mutation CreateVariantImport(
    $input: CreateVariantImportInput!
    $condition: ModelVariantImportConditionInput
  ) {
    createVariantImport(input: $input, condition: $condition) {
      id
      variantId
      importId
      distributorId
      productKey
      variantKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVariantImport = /* GraphQL */ `
  mutation UpdateVariantImport(
    $input: UpdateVariantImportInput!
    $condition: ModelVariantImportConditionInput
  ) {
    updateVariantImport(input: $input, condition: $condition) {
      id
      variantId
      importId
      distributorId
      productKey
      variantKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVariantImport = /* GraphQL */ `
  mutation DeleteVariantImport(
    $input: DeleteVariantImportInput!
    $condition: ModelVariantImportConditionInput
  ) {
    deleteVariantImport(input: $input, condition: $condition) {
      id
      variantId
      importId
      distributorId
      productKey
      variantKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMapping = /* GraphQL */ `
  mutation CreateMapping(
    $input: CreateMappingInput!
    $condition: ModelMappingConditionInput
  ) {
    createMapping(input: $input, condition: $condition) {
      id
      distributorId
      name
      productKeyFields
      variantKeyFields
      variantOptionFields
      variantValueFields
      categoryFields
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMapping = /* GraphQL */ `
  mutation UpdateMapping(
    $input: UpdateMappingInput!
    $condition: ModelMappingConditionInput
  ) {
    updateMapping(input: $input, condition: $condition) {
      id
      distributorId
      name
      productKeyFields
      variantKeyFields
      variantOptionFields
      variantValueFields
      categoryFields
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMapping = /* GraphQL */ `
  mutation DeleteMapping(
    $input: DeleteMappingInput!
    $condition: ModelMappingConditionInput
  ) {
    deleteMapping(input: $input, condition: $condition) {
      id
      distributorId
      name
      productKeyFields
      variantKeyFields
      variantOptionFields
      variantValueFields
      categoryFields
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIsoMapping = /* GraphQL */ `
  mutation CreateIsoMapping(
    $input: CreateIsoMappingInput!
    $condition: ModelIsoMappingConditionInput
  ) {
    createIsoMapping(input: $input, condition: $condition) {
      id
      mappingId
      isoId
      iso2022Id
      distributorId
      distributorCategory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIsoMapping = /* GraphQL */ `
  mutation UpdateIsoMapping(
    $input: UpdateIsoMappingInput!
    $condition: ModelIsoMappingConditionInput
  ) {
    updateIsoMapping(input: $input, condition: $condition) {
      id
      mappingId
      isoId
      iso2022Id
      distributorId
      distributorCategory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIsoMapping = /* GraphQL */ `
  mutation DeleteIsoMapping(
    $input: DeleteIsoMappingInput!
    $condition: ModelIsoMappingConditionInput
  ) {
    deleteIsoMapping(input: $input, condition: $condition) {
      id
      mappingId
      isoId
      iso2022Id
      distributorId
      distributorCategory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFieldMapping = /* GraphQL */ `
  mutation CreateFieldMapping(
    $input: CreateFieldMappingInput!
    $condition: ModelFieldMappingConditionInput
  ) {
    createFieldMapping(input: $input, condition: $condition) {
      id
      mappingId
      distributorId
      internalField
      distributorField
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFieldMapping = /* GraphQL */ `
  mutation UpdateFieldMapping(
    $input: UpdateFieldMappingInput!
    $condition: ModelFieldMappingConditionInput
  ) {
    updateFieldMapping(input: $input, condition: $condition) {
      id
      mappingId
      distributorId
      internalField
      distributorField
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFieldMapping = /* GraphQL */ `
  mutation DeleteFieldMapping(
    $input: DeleteFieldMappingInput!
    $condition: ModelFieldMappingConditionInput
  ) {
    deleteFieldMapping(input: $input, condition: $condition) {
      id
      mappingId
      distributorId
      internalField
      distributorField
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTempProductIso = /* GraphQL */ `
  mutation CreateTempProductIso(
    $input: CreateTempProductIsoInput!
    $condition: ModelTempProductIsoConditionInput
  ) {
    createTempProductIso(input: $input, condition: $condition) {
      id
      productID
      isoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTempProductIso = /* GraphQL */ `
  mutation UpdateTempProductIso(
    $input: UpdateTempProductIsoInput!
    $condition: ModelTempProductIsoConditionInput
  ) {
    updateTempProductIso(input: $input, condition: $condition) {
      id
      productID
      isoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTempProductIso = /* GraphQL */ `
  mutation DeleteTempProductIso(
    $input: DeleteTempProductIsoInput!
    $condition: ModelTempProductIsoConditionInput
  ) {
    deleteTempProductIso(input: $input, condition: $condition) {
      id
      productID
      isoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSavedProduct = /* GraphQL */ `
  mutation CreateSavedProduct(
    $input: CreateSavedProductInput!
    $condition: ModelSavedProductConditionInput
  ) {
    createSavedProduct(input: $input, condition: $condition) {
      id
      userID
      productID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const updateSavedProduct = /* GraphQL */ `
  mutation UpdateSavedProduct(
    $input: UpdateSavedProductInput!
    $condition: ModelSavedProductConditionInput
  ) {
    updateSavedProduct(input: $input, condition: $condition) {
      id
      userID
      productID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const deleteSavedProduct = /* GraphQL */ `
  mutation DeleteSavedProduct(
    $input: DeleteSavedProductInput!
    $condition: ModelSavedProductConditionInput
  ) {
    deleteSavedProduct(input: $input, condition: $condition) {
      id
      userID
      productID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const createBookmarkedArticle = /* GraphQL */ `
  mutation CreateBookmarkedArticle(
    $input: CreateBookmarkedArticleInput!
    $condition: ModelBookmarkedArticleConditionInput
  ) {
    createBookmarkedArticle(input: $input, condition: $condition) {
      id
      userID
      articleID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const updateBookmarkedArticle = /* GraphQL */ `
  mutation UpdateBookmarkedArticle(
    $input: UpdateBookmarkedArticleInput!
    $condition: ModelBookmarkedArticleConditionInput
  ) {
    updateBookmarkedArticle(input: $input, condition: $condition) {
      id
      userID
      articleID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const deleteBookmarkedArticle = /* GraphQL */ `
  mutation DeleteBookmarkedArticle(
    $input: DeleteBookmarkedArticleInput!
    $condition: ModelBookmarkedArticleConditionInput
  ) {
    deleteBookmarkedArticle(input: $input, condition: $condition) {
      id
      userID
      articleID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const createFollowedDiscussion = /* GraphQL */ `
  mutation CreateFollowedDiscussion(
    $input: CreateFollowedDiscussionInput!
    $condition: ModelFollowedDiscussionConditionInput
  ) {
    createFollowedDiscussion(input: $input, condition: $condition) {
      id
      userID
      discussionID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const updateFollowedDiscussion = /* GraphQL */ `
  mutation UpdateFollowedDiscussion(
    $input: UpdateFollowedDiscussionInput!
    $condition: ModelFollowedDiscussionConditionInput
  ) {
    updateFollowedDiscussion(input: $input, condition: $condition) {
      id
      userID
      discussionID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const deleteFollowedDiscussion = /* GraphQL */ `
  mutation DeleteFollowedDiscussion(
    $input: DeleteFollowedDiscussionInput!
    $condition: ModelFollowedDiscussionConditionInput
  ) {
    deleteFollowedDiscussion(input: $input, condition: $condition) {
      id
      userID
      discussionID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const createAuthorUser = /* GraphQL */ `
  mutation CreateAuthorUser(
    $input: CreateAuthorUserInput!
    $condition: ModelAuthorUserConditionInput
  ) {
    createAuthorUser(input: $input, condition: $condition) {
      id
      userID
      authorID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const updateAuthorUser = /* GraphQL */ `
  mutation UpdateAuthorUser(
    $input: UpdateAuthorUserInput!
    $condition: ModelAuthorUserConditionInput
  ) {
    updateAuthorUser(input: $input, condition: $condition) {
      id
      userID
      authorID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const deleteAuthorUser = /* GraphQL */ `
  mutation DeleteAuthorUser(
    $input: DeleteAuthorUserInput!
    $condition: ModelAuthorUserConditionInput
  ) {
    deleteAuthorUser(input: $input, condition: $condition) {
      id
      userID
      authorID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const createArticleAuthor = /* GraphQL */ `
  mutation CreateArticleAuthor(
    $input: CreateArticleAuthorInput!
    $condition: ModelArticleAuthorConditionInput
  ) {
    createArticleAuthor(input: $input, condition: $condition) {
      id
      authorID
      articleID
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticleAuthor = /* GraphQL */ `
  mutation UpdateArticleAuthor(
    $input: UpdateArticleAuthorInput!
    $condition: ModelArticleAuthorConditionInput
  ) {
    updateArticleAuthor(input: $input, condition: $condition) {
      id
      authorID
      articleID
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticleAuthor = /* GraphQL */ `
  mutation DeleteArticleAuthor(
    $input: DeleteArticleAuthorInput!
    $condition: ModelArticleAuthorConditionInput
  ) {
    deleteArticleAuthor(input: $input, condition: $condition) {
      id
      authorID
      articleID
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProductIso = /* GraphQL */ `
  mutation CreateProductIso(
    $input: CreateProductIsoInput!
    $condition: ModelProductIsoConditionInput
  ) {
    createProductIso(input: $input, condition: $condition) {
      id
      productID
      isoID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProductIso = /* GraphQL */ `
  mutation UpdateProductIso(
    $input: UpdateProductIsoInput!
    $condition: ModelProductIsoConditionInput
  ) {
    updateProductIso(input: $input, condition: $condition) {
      id
      productID
      isoID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProductIso = /* GraphQL */ `
  mutation DeleteProductIso(
    $input: DeleteProductIsoInput!
    $condition: ModelProductIsoConditionInput
  ) {
    deleteProductIso(input: $input, condition: $condition) {
      id
      productID
      isoID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProductIso2022 = /* GraphQL */ `
  mutation CreateProductIso2022(
    $input: CreateProductIso2022Input!
    $condition: ModelProductIso2022ConditionInput
  ) {
    createProductIso2022(input: $input, condition: $condition) {
      id
      productID
      iso2022ID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProductIso2022 = /* GraphQL */ `
  mutation UpdateProductIso2022(
    $input: UpdateProductIso2022Input!
    $condition: ModelProductIso2022ConditionInput
  ) {
    updateProductIso2022(input: $input, condition: $condition) {
      id
      productID
      iso2022ID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProductIso2022 = /* GraphQL */ `
  mutation DeleteProductIso2022(
    $input: DeleteProductIso2022Input!
    $condition: ModelProductIso2022ConditionInput
  ) {
    deleteProductIso2022(input: $input, condition: $condition) {
      id
      productID
      iso2022ID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createArticleIso = /* GraphQL */ `
  mutation CreateArticleIso(
    $input: CreateArticleIsoInput!
    $condition: ModelArticleIsoConditionInput
  ) {
    createArticleIso(input: $input, condition: $condition) {
      id
      articleID
      isoID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticleIso = /* GraphQL */ `
  mutation UpdateArticleIso(
    $input: UpdateArticleIsoInput!
    $condition: ModelArticleIsoConditionInput
  ) {
    updateArticleIso(input: $input, condition: $condition) {
      id
      articleID
      isoID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticleIso = /* GraphQL */ `
  mutation DeleteArticleIso(
    $input: DeleteArticleIsoInput!
    $condition: ModelArticleIsoConditionInput
  ) {
    deleteArticleIso(input: $input, condition: $condition) {
      id
      articleID
      isoID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createArticleIso2022 = /* GraphQL */ `
  mutation CreateArticleIso2022(
    $input: CreateArticleIso2022Input!
    $condition: ModelArticleIso2022ConditionInput
  ) {
    createArticleIso2022(input: $input, condition: $condition) {
      id
      articleID
      iso2022ID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticleIso2022 = /* GraphQL */ `
  mutation UpdateArticleIso2022(
    $input: UpdateArticleIso2022Input!
    $condition: ModelArticleIso2022ConditionInput
  ) {
    updateArticleIso2022(input: $input, condition: $condition) {
      id
      articleID
      iso2022ID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticleIso2022 = /* GraphQL */ `
  mutation DeleteArticleIso2022(
    $input: DeleteArticleIso2022Input!
    $condition: ModelArticleIso2022ConditionInput
  ) {
    deleteArticleIso2022(input: $input, condition: $condition) {
      id
      articleID
      iso2022ID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
