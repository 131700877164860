import {Button, Flex, Text, TextField} from "@aws-amplify/ui-react";
import React, {useState} from "react";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import {signIn} from "../../../../api/auth";
import Api from "../../../../api/graphql/Api";
import {displayMessage} from "../../../common/Toast/toastUtilities";

const api = new Api('User')
export default function SignIn({
   values,
   setValues,
   setOpen,
   setResetPasswordOpen,
   setVerifyEmailOpen
}) {
   const [signInError, setSignInError] = useState("")
   const [showPassword, setShowPassword] = useState(false)
   const [signingIn, setSigningIn] = useState(false)

   const handleChange = (event) => {
      setValues((currentValues) => {
         return {
            ...currentValues,
            [event.target.name]: event.target.value
         }
      })
   }

   const showResetPasswordModal = () => {
      setOpen(false)
      setResetPasswordOpen(true)
   }

   const handleSignIn = (event) => {
      event.preventDefault()

      setSignInError("")

      for (let index = 0; index < event.target.form.length; ++index) {
         if (event.target.form[index].localName === "input") {
            if (!event.target.form[index].validity.valid) {
               setSigningIn(false)
               return
            }
         }
      }

      signIn(values, api)
         .then(() => {
            setOpen(false)
            setSigningIn(false)
            setValues({
               ...values,
               username: '',
               password: ''
            })
         })
         .catch((error) => {
            switch (error.code) {
               case "UserNotConfirmedException":
                  setOpen(false)
                  setVerifyEmailOpen(true)
                  break
               default:
                  setSignInError(error.message)
            }

            setSigningIn(false)
         })
   }

   return (<>
      <Flex
         direction="column"
         width="100%"
         gap="1rem"
         justifyContent="space-between"
         alignItems="center"
      >
         <Flex
            direction="column"
            width="100%"
            gap="1rem"
            padding="2rem"
            as="form"
         >
            {signInError === ""
               ? null
               : displayMessage(() => {
                  setSignInError("")
               }, "error", signInError)}

            <TextField
               name="username"
               label={<Text>
                  Email
                  <Text
                     as="span"
                     fontSize="0.8rem"
                     color="red"
                     aria-hidden={true}
                  >
                     {' '} (required)
                  </Text>
               </Text>}
               type="email"
               isRequired={true}
               placeholder="Enter your Email"
               autoComplete="username"
               value={values.username}
               onChange={handleChange}
            />

            <TextField
               type={showPassword
                  ? "text"
                  : "password"}
               name="password"
               value={values.password}
               label={<Text>
                  Password
                  <Text
                     as="span"
                     fontSize="0.8rem"
                     color="red"
                     aria-hidden={true}
                  >
                     {' '} (required)
                  </Text>
               </Text>}
               isRequired={true}
               placeholder="Enter your Password"
               autoComplete="current-password"
               outerEndComponent={<Button onClick={() => setShowPassword(!showPassword)}>{showPassword
                  ? <MdVisibilityOff title="Hide password" />
                  : <MdVisibility title="Show password" />}</Button>}
               onChange={handleChange}
            />

            <Button
               variation="primary"
               size="small"
               autoFocus
               type="submit"
               onClick={(event) => {
                  setSigningIn(true)
                  handleSignIn(event)
               }}
            >
               {signingIn
               ? "Signing In ..."
               : "Sign In"}
            </Button>

            <Button
               variation="link"
               size="small"
               onClick={showResetPasswordModal}
            >
               Reset Password
            </Button>
         </Flex>
      </Flex>
   </>)
}
