import {Alert, Button, Flex, Text, TextAreaField} from "@aws-amplify/ui-react";
import * as React from "react";
import {useEffect, useState} from "react";
import {useUserAuthenticator} from "../../common/authenticator/useUserAuthenticator";
import {sendEmail} from "../../common/utilities";

export default function FooterContact() {
   const {
      // authStatus,
      user
   } = useUserAuthenticator();
   const [contactMessage, setContactMessage] = useState('');
   const [contactAddress, setContactAddress] = useState(user?.attributes?.email ?? "");
   const [disableSubmitButton, setDisableSubmitButton] = useState(false);
   const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);

   useEffect(() => {
      setContactAddress(user?.attributes?.email ?? "")
   }, [user])


   const setMessage = (e) => {
      setContactMessage(e.currentTarget.value)
      if (disableSubmitButton) {
         setDisableSubmitButton(false)
      }
   }
   // const setAddress = (e) => {
   //    setContactAddress(e.currentTarget.value)
   //    if (disableSubmitButton) {
   //       setDisableSubmitButton(false)
   //    }
   // }
   // const handleShowAlert = () => {
   //    setIsSuccessAlertVisible(true);
   // }

   const submitMessage = (event) => {
      event.preventDefault()
      if (event.currentTarget.form.reportValidity()) {
         sendEmail('assistive-tech.net@design.gatech.edu', "Contact AT Network", contactMessage)
         // setContactAddress("")
         // setContactMessage("")
         // handleShowAlert()
      } else {
         setDisableSubmitButton(true)
      }
   }

   return (<Flex
         gap="12px"
         direction="column"
         justifyContent="flex-start"
         alignItems="center"
      >

         <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24.204544067382812px"
            textAlign="left"
            display="block"
            direction="column"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
         >
            Questions? Contact Us
         </Text>

         {isSuccessAlertVisible
            ? <Alert
               isDismissible={true}
               hasIcon={true}
               variation="success"
               heading="Thank you for contacting us!"
               onDismiss={() => setIsSuccessAlertVisible(false)}
               width="16rem"
            >
               <Text
                  fontSize=".75rem"
                  width="100%"
               >
                  You should hear back from us in 5 to 7 business days.
               </Text>
            </Alert>
            : null}

         <Flex
            as="form"
            direction="column"
         >
            {/*<TextField*/}
            {/*    placeholder="E-mail (required)"*/}
            {/*    label="E-mail address"*/}
            {/*    labelHidden="true"*/}
            {/*    readOnly={authStatus==='authenticated'}*/}
            {/*    value={contactAddress}*/}
            {/*    isRequired={true}*/}
            {/*    type="email"*/}
            {/*    onChange={setAddress}*/}
            {/*    backgroundColor="var(--amplify-colors-font-inverse)"*/}
            {/*/>*/}
            <TextAreaField
               placeholder="Message (required)"
               label="Message"
               labelHidden="true"
               value={contactMessage}
               isRequired={true}
               rows={6}
               onChange={setMessage}
               backgroundColor="var(--amplify-colors-font-inverse)"
            />

            <Button
               variation="primary"
               type="submit"
               isDisabled={disableSubmitButton}
               onClick={submitMessage}
            >
               Send
            </Button>
         </Flex>
      </Flex>)
}
