import {Flex} from "@aws-amplify/ui-react"
import HeaderNav from "components/layout/Header/HeaderNav";
import Logo from "components/layout/Header/Logo";
import Ribbon from "components/layout/Header/Ribbon";
import {Search} from "components/layout/Header/Search";
import * as React from "react"

export default function Header({mainContentRef}) {
   return <Flex
      direction="column"
      justifyContent="center"
      alignItems="flex-end"
      overflow="hidden"
      position="relative"
      padding="0px 0px 32px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
   >
      <Ribbon mainContentRef={mainContentRef} />

      <Flex
         gap="0"
         direction="row"
         width="unset"
         height="unset"
         justifyContent="flex-end"
         alignItems="flex-start"
         shrink="0"
         alignSelf="stretch"
         position="relative"
         padding="0px 56px 0px 56px"
         display="flex"
      >
         <Logo />
         <Search type="all" />
      </Flex>

      <Flex
         direction="column"
         justifyContent="flex-start"
         alignItems="flex-start"
         alignSelf="stretch"
      >
         <HeaderNav />
      </Flex>
   </Flex>
}
