const expanderTheme = {
    name: 'expander-theme',
    tokens: {
        components: {
            expander: {
                boxShadow: {value: 'none'},
                header: {
                    boxShadow: {value: 'none'},
                    border: {value: 'none'}
                },
                item: {
                    borderRadius: {value: '0'},
                    boxShadow: {value: 'none'}
                },
                content: {
                    paddingInlineStart: {value: '.25rem'},
                    paddingInlineEnd: {value: '.25rem'},
                    text: {
                        paddingBlockStart: {value: '0'}
                    }
                },
                trigger: {
                    minHeight: {value: '0'},
                    paddingInlineStart: {value: '.25rem'},
                    paddingInlineEnd: {value: '.25rem'}
                }
            },
        },
    },
}

export default expanderTheme
