import {Button, Flex, Heading, ScrollView, Text} from "@aws-amplify/ui-react";
import React, {useState} from "react";

export default function ConsentFormDialog({
   setOpen,
   setPhase,
   setValues,
   setRegisterOpen
}) {

   const [enableButton, setEnableButton] = useState(false)
   const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
      if (bottom) {
         setEnableButton(true)
      }
   }

   return (
      <Flex
         direction="column"
         width="100%"
         gap="1rem"
         justifyContent="space-between"
         alignItems="center"
      >
         <Flex
            direction="column"
            width="100%"
            gap="1rem"
            padding="2rem"
         >
            <Text fontWeight="800">Please use the scrollbar on the side to scroll to the bottom in order to agree and proceed</Text>
            <ScrollView
               width="100%"
               maxHeight="20rem"
               onScroll={handleScroll}
            >
               <Flex
                  direction="row"
                  marginBottom="2rem"
               >
                  <Text fontWeight="800">Investigators: </Text> Maureen Linden, Julie Eshleman, Patricia Griffiths,
                  Frances Harris, Salimah LaForce, Nathan Moon
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Purpose:</Heading>
                  <Text>You are being asked to be a volunteer in a research study.</Text>
                  <Text>The project will identify how people with disabilities use information, knowledge, and peer
                     supports for selecting and using assistive technology (AT); how they describe their needs around
                     AT to support independence and community participation; how they use AT to meet their needs; and
                     areas where more support is needed because there is no AT that meets the need.</Text>
                  <Text>To answer some of these questions, we will review the discussions that people with
                     disabilities, the AT Manufacturers, AT Service Providers and others have within this online
                     community to look for patterns in the discussions. We will also identify which resources are
                     most popular with the community.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Exclusion / Inclusion Criteria:</Heading>
                  <Text>To be eligible to be part of this study, you must be over the age of 18. You must be able to
                     read written English or communicate using American Sign Language. You must be able to access the
                     internet while in the United States. You will not be allowed to participate if you are under the
                     age of 18, can not read English or use American Sign Language.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Procedures:</Heading>
                  <Text>If you decide to participate, we will also ask you to agree to our community “rules” which
                     describe how you may interact with others. We expect everyone to be respectful of others at all
                     times.</Text>
                  <Text>Once you agree to the rules. You will create a profile that will only be viewable by the
                     research team. It will include:</Text>
                  <ul style={{"marginTop": "0rem"}}>
                     <li>your date of birth so that we may verify your age;</li>
                     <li>an email address to verify you are a real person and allow you to recover a lost password,
                     </li>
                     <li>a functional limitation that you or a loved one have or that you specialize your practice or
                        technology around,
                     </li>
                     <li>your race/ethnicity,</li>
                     <li>your gender,</li>
                     <li>your state of residence,</li>
                     <li>your level of experience with assistive technology (e.g. novice, expert).</li>
                  </ul>
                  <Text>You will also have a public provide that will include a username of your choosing and a simple
                     description of your role with AT (e.g. AT User, AT Provider, Counselor, Educator, family member,
                     AT Industry Representative). This role will allow others in the community to understand your
                     frame of reference when viewing your activity in the community.</Text>
                  <Text>Once you have created a profile, you will be able to interact with the AT Network Community,
                     ask questions, like articles or technology, and participate in discussions. You may participate
                     for as long or short of a period of time as you like. You may leave the community and come back
                     to it using your profile. We will not ask you to do specific tasks or participate in a specific
                     way.</Text>
                  <Text>Note that you will not be able to access the AT Network Community outside the United States
                     and Canada.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Risks or Discomforts:</Heading>
                  <Text>The risks involved in participating in the community are not greater than those involved in
                     reading documents on the internet or writing an email. Your comments and likes will be viewable
                     by other members of the community. From your comments, others may be able to determine who you
                     are. We ask all participants to maintain confidentiality and keep what was discussed within the
                     community, but Georgia Tech cannot guarantee confidentiality if other participants choose to
                     discuss activities in the community with others.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Benefits:</Heading>
                  <Text>The information that we learn will help AT practitioners, counselors, and industry
                     representatives determine better ways to help people with disabilities integrate assistive
                     technology in their lives. They may find ways they can improve their technology or create better
                     technology to meet the needs of people with disabilities. They may find better ways to help
                     people learn how to use their assistive technology.</Text>
                  <Text>You may also benefit by learning about an assistive technology that may help you, or how to
                     use an assistive technology that you already know about in a different and better way. This may
                     allow you better independence in your home or community environment.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Costs / Compensation to You:</Heading>
                  <Text>There are no costs to you, other than your time, for being in this study. There is no
                     compensation for being in this study.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Confidentiality, Including Use Recordings:</Heading>
                  <Text>Your records will be kept by code number, rather than by name. This includes any audio or
                     visual recordings that you chose to share with us. For example, if you show a picture of
                     yourself using a technology, we will not refer to that picture with your name or other
                     identifying information. We will not use recordings or other identifiable information about you
                     in any presentation or publication. We will comply with any applicable laws and regulations
                     regarding confidentiality. You will be encouraged to use yours and others’ usernames when
                     interacting with them in the community. Your name and any other fact that might point to you
                     will not appear when results of this study are presented or published. The Georgia Institute of
                     Technology IRB and the Office of Human Research Protections may look over study records during
                     required reviews.</Text>
                  <Text>This research is funded by the National Institute of Disability Independent Living and
                     Rehabilitation Research (NIDILRR). NIDILRR will require us to provide de-identified data to the
                     Inter-University Consortium for Political and Social Research (ICPSR) at the end of their
                     provided funding. This de-identified data will allow other researchers to benefit from the
                     project. This data share will not include your name, any of your personal information, or any
                     images you may have provided. The project’s funding is estimated to end on September 1st, 2024.
                     The AT Network community will continue to operate after that time. Information that you provide
                     after that date will not be shared with ICPSR.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Heading
                     level={6}
                     as="h3"
                  >Alternatives to Participation:</Heading>
                  <Text>Should you decide that you do not want to participate, you will still be able to view the
                     resources in the community. You will be able to see AT products as well as helpful articles that
                     might assist you in selecting assistive technology. You will not be able to ask questions or
                     interact with other community members. You may also reach out to your local AT Act Projects or
                     other AT professionals to find out further information about assistive technology.</Text>
               </Flex>

               <Flex
                  direction="column"
                  marginBottom="2rem"
               >
                  <Flex direction="column">
                     <Heading
                        level={6}
                        as="h3"
                     >Questions about the Study and Your Rights as a Research
                        Participant:</Heading>If you have any questions about the study, you may contact Maureen Linden
                     at
                     Maureen.linden @design.gatech.edu or 404-894-4960.</Flex>
                  <Text>Please note:</Text>
                  <ul style={{"marginTop": "0rem"}}>
                     <li>Your participation in this study is voluntary. You do not have to be in this study if you
                        don't want to be.
                     </li>
                     <li>You have the right to change your mind and leave the study at any time without giving any
                        reason and without penalty.
                     </li>
                     <li>Any new information that may make you change your mind about being in this study will be
                        given to you.
                     </li>
                     <li>You will be emailed a copy of this consent form to keep.</li>
                     <li>You do not waive any of your legal rights by agreeing to this consent form.</li>
                  </ul>
               </Flex>

               <Text>If you have any questions about your rights as a research participant, you may contact Georgia
                  Institute of Technology Office of Research Integrity Assurance at IRB@gatech.edu.</Text>
               <Text>If you continue on and complete a profile within our community, we will consider this as your
                  consent to take part in this study.</Text>
            </ScrollView>

            <Button
               variation="primary"
               size="small"
               type="submit"
               disabled={!enableButton}
               autoFocus
               onClick={(event) => {
                  event.preventDefault()
                  setValues((values) => {
                     return {
                        ...values,
                        step: 2
                     }
                  })
                  setPhase(2)
               }}
            >
               Agree and Proceed
            </Button>

            <Button
               size="small"
               type="submit"
               onClick={(event) => {
                  event.preventDefault()
                  setValues((values) => {
                     return {
                        ...values,
                        step: 0
                     }
                  })
                  setRegisterOpen(true)
                  setOpen(false)
               }}
            >
               Back to Register
            </Button>
         </Flex>
      </Flex>
   )
}
