import {createContext, useContext, useEffect} from "react";

export const useUserAuthenticator = () => {
   const context = useContext(UserContext)

   if (context === null) {
      throw new Error('User context needs to be set. Make sure your component is a child of the AuthenticatorService')
   }

   return context
}

const UserContext = createContext(null)

const UserService = ({
   children,
   authService,
   user
}) => {
   useEffect(() => {
   }, [user]);

   return <UserContext.Provider value={authService.current}>
      {children}
   </UserContext.Provider>
}

export default UserService
