import {Button, Flex, ScrollView, Text} from "@aws-amplify/ui-react";
import React, {useState} from "react";
import {register} from "../../../../api/auth";
import {displayMessage} from "../../../common/Toast/toastUtilities";

export default function CommunityRulesDialog({
   setPhase,
   values,
   setValues
}) {
   const [enableButton, setEnableButton] = useState(false)
   const [error, setError] = useState("")
   const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
      if (bottom && (error === "")) {
         setEnableButton(true)
      }
   }

   const handleSubmit = (e) => {
      e.preventDefault()
      register(values)
         .then(() => {
            setValues((values) => {
               return {
                  ...values,
                  step: 2
               }
            })
            setPhase(3)
         })
         .catch((err) => {
            setEnableButton(false)
            setError(err.message)
         })
   }

   return (
      <Flex
         direction="column"
         width="100%"
         gap="1rem"
         justifyContent="space-between"
         alignItems="center"
      >

         <Flex
            direction="column"
            width="100%"
            gap="1rem"
            padding="2rem"
         >
            <Text fontWeight="800">Please use the scrollbar on the side to scroll to the bottom in order to agree and proceed</Text>

            {error === ""
               ? null
               : displayMessage(() => {
                  setError("")
               }, "error", error)}

            <ScrollView
               width="100%"
               maxHeight="20rem"
               onScroll={handleScroll}
            >
               <Text>AT Network is here to provide information to people of all backgrounds about the use of assistive
                  technology. We want our community to be inviting to everyone. Please abide by these rules so that
                  all may feel welcome.</Text>
               <ul>
                  <li style={{"marginBottom": "2rem"}}>
                     <Flex direction="column">
                        <Flex
                           direction="row"
                           display="inline"
                        >
                           <Text
                              fontWeight={800}
                              display="inline"
                           >Be respectful. </Text>
                           It is easier to say hurtful or disrespectful things when you aren’t standing
                           face-to-face
                           with someone. It is important to remember that others in the community are real people
                           who
                           are affected by the words you write. You have the right to disagree with someone else’s
                           opinion, but you should keep the discussion healthy and about the topic, not the person
                           you
                           are interacting with.
                        </Flex>
                        Do not insult, provoke, or threaten other community members. Remember that insults and
                        threats
                        on the internet can have legal consequences. If you find yourself on the receiving end of an
                        insult or threat, please do not respond and report the comment to the moderators
                        immediately.
                     </Flex>
                  </li>

                  <li style={{"marginBottom": "2rem"}}>
                     <Flex
                        direction="row"
                        display="inline"
                     >
                        <Text
                           fontWeight={800}
                           display="inline"
                        >No profanity. No hate speech. </Text>
                        AT Network has a zero-tolerance policy on profanity and hate speech. We will remove comments
                        or questions containing these on sight. If you use these, you will be removed from the
                        community. The moderators have final say on determining whether something is profanity or
                        hate speech. If you find these, please report them to the moderators.
                     </Flex>
                  </li>

                  <li style={{"marginBottom": "2rem"}}>
                     <Flex
                        direction="row"
                        display="inline"
                     >
                        <Text
                           fontWeight={800}
                           display="inline"
                        >Be careful with humor and sarcasm. </Text>
                        You shouldn’t avoid being funny. We love to see your personality shine through! Make sure
                        that it is clear you are being funny and not being rude. Emoticons and smileys can be
                        helpful when conveying humor or sarcasm so that it is read correctly. 😉
                     </Flex>
                  </li>

                  <li style={{"marginBottom": "2rem"}}>
                     <Flex
                        direction="row"
                        display="inline"
                     >
                        <Text
                           fontWeight={800}
                           display="inline"
                        >Be aware of strong language, all caps, and
                           exclamation points. </Text>
                        <Text display="inline">It is easy for written text to be misread and misunderstood. Have you
                           ever sent a text
                           message with good intent, but your recipient thought you were being rude? If so, then
                           you’ve
                           experienced this firsthand. Be aware of strong language and minimize it when you
                           can. </Text>
                        <Text
                           fontWeight={600}
                           display="inline"
                        >Tip: Read everything out loud before you send
                           it.</Text>
                     </Flex>
                  </li>

                  <li style={{"marginBottom": "2rem"}}>
                     <Flex direction="column">
                        <Flex
                           direction="row"
                           display="inline"
                        >
                           <Text
                              fontWeight={800}
                              display="inline"
                           >Be forgiving. </Text>
                           It is easy for written text to be misunderstood. Remember that you might be “reading into”
                           someone’s comment with an attitude that they did not intend. Avoid escalating the discussion
                           into an argument. Sometimes a simple question, “What do you mean?” is all it takes to
                           clarify a situation.
                        </Flex>
                        Also be forgiving of the mistakes of others. There may be typos, misspellings, or punctuation
                        mistakes, or comments that are difficult to understand. Please do not judge others for these.
                     </Flex>
                  </li>

                  <li>
                     <Flex direction="column">
                        <Flex
                           direction="row"
                           display="inline"
                        >
                           <Text
                              fontWeight={800}
                              display="inline"
                           >Respect the privacy of others. </Text>
                           This is a community of people who have come together to learn and discuss assistive
                           technology. In some ways, this can be a small community. Many people may not be comfortable
                           sharing their names or other personal information. Do not attempt to identify someone in the
                           community. If you know someone personally, but they are not using their real name, do not
                           use it in your comments or replies to them.
                        </Flex>
                        Do not upload or share videos, photos, or recordings of people other than yourself or those who
                        have specifically given you permission. If the administrators are asked to remove a video,
                        photo, or recording of someone who tells of they have not given permission for use of their
                        image, we will remove first and ask questions later. We will err on the side of removing content
                        from the public view.
                     </Flex>
                  </li>
               </ul>

               Remember that the AT Network community is viewable online by anyone in the United States and Canada. It
               is a public forum. Please keep this in mind when you are interacting in our community!
            </ScrollView>

            <Button
               variation="primary"
               size="small"
               type="submit"
               disabled={!enableButton}
               onClick={handleSubmit}
            >
               Agree and Proceed
            </Button>

            <Button
               size="small"
               type="submit"
               onClick={(event) => {
                  event.preventDefault()
                  setValues((values) => {
                     return {
                        ...values,
                        step: 1
                     }
                  })
                  setPhase(1)
               }}
            >
               Back to Consent Form
            </Button>
         </Flex>
      </Flex>
   )
}
