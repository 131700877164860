import React, {useState} from 'react'
import {Button, Flex, Heading, TabItem, Tabs} from "@aws-amplify/ui-react";
import {Dialog} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ResetPassword from "./SignIn/ResetPassword";
import {useSignInContext} from "../../common/authenticator/useSignInState";
import Register from "./Register/Register";
import SignIn from "./SignIn/SignIn";
import RegistrationStepDialog from "./Register/RegistrationStepDialog";
import VerifyEmailDialog from "./SignIn/VerifyEmailDialog";

export default function AuthorizationModal() {
   const {
      open,
      setOpen,
      tab,
      setTab
   } = useSignInContext()
   const [values, setValues] = useState({
      username: '',
      password: '',
      given_name: '',
      family_name: '',
      confirm_password: '',
      code: '',
      step: 0
   })
   const [step, setStep] = useState(1)
   const [openRegistrationStepModal, setOpenRegistrationStepModal] = useState(false)
   const [resetPasswordOpen, setResetPasswordOpen] = useState(false)
   const [verifyEmailOpen, setVerifyEmailOpen] = useState(false)

   const handleClick = (index) => {
      setTab(index)
      if (index === 1 && values.step > 0) {
         setStep(values.step)
         setOpenRegistrationStepModal(true)
      } else {
         setOpen(true)
      }
   }

   return (<>
      <ResetPassword
         open={resetPasswordOpen}
         setOpen={setResetPasswordOpen}
         setSignInOpen={setOpen}
         setVerifyEmailOpen={setVerifyEmailOpen}
      />
      <VerifyEmailDialog
         open={verifyEmailOpen}
         setOpen={setVerifyEmailOpen}
         setSignInOpen={setOpen}
         values={values}
         setValues={setValues}
      />
      <RegistrationStepDialog
         step={step}
         values={values}
         setValues={setValues}
         setRegisterOpen={setOpen}
         open={openRegistrationStepModal}
         setOpen={setOpenRegistrationStepModal}
      />
      <Flex>
         <Button
            variation="secondary"
            backgroundColor="var(--amplify-colors-background-primary)"
            color="var(--amplify-colors-font-tertiary)"
            onClick={() => handleClick(0)}
         >
            Sign In
         </Button>

         <Button
            variation="primary"
            onClick={() => handleClick(1)}
         >
            Register
         </Button>
      </Flex>

      <Dialog
         fullWidth
         maxWidth="md"
         open={open}
         onClose={() => setOpen(false)}
         aria-labelledby="alert-dialog-title"
      >

         <Flex
            justifyContent="flex-end"
            height="2rem"
         >
            <Button
               gap="0.2rem"
               onClick={() => {
                  setOpen(false)
                  setValues({
                     username: '',
                     password: ''
                  })
               }}
               style={{
                  "border": "0px",
                  "margin": "1rem 1rem 0",
                  "padding": "1rem 1rem",
                  "height": "fit-content",
                  "zIndex": "2"
               }}
            >
               <CloseIcon />
            </Button>
         </Flex>

         <Flex
            direction="column"
            top="-2rem"
         >
            <Tabs
               spacing="equal"
               currentIndex={tab}
               onChange={(i) => setTab(i)}
            >
               <TabItem
                  title={<Heading
                     level="6"
                     as="h2"
                     color="var(--amplify-colors-font-interactive)"
                     id="alert-dialog-title"
                  >
                     Sign In
                  </Heading>}
               >
                  <SignIn
                     values={values}
                     setValues={setValues}
                     setOpen={setOpen}
                     setResetPasswordOpen={setResetPasswordOpen}
                     setVerifyEmailOpen={setVerifyEmailOpen}
                  />
               </TabItem>
               <TabItem
                  title={<Heading
                     level="6"
                     as="h2"
                     color="var(--amplify-colors-font-interactive)"
                     id="alert-dialog-title"
                  >
                     Register
                  </Heading>}
               >
                  <Register
                     values={values}
                     setValues={setValues}
                     setOpenRegistrationStepDialog={setOpenRegistrationStepModal}
                     setOpen={setOpen}
                  />
               </TabItem>
            </Tabs>
         </Flex>
      </Dialog>
   </>)
}
