import {SelectField} from '@aws-amplify/ui-react'
import * as React from 'react'

export const SearchType = ({types, type: selectedType, setType}) => {
   const handleTypeChange = (event) => {
      setType(event.target.value)
   }

   return (<SelectField onChange={handleTypeChange}
         label="Search Type"
         labelHidden={true}
         value={selectedType}
         inputStyles={{width: "auto"}}>
         {Object.keys(types).map((type) => {
            return <option key={type}
               value={type}>{types[type].label}</option>
         })}
      </SelectField>
   )
}
