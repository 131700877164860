export const sendEmail = (to, subject = "", body = "") => {
   window.location.href = getMailTo(to, subject, body)
}

export const getMailTo = (to, subject = "", body = "") => {
   const args = [];
   if (subject !== "") {
      args.push('subject=' + encodeURIComponent(subject));
   }

   if (body !== "") {
      args.push('body=' + encodeURIComponent(body))
   }

   return 'mailto:' + encodeURIComponent(to) + (args.length > 0
      ? '?' + args.join('&')
      : '')
}

export const getUserNameToDisplay = (user) => {
   return user?.displayName ?? `${user?.firstName} ${user?.lastName}`
}

export const extractIsos = (newIsos) => {
   return newIsos
      .reduce((isos, iso) => {
         isos[iso.id] = iso?.plainLanguageTitle ?? iso?.title

         return isos
      }, {})
}

export const searchResultsSetIsos = (newIsos, setIsos, setSelectedIsos, isoId) => {
   setIsos((currentIsos) => {
      const extractedIsos = extractIsos(newIsos)
      return {
         ...currentIsos,
         ...extractedIsos
      }
   })

   const isosToAdd = newIsos.reduce((isos, iso) => {
      isos[iso.id] = isoId === iso.id

      return isos
   }, {})

   setSelectedIsos((currentSelectedIsos) => {
         return {
            ...currentSelectedIsos,
            ...isosToAdd
         }
      }
   )
}

export const extractNewIsos = (isoId, newSearchObject, existingIsos) => {
    const newIsos = isoId && (!existingIsos[isoId])
        ? new Set([isoId])
        : new Set()

    return newIsos.add(...newSearchObject.iso2022s.items
        .reduce((carry, searchObjectIso) => {
            if(!existingIsos[searchObjectIso.iso2022ID])
            {
                carry.push(searchObjectIso.iso2022ID)
            }
            return carry
        }, [])
    )
}
