import navItems from 'components/layout/Footer/navItems.json'
import {Flex, Text} from "@aws-amplify/ui-react";
import * as React from "react";
import MenuItems from "../MenuItems/MenuItems";
import Logo from "../Header/Logo";

export default function FooterNav() {
   return (
      <Flex
         gap="12px"
         direction="column"
         justifyContent="flex-start"
         alignItems="flex-start"
      >
         <Flex
            width="100%"
         >
            <Logo />
         </Flex>

         <Text
            fontFamily="Inter"
            fontSize="large"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24.204544067382812px"
            textAlign="left"
            display="block"
            direction="column"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
         >
            Navigate
         </Text>

         <Flex
            gap="68px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
         >
            <nav>
               <MenuItems menuItems={navItems} />
            </nav>
         </Flex>
      </Flex>
   )
}


