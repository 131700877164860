/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $createdBy: String
  ) {
    onCreateUser(filter: $filter, createdBy: $createdBy) {
      id
      distributorId
      firstName
      lastName
      email
      profile
      displayName
      roles
      atMostInterestedIn
      location
      displayLocation
      enabled
      createdBy
      savedProducts {
        nextToken
        startedAt
        __typename
      }
      bookmarkedArticles {
        nextToken
        startedAt
        __typename
      }
      followedDiscussions {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      articleApprovals {
        nextToken
        startedAt
        __typename
      }
      discussions {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      profileImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bannerImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      disabledUserNotifications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userProfileImageId
      userBannerImageId
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $createdBy: String
  ) {
    onUpdateUser(filter: $filter, createdBy: $createdBy) {
      id
      distributorId
      firstName
      lastName
      email
      profile
      displayName
      roles
      atMostInterestedIn
      location
      displayLocation
      enabled
      createdBy
      savedProducts {
        nextToken
        startedAt
        __typename
      }
      bookmarkedArticles {
        nextToken
        startedAt
        __typename
      }
      followedDiscussions {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      articleApprovals {
        nextToken
        startedAt
        __typename
      }
      discussions {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      profileImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bannerImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      disabledUserNotifications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userProfileImageId
      userBannerImageId
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $createdBy: String
  ) {
    onDeleteUser(filter: $filter, createdBy: $createdBy) {
      id
      distributorId
      firstName
      lastName
      email
      profile
      displayName
      roles
      atMostInterestedIn
      location
      displayLocation
      enabled
      createdBy
      savedProducts {
        nextToken
        startedAt
        __typename
      }
      bookmarkedArticles {
        nextToken
        startedAt
        __typename
      }
      followedDiscussions {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      articleApprovals {
        nextToken
        startedAt
        __typename
      }
      discussions {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      profileImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bannerImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      disabledUserNotifications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userProfileImageId
      userBannerImageId
      __typename
    }
  }
`;
export const onCreateDistributor = /* GraphQL */ `
  subscription OnCreateDistributor(
    $filter: ModelSubscriptionDistributorFilterInput
  ) {
    onCreateDistributor(filter: $filter) {
      id
      name
      description
      abbreviation
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      products {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      links {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      mappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDistributor = /* GraphQL */ `
  subscription OnUpdateDistributor(
    $filter: ModelSubscriptionDistributorFilterInput
  ) {
    onUpdateDistributor(filter: $filter) {
      id
      name
      description
      abbreviation
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      products {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      links {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      mappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDistributor = /* GraphQL */ `
  subscription OnDeleteDistributor(
    $filter: ModelSubscriptionDistributorFilterInput
  ) {
    onDeleteDistributor(filter: $filter) {
      id
      name
      description
      abbreviation
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      products {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      links {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      mappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAuthor = /* GraphQL */ `
  subscription OnCreateAuthor($filter: ModelSubscriptionAuthorFilterInput) {
    onCreateAuthor(filter: $filter) {
      id
      name
      about
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      users {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAuthor = /* GraphQL */ `
  subscription OnUpdateAuthor($filter: ModelSubscriptionAuthorFilterInput) {
    onUpdateAuthor(filter: $filter) {
      id
      name
      about
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      users {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAuthor = /* GraphQL */ `
  subscription OnDeleteAuthor($filter: ModelSubscriptionAuthorFilterInput) {
    onDeleteAuthor(filter: $filter) {
      id
      name
      about
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      users {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
    onCreateProduct(filter: $filter) {
      id
      distributorId
      hasImage
      hasIso2022
      hasDistributor
      productKey
      name
      listPrice
      manufacturer
      gtin
      asin
      sku
      submittedMakerSeller
      status
      description
      weightGrams
      variantOptions
      keywords
      details
      technicalSpecifications
      videoUrl
      internalNotes
      latestImageAddedAt
      createdAt
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      userSaves {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      vendorLinks {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
    onUpdateProduct(filter: $filter) {
      id
      distributorId
      hasImage
      hasIso2022
      hasDistributor
      productKey
      name
      listPrice
      manufacturer
      gtin
      asin
      sku
      submittedMakerSeller
      status
      description
      weightGrams
      variantOptions
      keywords
      details
      technicalSpecifications
      videoUrl
      internalNotes
      latestImageAddedAt
      createdAt
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      userSaves {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      vendorLinks {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
    onDeleteProduct(filter: $filter) {
      id
      distributorId
      hasImage
      hasIso2022
      hasDistributor
      productKey
      name
      listPrice
      manufacturer
      gtin
      asin
      sku
      submittedMakerSeller
      status
      description
      weightGrams
      variantOptions
      keywords
      details
      technicalSpecifications
      videoUrl
      internalNotes
      latestImageAddedAt
      createdAt
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      userSaves {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      vendorLinks {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVariant = /* GraphQL */ `
  subscription OnCreateVariant($filter: ModelSubscriptionVariantFilterInput) {
    onCreateVariant(filter: $filter) {
      id
      productId
      distributorId
      variantKey
      listPrice
      gtin
      asin
      sku
      status
      description
      weightGrams
      prices {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateVariant = /* GraphQL */ `
  subscription OnUpdateVariant($filter: ModelSubscriptionVariantFilterInput) {
    onUpdateVariant(filter: $filter) {
      id
      productId
      distributorId
      variantKey
      listPrice
      gtin
      asin
      sku
      status
      description
      weightGrams
      prices {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteVariant = /* GraphQL */ `
  subscription OnDeleteVariant($filter: ModelSubscriptionVariantFilterInput) {
    onDeleteVariant(filter: $filter) {
      id
      productId
      distributorId
      variantKey
      listPrice
      gtin
      asin
      sku
      status
      description
      weightGrams
      prices {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePrice = /* GraphQL */ `
  subscription OnCreatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onCreatePrice(filter: $filter) {
      id
      productId
      variantId
      distributorId
      price
      linkText
      url
      pricingNotes
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      variant {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePrice = /* GraphQL */ `
  subscription OnUpdatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onUpdatePrice(filter: $filter) {
      id
      productId
      variantId
      distributorId
      price
      linkText
      url
      pricingNotes
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      variant {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePrice = /* GraphQL */ `
  subscription OnDeletePrice($filter: ModelSubscriptionPriceFilterInput) {
    onDeletePrice(filter: $filter) {
      id
      productId
      variantId
      distributorId
      price
      linkText
      url
      pricingNotes
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      variant {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLink = /* GraphQL */ `
  subscription OnCreateLink($filter: ModelSubscriptionLinkFilterInput) {
    onCreateLink(filter: $filter) {
      id
      productId
      distributorId
      linkText
      url
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLink = /* GraphQL */ `
  subscription OnUpdateLink($filter: ModelSubscriptionLinkFilterInput) {
    onUpdateLink(filter: $filter) {
      id
      productId
      distributorId
      linkText
      url
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLink = /* GraphQL */ `
  subscription OnDeleteLink($filter: ModelSubscriptionLinkFilterInput) {
    onDeleteLink(filter: $filter) {
      id
      productId
      distributorId
      linkText
      url
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onCreateQuestion(filter: $filter) {
      id
      userId
      productId
      parentQuestionId
      body
      answers {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onUpdateQuestion(filter: $filter) {
      id
      userId
      productId
      parentQuestionId
      body
      answers {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onDeleteQuestion(filter: $filter) {
      id
      userId
      productId
      parentQuestionId
      body
      answers {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
    onCreateReview(filter: $filter) {
      id
      ratingId
      userId
      productId
      parentReviewId
      body
      responses {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentReview {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
    onUpdateReview(filter: $filter) {
      id
      ratingId
      userId
      productId
      parentReviewId
      body
      responses {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentReview {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
    onDeleteReview(filter: $filter) {
      id
      ratingId
      userId
      productId
      parentReviewId
      body
      responses {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentReview {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRating = /* GraphQL */ `
  subscription OnCreateRating($filter: ModelSubscriptionRatingFilterInput) {
    onCreateRating(filter: $filter) {
      id
      userId
      productId
      reviewId
      rating
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRating = /* GraphQL */ `
  subscription OnUpdateRating($filter: ModelSubscriptionRatingFilterInput) {
    onUpdateRating(filter: $filter) {
      id
      userId
      productId
      reviewId
      rating
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRating = /* GraphQL */ `
  subscription OnDeleteRating($filter: ModelSubscriptionRatingFilterInput) {
    onDeleteRating(filter: $filter) {
      id
      userId
      productId
      reviewId
      rating
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArticle = /* GraphQL */ `
  subscription OnCreateArticle($filter: ModelSubscriptionArticleFilterInput) {
    onCreateArticle(filter: $filter) {
      id
      type
      mainImageId
      articleApprovalId
      title
      prefix
      key
      published
      wordCount
      authorUserIds
      mainImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      userBookmarks {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleApproval {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArticle = /* GraphQL */ `
  subscription OnUpdateArticle($filter: ModelSubscriptionArticleFilterInput) {
    onUpdateArticle(filter: $filter) {
      id
      type
      mainImageId
      articleApprovalId
      title
      prefix
      key
      published
      wordCount
      authorUserIds
      mainImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      userBookmarks {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleApproval {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArticle = /* GraphQL */ `
  subscription OnDeleteArticle($filter: ModelSubscriptionArticleFilterInput) {
    onDeleteArticle(filter: $filter) {
      id
      type
      mainImageId
      articleApprovalId
      title
      prefix
      key
      published
      wordCount
      authorUserIds
      mainImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      userBookmarks {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleApproval {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArticleApproval = /* GraphQL */ `
  subscription OnCreateArticleApproval(
    $filter: ModelSubscriptionArticleApprovalFilterInput
  ) {
    onCreateArticleApproval(filter: $filter) {
      id
      articleId
      approvedByUserId
      notes
      dateApproved
      approvedBy {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArticleApproval = /* GraphQL */ `
  subscription OnUpdateArticleApproval(
    $filter: ModelSubscriptionArticleApprovalFilterInput
  ) {
    onUpdateArticleApproval(filter: $filter) {
      id
      articleId
      approvedByUserId
      notes
      dateApproved
      approvedBy {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArticleApproval = /* GraphQL */ `
  subscription OnDeleteArticleApproval(
    $filter: ModelSubscriptionArticleApprovalFilterInput
  ) {
    onDeleteArticleApproval(filter: $filter) {
      id
      articleId
      approvedByUserId
      notes
      dateApproved
      approvedBy {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArticleSection = /* GraphQL */ `
  subscription OnCreateArticleSection(
    $filter: ModelSubscriptionArticleSectionFilterInput
  ) {
    onCreateArticleSection(filter: $filter) {
      id
      articleId
      articleSectionId
      title
      sortWeight
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleComponents {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentArticleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArticleSection = /* GraphQL */ `
  subscription OnUpdateArticleSection(
    $filter: ModelSubscriptionArticleSectionFilterInput
  ) {
    onUpdateArticleSection(filter: $filter) {
      id
      articleId
      articleSectionId
      title
      sortWeight
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleComponents {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentArticleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArticleSection = /* GraphQL */ `
  subscription OnDeleteArticleSection(
    $filter: ModelSubscriptionArticleSectionFilterInput
  ) {
    onDeleteArticleSection(filter: $filter) {
      id
      articleId
      articleSectionId
      title
      sortWeight
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleComponents {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentArticleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArticleComponent = /* GraphQL */ `
  subscription OnCreateArticleComponent(
    $filter: ModelSubscriptionArticleComponentFilterInput
  ) {
    onCreateArticleComponent(filter: $filter) {
      id
      articleSectionId
      sortWeight
      bodies {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      articleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArticleComponent = /* GraphQL */ `
  subscription OnUpdateArticleComponent(
    $filter: ModelSubscriptionArticleComponentFilterInput
  ) {
    onUpdateArticleComponent(filter: $filter) {
      id
      articleSectionId
      sortWeight
      bodies {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      articleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArticleComponent = /* GraphQL */ `
  subscription OnDeleteArticleComponent(
    $filter: ModelSubscriptionArticleComponentFilterInput
  ) {
    onDeleteArticleComponent(filter: $filter) {
      id
      articleSectionId
      sortWeight
      bodies {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      articleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBody = /* GraphQL */ `
  subscription OnCreateBody($filter: ModelSubscriptionBodyFilterInput) {
    onCreateBody(filter: $filter) {
      id
      articleComponentId
      body
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBody = /* GraphQL */ `
  subscription OnUpdateBody($filter: ModelSubscriptionBodyFilterInput) {
    onUpdateBody(filter: $filter) {
      id
      articleComponentId
      body
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBody = /* GraphQL */ `
  subscription OnDeleteBody($filter: ModelSubscriptionBodyFilterInput) {
    onDeleteBody(filter: $filter) {
      id
      articleComponentId
      body
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDiscussion = /* GraphQL */ `
  subscription OnCreateDiscussion(
    $filter: ModelSubscriptionDiscussionFilterInput
  ) {
    onCreateDiscussion(filter: $filter) {
      id
      userId
      topicId
      title
      body
      popularity
      userFollows {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      topic {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDiscussion = /* GraphQL */ `
  subscription OnUpdateDiscussion(
    $filter: ModelSubscriptionDiscussionFilterInput
  ) {
    onUpdateDiscussion(filter: $filter) {
      id
      userId
      topicId
      title
      body
      popularity
      userFollows {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      topic {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDiscussion = /* GraphQL */ `
  subscription OnDeleteDiscussion(
    $filter: ModelSubscriptionDiscussionFilterInput
  ) {
    onDeleteDiscussion(filter: $filter) {
      id
      userId
      topicId
      title
      body
      popularity
      userFollows {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      topic {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      id
      userId
      articleId
      discussionId
      parentCommentId
      comment
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentComment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      id
      userId
      articleId
      discussionId
      parentCommentId
      comment
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentComment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      id
      userId
      articleId
      discussionId
      parentCommentId
      comment
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentComment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVote = /* GraphQL */ `
  subscription OnCreateVote(
    $filter: ModelSubscriptionVoteFilterInput
    $owner: String
  ) {
    onCreateVote(filter: $filter, owner: $owner) {
      id
      userId
      commentId
      reviewId
      questionId
      articleId
      discussionId
      vote
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      question {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateVote = /* GraphQL */ `
  subscription OnUpdateVote(
    $filter: ModelSubscriptionVoteFilterInput
    $owner: String
  ) {
    onUpdateVote(filter: $filter, owner: $owner) {
      id
      userId
      commentId
      reviewId
      questionId
      articleId
      discussionId
      vote
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      question {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteVote = /* GraphQL */ `
  subscription OnDeleteVote(
    $filter: ModelSubscriptionVoteFilterInput
    $owner: String
  ) {
    onDeleteVote(filter: $filter, owner: $owner) {
      id
      userId
      commentId
      reviewId
      questionId
      articleId
      discussionId
      vote
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      question {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage($filter: ModelSubscriptionImageFilterInput) {
    onCreateImage(filter: $filter) {
      id
      productId
      articleId
      articleComponentId
      discussionId
      reviewId
      distributorId
      profileUserId
      bannerUserId
      url
      prefix
      key
      altText
      title
      width
      height
      position
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      bannerUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage($filter: ModelSubscriptionImageFilterInput) {
    onUpdateImage(filter: $filter) {
      id
      productId
      articleId
      articleComponentId
      discussionId
      reviewId
      distributorId
      profileUserId
      bannerUserId
      url
      prefix
      key
      altText
      title
      width
      height
      position
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      bannerUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage($filter: ModelSubscriptionImageFilterInput) {
    onDeleteImage(filter: $filter) {
      id
      productId
      articleId
      articleComponentId
      discussionId
      reviewId
      distributorId
      profileUserId
      bannerUserId
      url
      prefix
      key
      altText
      title
      width
      height
      position
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      bannerUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo($filter: ModelSubscriptionVideoFilterInput) {
    onCreateVideo(filter: $filter) {
      id
      productId
      articleComponentId
      discussionId
      reviewId
      distributorId
      prefix
      videoKey
      transcriptKey
      title
      length
      language
      asl
      openCaptions
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo($filter: ModelSubscriptionVideoFilterInput) {
    onUpdateVideo(filter: $filter) {
      id
      productId
      articleComponentId
      discussionId
      reviewId
      distributorId
      prefix
      videoKey
      transcriptKey
      title
      length
      language
      asl
      openCaptions
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo($filter: ModelSubscriptionVideoFilterInput) {
    onDeleteVideo(filter: $filter) {
      id
      productId
      articleComponentId
      discussionId
      reviewId
      distributorId
      prefix
      videoKey
      transcriptKey
      title
      length
      language
      asl
      openCaptions
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVideoCaption = /* GraphQL */ `
  subscription OnCreateVideoCaption(
    $filter: ModelSubscriptionVideoCaptionFilterInput
  ) {
    onCreateVideoCaption(filter: $filter) {
      id
      videoId
      distributorId
      captionKey
      language
      video {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateVideoCaption = /* GraphQL */ `
  subscription OnUpdateVideoCaption(
    $filter: ModelSubscriptionVideoCaptionFilterInput
  ) {
    onUpdateVideoCaption(filter: $filter) {
      id
      videoId
      distributorId
      captionKey
      language
      video {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteVideoCaption = /* GraphQL */ `
  subscription OnDeleteVideoCaption(
    $filter: ModelSubscriptionVideoCaptionFilterInput
  ) {
    onDeleteVideoCaption(filter: $filter) {
      id
      videoId
      distributorId
      captionKey
      language
      video {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateIso = /* GraphQL */ `
  subscription OnCreateIso($filter: ModelSubscriptionIsoFilterInput) {
    onCreateIso(filter: $filter) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateIso = /* GraphQL */ `
  subscription OnUpdateIso($filter: ModelSubscriptionIsoFilterInput) {
    onUpdateIso(filter: $filter) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteIso = /* GraphQL */ `
  subscription OnDeleteIso($filter: ModelSubscriptionIsoFilterInput) {
    onDeleteIso(filter: $filter) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateIso2022 = /* GraphQL */ `
  subscription OnCreateIso2022($filter: ModelSubscriptionIso2022FilterInput) {
    onCreateIso2022(filter: $filter) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateIso2022 = /* GraphQL */ `
  subscription OnUpdateIso2022($filter: ModelSubscriptionIso2022FilterInput) {
    onUpdateIso2022(filter: $filter) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteIso2022 = /* GraphQL */ `
  subscription OnDeleteIso2022($filter: ModelSubscriptionIso2022FilterInput) {
    onDeleteIso2022(filter: $filter) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTopic = /* GraphQL */ `
  subscription OnCreateTopic($filter: ModelSubscriptionTopicFilterInput) {
    onCreateTopic(filter: $filter) {
      id
      title
      description
      discussions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTopic = /* GraphQL */ `
  subscription OnUpdateTopic($filter: ModelSubscriptionTopicFilterInput) {
    onUpdateTopic(filter: $filter) {
      id
      title
      description
      discussions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTopic = /* GraphQL */ `
  subscription OnDeleteTopic($filter: ModelSubscriptionTopicFilterInput) {
    onDeleteTopic(filter: $filter) {
      id
      title
      description
      discussions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserSubscription = /* GraphQL */ `
  subscription OnCreateUserSubscription(
    $filter: ModelSubscriptionUserSubscriptionFilterInput
  ) {
    onCreateUserSubscription(filter: $filter) {
      id
      articleId
      productId
      commentId
      discussionId
      userId
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserSubscription = /* GraphQL */ `
  subscription OnUpdateUserSubscription(
    $filter: ModelSubscriptionUserSubscriptionFilterInput
  ) {
    onUpdateUserSubscription(filter: $filter) {
      id
      articleId
      productId
      commentId
      discussionId
      userId
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserSubscription = /* GraphQL */ `
  subscription OnDeleteUserSubscription(
    $filter: ModelSubscriptionUserSubscriptionFilterInput
  ) {
    onDeleteUserSubscription(filter: $filter) {
      id
      articleId
      productId
      commentId
      discussionId
      userId
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity($filter: ModelSubscriptionActivityFilterInput) {
    onCreateActivity(filter: $filter) {
      id
      message
      url
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity($filter: ModelSubscriptionActivityFilterInput) {
    onUpdateActivity(filter: $filter) {
      id
      message
      url
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity($filter: ModelSubscriptionActivityFilterInput) {
    onDeleteActivity(filter: $filter) {
      id
      message
      url
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSubscribedActivity = /* GraphQL */ `
  subscription OnCreateSubscribedActivity(
    $filter: ModelSubscriptionSubscribedActivityFilterInput
  ) {
    onCreateSubscribedActivity(filter: $filter) {
      id
      userId
      userSubscriptionId
      activityId
      read
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      activity {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscription {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSubscribedActivity = /* GraphQL */ `
  subscription OnUpdateSubscribedActivity(
    $filter: ModelSubscriptionSubscribedActivityFilterInput
  ) {
    onUpdateSubscribedActivity(filter: $filter) {
      id
      userId
      userSubscriptionId
      activityId
      read
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      activity {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscription {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSubscribedActivity = /* GraphQL */ `
  subscription OnDeleteSubscribedActivity(
    $filter: ModelSubscriptionSubscribedActivityFilterInput
  ) {
    onDeleteSubscribedActivity(filter: $filter) {
      id
      userId
      userSubscriptionId
      activityId
      read
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      activity {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscription {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDisabledUserNotifications = /* GraphQL */ `
  subscription OnCreateDisabledUserNotifications(
    $filter: ModelSubscriptionDisabledUserNotificationsFilterInput
  ) {
    onCreateDisabledUserNotifications(filter: $filter) {
      id
      userId
      resource
      activityType
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDisabledUserNotifications = /* GraphQL */ `
  subscription OnUpdateDisabledUserNotifications(
    $filter: ModelSubscriptionDisabledUserNotificationsFilterInput
  ) {
    onUpdateDisabledUserNotifications(filter: $filter) {
      id
      userId
      resource
      activityType
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDisabledUserNotifications = /* GraphQL */ `
  subscription OnDeleteDisabledUserNotifications(
    $filter: ModelSubscriptionDisabledUserNotificationsFilterInput
  ) {
    onDeleteDisabledUserNotifications(filter: $filter) {
      id
      userId
      resource
      activityType
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateImport = /* GraphQL */ `
  subscription OnCreateImport($filter: ModelSubscriptionImportFilterInput) {
    onCreateImport(filter: $filter) {
      id
      distributorId
      importId
      prefix
      key
      status
      startTime
      endTime
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateImport = /* GraphQL */ `
  subscription OnUpdateImport($filter: ModelSubscriptionImportFilterInput) {
    onUpdateImport(filter: $filter) {
      id
      distributorId
      importId
      prefix
      key
      status
      startTime
      endTime
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteImport = /* GraphQL */ `
  subscription OnDeleteImport($filter: ModelSubscriptionImportFilterInput) {
    onDeleteImport(filter: $filter) {
      id
      distributorId
      importId
      prefix
      key
      status
      startTime
      endTime
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateProductImport = /* GraphQL */ `
  subscription OnCreateProductImport(
    $filter: ModelSubscriptionProductImportFilterInput
  ) {
    onCreateProductImport(filter: $filter) {
      id
      productId
      importId
      distributorId
      productKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateProductImport = /* GraphQL */ `
  subscription OnUpdateProductImport(
    $filter: ModelSubscriptionProductImportFilterInput
  ) {
    onUpdateProductImport(filter: $filter) {
      id
      productId
      importId
      distributorId
      productKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteProductImport = /* GraphQL */ `
  subscription OnDeleteProductImport(
    $filter: ModelSubscriptionProductImportFilterInput
  ) {
    onDeleteProductImport(filter: $filter) {
      id
      productId
      importId
      distributorId
      productKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVariantImport = /* GraphQL */ `
  subscription OnCreateVariantImport(
    $filter: ModelSubscriptionVariantImportFilterInput
  ) {
    onCreateVariantImport(filter: $filter) {
      id
      variantId
      importId
      distributorId
      productKey
      variantKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateVariantImport = /* GraphQL */ `
  subscription OnUpdateVariantImport(
    $filter: ModelSubscriptionVariantImportFilterInput
  ) {
    onUpdateVariantImport(filter: $filter) {
      id
      variantId
      importId
      distributorId
      productKey
      variantKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteVariantImport = /* GraphQL */ `
  subscription OnDeleteVariantImport(
    $filter: ModelSubscriptionVariantImportFilterInput
  ) {
    onDeleteVariantImport(filter: $filter) {
      id
      variantId
      importId
      distributorId
      productKey
      variantKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMapping = /* GraphQL */ `
  subscription OnCreateMapping($filter: ModelSubscriptionMappingFilterInput) {
    onCreateMapping(filter: $filter) {
      id
      distributorId
      name
      productKeyFields
      variantKeyFields
      variantOptionFields
      variantValueFields
      categoryFields
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMapping = /* GraphQL */ `
  subscription OnUpdateMapping($filter: ModelSubscriptionMappingFilterInput) {
    onUpdateMapping(filter: $filter) {
      id
      distributorId
      name
      productKeyFields
      variantKeyFields
      variantOptionFields
      variantValueFields
      categoryFields
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMapping = /* GraphQL */ `
  subscription OnDeleteMapping($filter: ModelSubscriptionMappingFilterInput) {
    onDeleteMapping(filter: $filter) {
      id
      distributorId
      name
      productKeyFields
      variantKeyFields
      variantOptionFields
      variantValueFields
      categoryFields
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateIsoMapping = /* GraphQL */ `
  subscription OnCreateIsoMapping(
    $filter: ModelSubscriptionIsoMappingFilterInput
  ) {
    onCreateIsoMapping(filter: $filter) {
      id
      mappingId
      isoId
      iso2022Id
      distributorId
      distributorCategory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateIsoMapping = /* GraphQL */ `
  subscription OnUpdateIsoMapping(
    $filter: ModelSubscriptionIsoMappingFilterInput
  ) {
    onUpdateIsoMapping(filter: $filter) {
      id
      mappingId
      isoId
      iso2022Id
      distributorId
      distributorCategory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteIsoMapping = /* GraphQL */ `
  subscription OnDeleteIsoMapping(
    $filter: ModelSubscriptionIsoMappingFilterInput
  ) {
    onDeleteIsoMapping(filter: $filter) {
      id
      mappingId
      isoId
      iso2022Id
      distributorId
      distributorCategory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateFieldMapping = /* GraphQL */ `
  subscription OnCreateFieldMapping(
    $filter: ModelSubscriptionFieldMappingFilterInput
  ) {
    onCreateFieldMapping(filter: $filter) {
      id
      mappingId
      distributorId
      internalField
      distributorField
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFieldMapping = /* GraphQL */ `
  subscription OnUpdateFieldMapping(
    $filter: ModelSubscriptionFieldMappingFilterInput
  ) {
    onUpdateFieldMapping(filter: $filter) {
      id
      mappingId
      distributorId
      internalField
      distributorField
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFieldMapping = /* GraphQL */ `
  subscription OnDeleteFieldMapping(
    $filter: ModelSubscriptionFieldMappingFilterInput
  ) {
    onDeleteFieldMapping(filter: $filter) {
      id
      mappingId
      distributorId
      internalField
      distributorField
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTempProductIso = /* GraphQL */ `
  subscription OnCreateTempProductIso(
    $filter: ModelSubscriptionTempProductIsoFilterInput
  ) {
    onCreateTempProductIso(filter: $filter) {
      id
      productID
      isoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTempProductIso = /* GraphQL */ `
  subscription OnUpdateTempProductIso(
    $filter: ModelSubscriptionTempProductIsoFilterInput
  ) {
    onUpdateTempProductIso(filter: $filter) {
      id
      productID
      isoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTempProductIso = /* GraphQL */ `
  subscription OnDeleteTempProductIso(
    $filter: ModelSubscriptionTempProductIsoFilterInput
  ) {
    onDeleteTempProductIso(filter: $filter) {
      id
      productID
      isoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSavedProduct = /* GraphQL */ `
  subscription OnCreateSavedProduct(
    $filter: ModelSubscriptionSavedProductFilterInput
    $createdBy: String
  ) {
    onCreateSavedProduct(filter: $filter, createdBy: $createdBy) {
      id
      userID
      productID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onUpdateSavedProduct = /* GraphQL */ `
  subscription OnUpdateSavedProduct(
    $filter: ModelSubscriptionSavedProductFilterInput
    $createdBy: String
  ) {
    onUpdateSavedProduct(filter: $filter, createdBy: $createdBy) {
      id
      userID
      productID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onDeleteSavedProduct = /* GraphQL */ `
  subscription OnDeleteSavedProduct(
    $filter: ModelSubscriptionSavedProductFilterInput
    $createdBy: String
  ) {
    onDeleteSavedProduct(filter: $filter, createdBy: $createdBy) {
      id
      userID
      productID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onCreateBookmarkedArticle = /* GraphQL */ `
  subscription OnCreateBookmarkedArticle(
    $filter: ModelSubscriptionBookmarkedArticleFilterInput
    $createdBy: String
  ) {
    onCreateBookmarkedArticle(filter: $filter, createdBy: $createdBy) {
      id
      userID
      articleID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onUpdateBookmarkedArticle = /* GraphQL */ `
  subscription OnUpdateBookmarkedArticle(
    $filter: ModelSubscriptionBookmarkedArticleFilterInput
    $createdBy: String
  ) {
    onUpdateBookmarkedArticle(filter: $filter, createdBy: $createdBy) {
      id
      userID
      articleID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onDeleteBookmarkedArticle = /* GraphQL */ `
  subscription OnDeleteBookmarkedArticle(
    $filter: ModelSubscriptionBookmarkedArticleFilterInput
    $createdBy: String
  ) {
    onDeleteBookmarkedArticle(filter: $filter, createdBy: $createdBy) {
      id
      userID
      articleID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onCreateFollowedDiscussion = /* GraphQL */ `
  subscription OnCreateFollowedDiscussion(
    $filter: ModelSubscriptionFollowedDiscussionFilterInput
    $createdBy: String
  ) {
    onCreateFollowedDiscussion(filter: $filter, createdBy: $createdBy) {
      id
      userID
      discussionID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onUpdateFollowedDiscussion = /* GraphQL */ `
  subscription OnUpdateFollowedDiscussion(
    $filter: ModelSubscriptionFollowedDiscussionFilterInput
    $createdBy: String
  ) {
    onUpdateFollowedDiscussion(filter: $filter, createdBy: $createdBy) {
      id
      userID
      discussionID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onDeleteFollowedDiscussion = /* GraphQL */ `
  subscription OnDeleteFollowedDiscussion(
    $filter: ModelSubscriptionFollowedDiscussionFilterInput
    $createdBy: String
  ) {
    onDeleteFollowedDiscussion(filter: $filter, createdBy: $createdBy) {
      id
      userID
      discussionID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onCreateAuthorUser = /* GraphQL */ `
  subscription OnCreateAuthorUser(
    $filter: ModelSubscriptionAuthorUserFilterInput
    $createdBy: String
  ) {
    onCreateAuthorUser(filter: $filter, createdBy: $createdBy) {
      id
      userID
      authorID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onUpdateAuthorUser = /* GraphQL */ `
  subscription OnUpdateAuthorUser(
    $filter: ModelSubscriptionAuthorUserFilterInput
    $createdBy: String
  ) {
    onUpdateAuthorUser(filter: $filter, createdBy: $createdBy) {
      id
      userID
      authorID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onDeleteAuthorUser = /* GraphQL */ `
  subscription OnDeleteAuthorUser(
    $filter: ModelSubscriptionAuthorUserFilterInput
    $createdBy: String
  ) {
    onDeleteAuthorUser(filter: $filter, createdBy: $createdBy) {
      id
      userID
      authorID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const onCreateArticleAuthor = /* GraphQL */ `
  subscription OnCreateArticleAuthor(
    $filter: ModelSubscriptionArticleAuthorFilterInput
  ) {
    onCreateArticleAuthor(filter: $filter) {
      id
      authorID
      articleID
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArticleAuthor = /* GraphQL */ `
  subscription OnUpdateArticleAuthor(
    $filter: ModelSubscriptionArticleAuthorFilterInput
  ) {
    onUpdateArticleAuthor(filter: $filter) {
      id
      authorID
      articleID
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArticleAuthor = /* GraphQL */ `
  subscription OnDeleteArticleAuthor(
    $filter: ModelSubscriptionArticleAuthorFilterInput
  ) {
    onDeleteArticleAuthor(filter: $filter) {
      id
      authorID
      articleID
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateProductIso = /* GraphQL */ `
  subscription OnCreateProductIso(
    $filter: ModelSubscriptionProductIsoFilterInput
  ) {
    onCreateProductIso(filter: $filter) {
      id
      productID
      isoID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateProductIso = /* GraphQL */ `
  subscription OnUpdateProductIso(
    $filter: ModelSubscriptionProductIsoFilterInput
  ) {
    onUpdateProductIso(filter: $filter) {
      id
      productID
      isoID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteProductIso = /* GraphQL */ `
  subscription OnDeleteProductIso(
    $filter: ModelSubscriptionProductIsoFilterInput
  ) {
    onDeleteProductIso(filter: $filter) {
      id
      productID
      isoID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateProductIso2022 = /* GraphQL */ `
  subscription OnCreateProductIso2022(
    $filter: ModelSubscriptionProductIso2022FilterInput
  ) {
    onCreateProductIso2022(filter: $filter) {
      id
      productID
      iso2022ID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateProductIso2022 = /* GraphQL */ `
  subscription OnUpdateProductIso2022(
    $filter: ModelSubscriptionProductIso2022FilterInput
  ) {
    onUpdateProductIso2022(filter: $filter) {
      id
      productID
      iso2022ID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteProductIso2022 = /* GraphQL */ `
  subscription OnDeleteProductIso2022(
    $filter: ModelSubscriptionProductIso2022FilterInput
  ) {
    onDeleteProductIso2022(filter: $filter) {
      id
      productID
      iso2022ID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArticleIso = /* GraphQL */ `
  subscription OnCreateArticleIso(
    $filter: ModelSubscriptionArticleIsoFilterInput
  ) {
    onCreateArticleIso(filter: $filter) {
      id
      articleID
      isoID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArticleIso = /* GraphQL */ `
  subscription OnUpdateArticleIso(
    $filter: ModelSubscriptionArticleIsoFilterInput
  ) {
    onUpdateArticleIso(filter: $filter) {
      id
      articleID
      isoID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArticleIso = /* GraphQL */ `
  subscription OnDeleteArticleIso(
    $filter: ModelSubscriptionArticleIsoFilterInput
  ) {
    onDeleteArticleIso(filter: $filter) {
      id
      articleID
      isoID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArticleIso2022 = /* GraphQL */ `
  subscription OnCreateArticleIso2022(
    $filter: ModelSubscriptionArticleIso2022FilterInput
  ) {
    onCreateArticleIso2022(filter: $filter) {
      id
      articleID
      iso2022ID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArticleIso2022 = /* GraphQL */ `
  subscription OnUpdateArticleIso2022(
    $filter: ModelSubscriptionArticleIso2022FilterInput
  ) {
    onUpdateArticleIso2022(filter: $filter) {
      id
      articleID
      iso2022ID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArticleIso2022 = /* GraphQL */ `
  subscription OnDeleteArticleIso2022(
    $filter: ModelSubscriptionArticleIso2022FilterInput
  ) {
    onDeleteArticleIso2022(filter: $filter) {
      id
      articleID
      iso2022ID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
