import {Step, StepLabel, Stepper} from "@mui/material";
import React from "react";

export const RegistrationStepper = ({activeStep}) => {
   const registrationSteps = [
      "Personal information",
      "Consent form",
      "Community rules",
      "Verification"
   ]

   return (
      <Stepper
         activeStep={activeStep}
         alternativeLabel
         style={{paddingTop: "2rem"}}
      >
         {
            registrationSteps.map((label) =>
               (
                  <Step key={label}>
                     <StepLabel>{label}</StepLabel>
                  </Step>
               )
            )
         }
      </Stepper>
   )
}
