import {Auth, Hub, Logger} from 'aws-amplify'
import {errorAlert} from './alerts'

export const logger = new Logger('auth')

export const signIn = (values, userApi) => {
   return Auth.signIn(values.username.toLowerCase(), values.password)
      .then((cognitoUser) => {
         if (isNewPasswordRequired(cognitoUser)) {
            return Promise.reject(cognitoUser)
         }

         return userApi.getByField('id', cognitoUser.attributes.sub, {})
      })
      .then((users) => {
         Hub.dispatch('alerts', {
            event: 'signIn',
            data: {severity: 'info'},
            message: 'You have successfully signed in.'
         })
         return users[0]
      })
      .catch((error) => {
         return Promise.reject(error)
      })
}

export const register = (values) => {
   return Auth.signUp({
      username: values.username,
      password: values.password,
      attributes: {
         given_name: values.given_name,
         family_name: values.family_name,

      },
      autoSignIn: {
         enabled: true,
      }
   })
      .then(() => {
         Hub.dispatch('alerts', {
            event: 'signUp',
            data: {severity: 'info'},
            message: 'You have successfully registered.'
         })
      })
      .catch((error) => {
         return Promise.reject(error)
      })
}

export const confirmRegister = ({
   username,
   code
}) => {
   return Auth
      .confirmSignUp(username, code)
      .then(() => {
         Hub.dispatch('alerts', {
            event: 'confirmRegistration',
            data: {severity: 'info'},
            message: 'Your registration has been confirmed'
         })
      })
      .catch((error) => {
         return Promise.reject(error)
      })
}

export const signOut = () => {
   return Auth.signOut().then(() => {
      Hub.dispatch('alerts', {
         event: 'signOut',
         data: {severity: 'info'},
         message: 'You have successfully signed out.'
      })
   }).catch((error) => {
      errorAlert(error)

      return Promise.reject(error)
   })
}

export const setNewRequiredPassword = (email, values, userApi) => {
   return Auth.signIn(email.toLowerCase(), values.password)
      .then((cognitoUser) => {
         return Auth.completeNewPassword(cognitoUser, values.newPassword, {})
      })
      .then((cognitoUser) => {
         if (cognitoUser.challengeName) {
            return Promise.reject(cognitoUser.challengeName)
         }

         return Auth.currentAuthenticatedUser()
      })
      .then((cognitoUser) => {
         return userApi.getByField('cognitoId', cognitoUser.username)
      }).then((users) => {
         Hub.dispatch('alerts', {
            event: 'setNewRequiredPassword',
            data: {severity: 'success'},
            message: 'Your password was successfully updated and you have been signed in.'
         })

         return users[0]
      })
      .catch((error) => {
         errorAlert(error)

         return Promise.reject(error)
      })
}

export const setNewPassword = (values, context) => {
   return Auth.changePassword(context.cognitoUser, values.password, values.newPassword).then(() => {
      Hub.dispatch('alerts', {
         event: 'setNewPassword',
         data: {severity: 'success'},
         message: 'Your password was successfully updated.'
      })

      return true
   }).catch((error) => {

      return Promise.reject(error)
   })
}

export const sendEmailVerification = (values) => {
   return Auth.resendSignUp(values.username)
      .catch((error) => {
         return Promise.reject(error)
      })
}

export const verifyEmail = (values, context) => {
   return Auth.verifyCurrentUserAttributeSubmit('email', values.code)
      .then(() => {
         return Auth.currentAuthenticatedUser()
      })
      .then((cognitoUser) => {
         context.setCognitoUser(cognitoUser)
      })
      .then(() => {
         Hub.dispatch('alerts', {
            event: 'verifyEmail',
            data: {severity: 'success'},
            message: 'Your email address has been successfully verified.'
         })

         return true
      })
      .catch((error) => {
         errorAlert(error)

         return error
      })
}

export const sendForgotPassword = async (values) => {
   try {
      await Auth.forgotPassword(values.email.toLowerCase());
      Hub.dispatch('alerts', {
         event: 'sendForgotPassword',
         data: {severity: 'success'},
         message: 'A verification code was sent to your email.'
      })
   }
   catch (error) {
      return Promise.reject(error)
   }
}

export const verifyForgotPassword = (values) => {
   return Auth.forgotPasswordSubmit(values.email.toLowerCase(), values.code, values.password).then(() => {
      Hub.dispatch('alerts', {
         event: 'verifyForgotPassword',
         data: {severity: 'success'},
         message: 'Your password was successfully updated.'
      })

      return true
   }).catch((error) => {

      return Promise.reject(error)
   })
}

export const isPasswordExpired = (error) => {
   return error.message === 'Temporary password has expired and must be reset by an administrator.'
}

export const isNewPasswordRequired = (error) => {
   return !!error.challengeName && error.challengeName === 'NEW_PASSWORD_REQUIRED'
}


export const getCurrentUser = () => {
   return Auth.currentAuthenticatedUser()
      .then((user) => {
         return {
            user,
            authStatus: "authenticated"
         }
      })
      .catch((error) => {
         return {
            user: {},
            authStatus: "unauthenticated"
         }
      })
}


export const isAuthenticated = () => {
   return Auth.currentAuthenticatedUser()
      .then(() => true)
      .catch(() => Promise.resolve(false))
}

export const handleUpdatePassword = async (oldPassword, newPassword) => {
   const user = await Auth.currentAuthenticatedUser();
   return Auth.changePassword(user, oldPassword, newPassword)
      .then((result) => {
         return true
      })
      .catch((err) => {
         return err
      })
}
