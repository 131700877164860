import {useEffect, useRef, useState} from "react";
import {Hub} from "aws-amplify";
import AuthStatusService from "./useAuthStatusAuthenticator";
import UserService from "./useUserAuthenticator";
import {getCurrentUser} from "api/auth";
import SignInService from "./useSignInState";
import RolesService from "./useRoles";

const AuthProviders = ({children}) => {
   const [authStatus, setAuthStatus] = useState("processing")
   const [user, setUser] = useState({})
   const [roles, setRoles] = useState([])
   const [open, setOpen] = useState(false)
   const [tab, setTab] = useState(0)

   const toSignIn = () => {
      setOpen(true)
      setTab(0)
   }

   const toSignUp = () => {
      setOpen(true)
      setTab(1)
   }

   const authService = useRef({
      user,
      authStatus,
      toSignIn,
      toSignUp
   })

   const getRolesFromUser = (currentUser) => {
      return Object.keys(currentUser.user).length > 0
         ? currentUser.user.signInUserSession.idToken.payload['cognito:groups']
         : [];
   }

   useEffect(() => {
      getCurrentUser()
         .then((currentUser) => {
            authService.current = {
               user: currentUser.user,
               authStatus: currentUser.authStatus,
               toSignIn,
               toSignUp
            }
            setRoles(getRolesFromUser(currentUser))
            setUser(currentUser.user)
            setAuthStatus(currentUser.authStatus)
         })
   }, []);

   const handleAuthentication = async ({
      event,
      data
   }) => {
      switch (event) {
         case 'signIn': {
            authService.current = {
               user: data,
               authStatus: "authenticated"
            }
            setAuthStatus("authenticated")
            setUser(data)
            const currentUser = await getCurrentUser()
            setRoles(getRolesFromUser(currentUser))
            break;
         }
         case 'signUp':
            // console.log('user signed up');
            break;
         case 'signOut': {
            // console.log("We have signed out")
            authService.current = {
               user: {},
               authStatus: "unauthenticated"
            }
            setAuthStatus("unauthenticated")
            setUser({})
            setRoles([])
            break;
         }
         case 'signIn_failure':
            // console.log('user sign in failed');
            break;
         case 'configured':
            // console.log('the Auth module is configured');
            break;
      }
   }

   Hub.listen('auth', (data) => handleAuthentication(data.payload))
   Hub.listen('cognitoUpdate', async (data) => {
      getCurrentUser()
         .then((currentUser) => {
            setUser(currentUser)
         })
   })

   return (
      <UserService
         authService={authService}
         user={user}
      >
         <AuthStatusService
            authService={authService}
            authStatus={authStatus}
         >
            <RolesService roles={roles}>
               <SignInService
                  open={open}
                  setOpen={setOpen}
                  tab={tab}
                  setTab={setTab}
               >
                  {children}
               </SignInService>
            </RolesService>
         </AuthStatusService>
      </UserService>

   )
}
export default AuthProviders
