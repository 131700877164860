import {createContext, useContext} from "react";

export const useRoles = () => {
    const context = useContext(RolesContext)

    if (context === null) {
        throw new Error('Roles context needs to be set. Make sure your component is a child of the RolesService')
    }

    return context
}

const RolesContext = createContext(null)

const RolesService = ({children, roles}) => {
    const hasRole = (rolesToSearchFor = []) => {
        let hasRoles = true
        rolesToSearchFor.forEach((role) => {
            if(hasRoles === false) {
                return false
            }

            hasRoles = (roles.findIndex((userRole) => userRole === role) !== -1)
        })

        return hasRoles
    }

    const getRoles = () => {
        return roles
    }

    return <RolesContext.Provider value={{hasRole, getRoles}}>
        {children}
    </RolesContext.Provider>
}

export default RolesService
