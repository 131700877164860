import {lazy} from 'react'

const GlobalSearch = lazy(() => import('components/search/GlobalSearch'))

const Products = lazy(() => import('components/products/index/Products'))
const ProductSearch = lazy(() => import('components/products/search/ProductSearch'))
const ProductView = lazy(() => import('components/products/view/ProductView'))

const InformationalResources = lazy(() => import('components/resources/index/Resources'))
const ResourceSearch = lazy(() => import('components/resources/search/ResourcesSearch'))
const ResourceView = lazy(() => import('../components/resources/view/ResourceView'))

const Discussions = lazy(() => import('../components/discussions/index/Discussions'))
const DiscussionsSearch = lazy(() => import('components/discussions/search/DiscussionsSearch'))
const DiscussionView = lazy(() => import('../components/discussions/view/DiscussionView'))
const DiscussionTest = lazy(() => import('../components/discussions/index/__test__/DiscussionCardTest'))

const UserProfile = lazy(() => import('components/users/view/UserProfile'))
const DistributorView = lazy(() => import('components/distributors/view/DistributorView'))
const AuthorView = lazy(() => import('components/authors/view/AuthorView'))

const VerifyEmail = lazy(() => import('components/VerifyEmail'))
const ConsentForm = lazy(() => import('components/ConsentForm'))
const CommunityRules = lazy(() => import('components/CommunityRules'))

export const routes = {
   '/search/:searchTerm': {
      label: 'Search Results',
      component: GlobalSearch
   },
   '/products': {
      label: 'Products',
      component: Products,
      exact: true
   },
   '/products/search/:searchTerm': {
      label: 'Product Search Results',
      component: ProductSearch
   },
   '/products/searchByIso/:isoId/?:searchTerm?': {
      label: 'Product Search Results',
      component: ProductSearch
   },
    '/products/searchByIsoClass/:isoClass/?:searchTerm?': {
      label: 'Product Search Results',
      component: ProductSearch
    },
   '/products/:id': {
      label: 'View Product',
      component: ProductView
   },
   '/resources': {
      label: 'Informational Resources',
      component: InformationalResources,
      exact: true
   },
   '/resources/search/:searchTerm': {
      label: 'Informational Resource Search Results',
      component: ResourceSearch
   },
   '/resources/searchByIso/:isoId/?:searchTerm?': {
      label: 'Informational Resource Search Results',
      component: ResourceSearch
   },
   '/resources/:id': {
      label: 'View Informational Resource',
      component: ResourceView
   },
   '/discussions': {
      label: 'Discussions',
      component: Discussions,
      exact: true
   },
   '/discussions/test': {
     label: 'Discussions Test',
     component: DiscussionTest,
     exact: true
   },
   '/discussions/search/:searchTerm': {
      label: 'Discussion Search Results',
      component: DiscussionsSearch
   },
   '/discussions/searchByIso/:isoId/?:searchTerm?': {
      label: 'Discussion Search Results',
      component: DiscussionsSearch
   },
   '/discussions/:id': {
      label: 'View Discussion',
      component: DiscussionView
   },
   '/users/:id': {
      label: 'User Profile',
      component: UserProfile
   },
   '/distributors/:id': {
      label: 'Distributor Profile',
      component: DistributorView
   },
   '/authors/:id': {
      label: 'Author Profile',
      component: AuthorView
   },
   '/verify/:code': {
      label: 'Verify Email',
      component: VerifyEmail,
   },
    '/consent-form': {
       label: 'Consent Form',
        component: ConsentForm,
        exact: true
    },
    '/community-rules': {
        label: 'Community Rules',
        component: CommunityRules,
        exact: true
    }
}
