import {createContext, useContext, useEffect} from "react";

export const useAuthStatusAuthenticator = () => {
   const context = useContext(AuthStatusContext)

   if (context === null) {
      throw new Error('User context needs to be set. Make sure your component is a child of the AuthenticatorService')
   }

   return context
}

const AuthStatusContext = createContext(null)

const AuthStatusService = ({
   children,
   authService,
   authStatus
}) => {
   useEffect(() => {
   }, [authStatus]);

   return <AuthStatusContext.Provider value={authService.current}>
      {children}
   </AuthStatusContext.Provider>
}

export default AuthStatusService
