import {Flex, SearchField} from '@aws-amplify/ui-react'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom"
import {SearchType} from "components/layout/Header/SearchType";

const types = {
   all: {label: "All", path: "/search"},
   product: {label: "Products", path: "/products/search"},
   article: {label: "Resources", path: "/resources/search"},
   discussion: {label: "Forum", path: "/discussions/search"}
}

const getInitialType = (pathname) => {
   switch (true) {
      case pathname.includes("/products"):
         return "product"
      case pathname.includes("/resources"):
         return "article"
      case pathname.includes("/discussions"):
         return "discussion"
      default:
         return "all"
   }
}

export const Search = () => {
   const navigate = useNavigate()
   const {pathname} = useLocation()

   const [searchTerm, setSearchTerm] = useState('')
   const [type, setType] = useState(getInitialType(pathname))

   useEffect(() => {
      setType(getInitialType(pathname))
   }, [pathname])

   const onChange = (event) => {
      setSearchTerm(event.target.value)
   }

   const onClear = () => {
      setSearchTerm('')
   }

   const onSubmit = () => {
      navigate(`${types[type].path}/${encodeURIComponent(searchTerm)}`)
   }


   return (<Flex
         display="flex"
         gap="210px"
         direction="row"
         width="unset"
         height="100px"
         justifyContent="flex-start"
         alignItems="center"
         overflow="hidden"
         shrink="0"
         position="relative"
         padding="0px 0px 0px 0px"
         backgroundColor="rgba(255,255,255,1)"
      >
         <SearchField
            outerStartComponent={<SearchType types={types}
               type={type}
               setType={setType} />}
            label="search"
            placeholder={`Search ${types[type].label}...`}
            onChange={onChange}
            onClear={onClear}
            onSubmit={onSubmit}
            value={searchTerm}
         />
      </Flex>
   )
}
