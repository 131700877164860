import {createContext, useContext} from "react";

export const useSignInContext = () => {
    const context = useContext(SignInContext)

    if (context === null) {
        throw new Error('Sign in context needs to be set. Make sure your component is a child of the SignInService')
    }

    return context
}

const SignInContext = createContext(null)

const SignInService = ({children, open, setOpen, tab, setTab}) => {
    return <SignInContext.Provider value={{
        open,
        setOpen,
        tab,
        setTab
    }}>
        {children}
    </SignInContext.Provider>
}

export default SignInService
