import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {Alert} from "@mui/material";

export function displayMessage(onClose = null, type = "info", message) {
   return (
      <Alert
         icon={getIconForAlert(type)}
         severity={type}
         onClose={ () => {
            onClose
               ? onClose()
               : console.log("Message closed")
         }}
         sx={{
            ...getColorsForAlert(type),
            "borderRadius": "0.375rem",
            "gap": "0.75rem",
            "alignItems": "center",
         }}
      >
         {message}
      </Alert>
   )
}

export const getIconForAlert = (type) => {
   switch (type) {
      case "success": return <CheckCircleOutlineOutlinedIcon style={{"color": "#285627"}}/>
      case "warning": return <WarningAmberOutlinedIcon style={{"color": "#764700"}}/>
      case "error": return <ErrorOutlineOutlinedIcon style={{"color": "#881818"}}/>
      default: return <InfoOutlinedIcon style={{"color": "#003B68"}}/>
   }
}

export const getColorsForAlert = (type) => {
   switch (type) {
      case "success":
         return {
            "backgroundColor": "#BFE7BE",
            "color": "#285627"
         }
      case "warning":
         return {
            "backgroundColor": "#FFDCA6",
            "color": "#764700"
         }
      case "error":
         return {
            "backgroundColor": "#FFD6D6",
            "color": "#881818"
         }
      default:
         return {
            "backgroundColor": "#CFE9FD",
            "color": "#003B68"
         }
   }
}
