import {Button, Flex, Grid, Text, TextField} from "@aws-amplify/ui-react";
import React, {useState} from "react";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import Api from "../../../../api/graphql/Api";
import {
   getInputError,
   getPasswordMatchError,
   getPasswordValidationErrors,
   isValid
} from "../../../common/validationUtilities";
import {RegistrationStepper} from "./RegistrationStepper"

const UserApi = new Api('User')
export default function Register({
   values,
   setValues,
   setOpenRegistrationStepDialog,
   setOpen
}) {
   const [errors, setErrors] = useState({})
   const [showPassword, setShowPassword] = useState(false)
   const [registering, setRegistering] = useState(false)

   const handleChange = ({
      target
   }) => {
      const {
         name,
         value
      } = target

      setValues((currentValues) => {
         return {
            ...currentValues,
            [name]: value
         }
      })

      if (name.includes("password")) {
         const password = name === "password"
            ? value
            : values.password
         const confirmPassword = name === "confirm_password"
            ? value
            : values.confirm_password

         const newErrors = getErrors(name, getPasswordValidationErrors(value))
         newErrors.confirm_password = newErrors.confirm_password === "The passwords entered do not match"
            ? getPasswordMatchError(password, confirmPassword)
            : newErrors.confirm_password ?? getPasswordMatchError(password, confirmPassword)

         setErrors(newErrors)
      } else {
         setErrors(getErrors(name, getInputError(target)))
      }
   }

   const getErrors = (name, error) => {
      return {
         ...errors,
         [name]: error
      }
   }

   const handleRegister = (event) => {
      event.preventDefault()

      if (isValid(event.target.form)) {
         if (values.password === values.confirm_password) {
            UserApi
               .getByField("email", values.username)
               .then((users) => {
                  if (users.length > 0) {
                     setErrors({
                        username: "An account with this e-mail already exists. Please sign in."
                     })
                  } else {
                     setOpen(false)
                     setOpenRegistrationStepDialog(true)
                  }
               })
         }

         setRegistering(false)
      }
   }

   return (
      <>
         <RegistrationStepper activeStep={0} />

         <Flex
            direction="column"
            width="100%"
            gap="1rem"
            justifyContent="space-between"
            alignItems="center"
         >
            <Flex
               direction="column"
               width="100%"
               gap="1rem"
               padding="2rem"
               as="form"
            >
               <Grid
                  direction="row"
                  width="100%"
                  templateRows="1fr"
                  templateColumns="1fr 1fr"
                  columnGap="0.5rem"
               >
                  <TextField
                     name="given_name"
                     label={<Text>
                        First Name
                        <Text
                           as="span"
                           fontSize="0.8rem"
                           color="red"
                           aria-hidden={true}
                        >
                           {' '} (required)
                        </Text>
                     </Text>}
                     isRequired={true}
                     value={values.given_name}
                     placeholder="Enter your first name"
                     autoComplete="given-name"
                     onChange={handleChange}
                  />

                  <TextField
                     name="family_name"
                     label={<Text>
                        Last Name
                        <Text
                           as="span"
                           fontSize="0.8rem"
                           color="red"
                           aria-hidden={true}
                        >
                           {' '} (required)
                        </Text>
                     </Text>}
                     isRequired={true}
                     value={values.family_name}
                     placeholder="Enter your last name"
                     autoComplete="family-name"
                     onChange={handleChange}
                  />
               </Grid>

               <TextField
                  name="username"
                  label={<Text>
                     Email
                     <Text
                        as="span"
                        fontSize="0.8rem"
                        color="red"
                        aria-hidden={true}
                     >
                        {' '} (required)
                     </Text>
                  </Text>}
                  type="email"
                  isRequired={true}
                  value={values.username}
                  hasError={!!errors?.username}
                  errorMessage={errors?.username ?? ""}
                  placeholder="Enter your Email"
                  autoComplete="username"
                  onChange={handleChange}
               />

               <ul>
                  <li>Your password must contain an upper and lower case letter and a number</li>
                  <li>Your password must contain a space or special character from the following
                     list: <br />{"! @ # $ ^ & * _ = . , ' + > < - { } [ ] ( ) : ' \" ? \\ | / ` ~"}</li>
                  <li>Your password must have 12-50 characters</li>
               </ul>

               <TextField
                  type={showPassword
                     ? "text"
                     : "password"}
                  name="password"
                  isRequired={true}
                  value={values.password}
                  hasError={!!errors?.password}
                  errorMessage={errors?.password ?? ""}
                  label={<Text>
                     Password
                     <Text
                        as="span"
                        fontSize="0.8rem"
                        color="red"
                        aria-hidden={true}
                     >
                        {' '} (required)
                     </Text>
                  </Text>}
                  placeholder="Enter your Password"
                  autoComplete="new-password"
                  outerEndComponent={<Button onClick={() => setShowPassword(!showPassword)}>{showPassword
                     ? <MdVisibilityOff title="Hide password" />
                     : <MdVisibility title="Show password" />}</Button>}
                  onChange={handleChange}
               />

               <TextField
                  type={showPassword
                     ? "text"
                     : "password"}
                  name="confirm_password"
                  isRequired={true}
                  value={values.confirm_password}
                  hasError={!!errors?.confirm_password}
                  errorMessage={errors?.confirm_password ?? ""}
                  label={<Text>
                     Confirm password
                     <Text
                        as="span"
                        fontSize="0.8rem"
                        color="red"
                        aria-hidden={true}
                     >
                        {' '} (required)
                     </Text>
                  </Text>}
                  placeholder="Please confirm your Password"
                  autoComplete="new-password"
                  outerEndComponent={<Button onClick={() => setShowPassword(!showPassword)}>{showPassword
                     ? <MdVisibilityOff title="Hide password" />
                     : <MdVisibility title="Show password" />}</Button>}
                  onChange={handleChange}
               />

               <Button
                  variation="primary"
                  size="small"
                  type="submit"
                  disabled={!!Object.values(errors).filter((value) => !!value).length}
                  onClick={(event) => {
                     setRegistering(true)
                     setValues((values) => {
                        return {
                           ...values,
                           step: 1
                        }
                     })
                     handleRegister(event)
                  }}
               >{registering
                  ? "Registering ..."
                  : "Begin Registration"}</Button>
            </Flex>
         </Flex>
      </>
   )
}
