import {lazy} from 'react'

const ProfileView = lazy(() => import('components/profile/view/ProfileView'))
const ProfileEdit = lazy(() => import('components/profile/edit/ProfileEdit'))
const ProductImports = lazy(() => import('components/imports/ProductImports'))
const ImportAdd = lazy(() => import('components/imports/add/ImportAdd'))
const ImportView = lazy(() => import('components/imports/ImportView'))
const DiscussionAdd= lazy(() => import('components/discussions/add/DiscussionAdd'))
const Bookmark = lazy(() => import('components/bookmarks/Bookmarks'))
const ArticleDashboard = lazy(() => import('components/ArticleDashboard/ArticleDashboard'))

export const routes = {
   '/profile': {
      label: 'Profile',
      component: ProfileView,
      exact: true
   },
   '/profile/edit': {
      label: 'Edit Profile',
      component: ProfileEdit,
      exact: true
   },
   '/bookmarks': {
      label: 'Bookmarks',
      component: Bookmark,
      exact: true
   },
   '/articleDashboard': {
      label: 'Article Dashboard',
      component: ArticleDashboard,
      exact: true
   },
   '/imports': {
      label: 'Imports',
      component: ProductImports,
      exact: true
   },
   '/imports/add': {
      label: 'Add Import',
      component: ImportAdd,
      exact: true
   },
   '/imports/:id': {
      label: 'View Import',
      component: ImportView
   },
   '/discussions/add': {
      label: 'Add Discussion',
      component: DiscussionAdd,
      exact: true
   },
}
