import {Navigate, useLocation} from 'react-router-dom';
import {useAuthStatusAuthenticator} from "../components/common/authenticator/useAuthStatusAuthenticator";
import Loader from "../components/common/Loader/Loader";
import React, {useEffect, useState} from "react";

export function RequireAuth({children}) {
   const location = useLocation()
   const {authStatus} = useAuthStatusAuthenticator()
   const [processing, setProcessing] = useState(authStatus === "processing")

   useEffect(() => {
      if(authStatus !== "processing")
      {
         setProcessing(false)
      }
   }, [authStatus]);

   if (processing) {
      return <Loader />
   }

   if (authStatus !== 'authenticated') {
      return <Navigate to="/" state={{from: location}} replace />
   }

   return children
}
