import {Flex, Text} from "@aws-amplify/ui-react"
import {Box} from "@mui/material";
import * as React from "react"
import "./loader.css";

export default function Loader() {
   return <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      minHeight="20rem"
      gap={0}
   >
      <Flex
         direction="row"
         justifyContent="center"
         alignItems="center"
      >
         <Box className="circle">
            <span />
         </Box>
         <Box className="circle">
            <span />
         </Box>
         <Box className="circle">
            <span />
         </Box>
      </Flex>
      <Text
         variation="primary"
         lineHeight="1.25em"
         fontSize="32px"
      >
         Loading Page
      </Text>
   </Flex>
}
