import {Flex} from "@aws-amplify/ui-react";
import navItems from 'components/layout/Header/navItems.json'
import * as React from "react";
import MenuItems from "../MenuItems/MenuItems";

export default function HeaderNav() {
   return (<Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      shrink="0"
      alignSelf="stretch"
      position="relative"
      padding="0 3.5rem 0 3.5rem"
      display="flex"
   >
      <Flex
         gap="10px"
         direction="row"
         justifyContent="flex-start"
         alignItems="flex-start"
         alignSelf="stretch"
         position="relative"
         borderRadius="5px"
         padding="12px 291px 12px 24px"
         backgroundColor="var(--amplify-colors-brand-primary-90)"
      >
         <Flex
            gap="70px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
         >
            <nav>
               <MenuItems type="list" direction="row" menuItems={navItems} />
            </nav>
         </Flex>
      </Flex>
   </Flex>)
}
