import {Divider, Flex, Text} from "@aws-amplify/ui-react";
import CopyrightIcon from '@mui/icons-material/Copyright';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import MailIcon from '@mui/icons-material/Mail';
import React from "react";
import {Link} from "react-router-dom";
import {useUserAuthenticator} from "../../common/authenticator/useUserAuthenticator";

export default function SocialMediaContact() {
    const {authStatus, toSignIn, toSignUp} = useUserAuthenticator();
    const openModal = (e, action) => {
        e.preventDefault()
        if(action==="signIn")
        {
            toSignIn()
        } else if(action==="signUp")
        {
            toSignUp()
        }
    }

    return (
        <Flex
            direction="column"
            width="100%"
            justifyContent="center"
            backgroundColor="rgba(104,112,120,1)">

            <Divider size="large" color="rgba(255,255,255,1)"/>

            <Flex
                direction="row"
                width="100%"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Flex
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    gap="1px"
                >
                    <CopyrightIcon style={{color: "var(--amplify-colors-font-inverse)"}} aria-hidden="false"/>
                    <Text
                        fontFamily="Inter"
                        fontSize="0.75rem"
                        fontWeight="400"
                        color="var(--amplify-colors-font-inverse)"
                        textAlign="center"
                        display="block"
                        whiteSpace="pre-wrap"
                        margin="0 0.5rem 0 0.5rem">
                        Georgia Institute of Technology. 2023.
                    </Text>

                    <Link to='/consent-form' target="_blank" style={{"textDecoration": "none", "color": "var(--amplify-colors-font-inverse)",
                        "padding":"0 0.5rem 0 0.5rem", "borderLeft": "0.1rem solid", "borderRight": "0.1rem solid","fontSize":"0.75rem"}}>Consent Form</Link>

                    <Link to='/community-rules' target="_blank" style={{"textDecoration": "none", "color": "var(--amplify-colors-font-inverse)",
                        "padding":"0 0.5rem 0 0.5rem", "fontSize":"0.75rem"}}>Community rules</Link>

                    {authStatus === "authenticated" ?
                        <Link
                            to="/profile"
                            style={{"textDecoration": "none", "color": "var(--amplify-colors-font-inverse)",
                                "padding":"0 0.5rem 0 0.5rem", "borderLeft": "0.1rem solid", "fontSize":"0.75rem"}}
                        >
                            Profile
                        </Link>
                        :
                        <Flex
                            gap="0px">
                            <Link
                                to="#footer-modal"
                                onClick={(e) => openModal(e, "signIn")}
                                style={{"textDecoration": "none", "color": "var(--amplify-colors-font-inverse)",
                                    "padding":"0 0.5rem 0 0.5rem", "borderLeft": "0.1rem solid", "borderRight": "0.1rem solid","fontSize":"0.75rem"}}
                            >
                                Sign In
                            </Link>

                            <Link
                                to="#footer-modal"
                                onClick={(e) => openModal(e, "signUp")}
                                style={{"textDecoration": "none", "color": "var(--amplify-colors-font-inverse)",
                                    "padding":"0 0.5rem 0 0.5rem", "fontSize":"0.75rem"}}
                            >
                                Register
                            </Link>
                        </Flex>
                    }
                </Flex>

                <Flex
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    color="var(--amplify-colors-font-inverse)">

                    {/*<FacebookIcon aria-hidden="false"/>*/}
                    {/*<TwitterIcon aria-hidden="false"/>*/}
                    {/*<LinkedInIcon aria-hidden="false"/>*/}
                    {/*<MailIcon aria-hidden="false"/>*/}
                </Flex>

            </Flex>
        </Flex>
    )
}
