import {Button, Flex, Text, TextField} from "@aws-amplify/ui-react";
import {Hub} from "aws-amplify";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {confirmRegister} from "../../../../api/auth";
import {displayMessage} from "../../../common/Toast/toastUtilities";

export default function ConfirmRegistration({
   setOpen,
   values,
   setValues,
   setPhase
}) {
   const [codeResent, setCodeResent] = useState(true)
   const [error, setError] = useState("")
   const [verifying, setVerifying] = useState(false)
   const navigate = useNavigate()

   const handleChange = (event) => {
      setValues({
         ...values,
         [event.target.name]: event.target.value
      })
   }

   const handleSubmit = (event) => {
      event.preventDefault()
      setError("")
      setVerifying(true)

      for (let index = 0; index < event.target.form.length; ++index) {
         if (event.target.form[index].localName === "input") {
            if (!event.target.form[index].validity.valid) {
               setVerifying(false)
               return
            }
         }
      }

      if (values.username !== "") {
         confirmRegister(values)
            .then(() => {
               setValues({
                  username: '',
                  password: '',
                  code: ''
               })
            })
            .catch((error) => {
               console.log("Error", error)
               setError("Sorry, we have encountered an error")
            })
            .finally(() => {
               setVerifying(false)
            })
      } else {
         setError("An email hasn't been provided")
      }
   }

   const listenToAutoSignInEvent = () => {
      console.log("listenToAutoSignIn triggered")
      Hub.listen('auth', ({ payload }) => {
         const { event } = payload;
         if (event === 'autoSignIn') {
            const user = payload.data;
            console.log("Signed In User", user)
            navigate('/my-profile')
            // assign user
         } else if (event === 'autoSignIn_failure') {
            // redirect to sign in page
            console.log("Signing in failed on verification")
         } else {
            console.log("Error on verifying user", event)
         }
         setOpen(false)
         setValues({...values, code: ''})
      })
   }

   return (
      <Flex
         direction="column"
         width="100%"
         gap="1rem"
         justifyContent="space-between"
         alignItems="center"
      >

         <Flex
            direction="column"
            width="100%"
            gap="1rem"
            padding="2rem"
            paddingTop="1rem"
            as="form"
         >
            {error === ""
               ? null
               : displayMessage(() => {
                  setError("")
               }, "error", error)}


            {codeResent
               ? displayMessage(() => {
                  setCodeResent(false)
               }, "info", `A new verification code was sent to ${values.username}`)
               : null}

            <TextField
               name="code"
               label={<Text>
                  Verification Code
                  <Text
                     as="span"
                     fontSize="0.8rem"
                     color="red"
                     aria-hidden={true}
                  >
                     {' '} (required)
                  </Text>
               </Text>}
               placeholder="Enter your code"
               autoComplete="code"
               isRequired={true}
               value={values.code}
               onChange={handleChange}
            />

            <Button
               variation="primary"
               size="small"
               autoFocus
               type="submit"
               disabled={verifying}
               onClick={(event) => {
                  event.preventDefault()
                  setOpen(false)
                  setValues((values) => {
                     return {
                        ...values,
                        step: 0
                     }
                  })
                  setPhase(0)
                  handleSubmit(event)
               }}
            >
               {verifying
                  ? "Verifying ... "
                  : "Verify"}
            </Button>

            <Button
               size="small"
               type="submit"
               onClick={(event) => {
                  event.preventDefault()
                  setValues((values) => {
                     return {
                        ...values,
                        step: 2
                     }
                  })
                  setPhase(2)
               }}
            >
               Back to Community Rules
            </Button>
         </Flex>
      </Flex>)
}
