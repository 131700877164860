import {Hub} from "aws-amplify";

export const errorAlert = (alert) => {
    sendAlert(alert, 'error')
}

export const warningAlert = (alert) => {
    sendAlert(alert, 'warning')
}

export const infoAlert = (alert) => {
    sendAlert(alert, 'info')
}

export const successAlert = (alert) => {
    sendAlert(alert, 'success')
}

export const sendAlert = ({event, message, stack}, severity) => {
    return Hub.dispatch('alerts', {
        event: {event, stack},
        data: {severity},
        message: message
    })
}
