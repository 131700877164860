import * as React from "react"
import {Button, Flex} from "@aws-amplify/ui-react"
import RibbonNav from "components/layout/Header/RibbonNav";
import './skipToMainLink.css'

export default function Ribbon({mainContentRef}) {
    const skipToMainRef = React.useRef(null)

    return (<Flex
        gap="10px"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="12px 56px 12px 56px"
        backgroundColor="rgba(104,112,120,1)"
        display="flex"
    >
        <Flex
            direction="row"
            width="100%"
            margin="0"
            justifyContent="space-between"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
        >
            <Button
                ref={skipToMainRef}
                variation="link"
                to="#main"
                className="skip-to-main-content-link"
                onClick={(e) => {
                    // Removes focus from current element making it invisible
                    skipToMainRef.current.blur()
                    mainContentRef.current.scrollIntoView()
                    mainContentRef.current.focus()
                }}
                style={{"padding": "0.25rem"}}
            >
                Skip to main content
            </Button>
            <RibbonNav/>
        </Flex>
    </Flex>)
}
