import {Alert, Slide, Snackbar} from "@mui/material";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {Hub} from "aws-amplify";
import {getColorsForAlert, getIconForAlert} from "./toastUtilities";

export const Toast = ({
   toast = {},
   setToast
}) => {
   const {
      message,
      type
   } = toast

   const location = useLocation()
   useEffect(() => {
      setToast()
   }, [location]);

   Hub.listen('alerts', (data) => {
      setToast({
         message: data.payload.message,
         type: data.payload.data.severity
      })
   })

   const handleClose = () => {
      setToast()
   }

   if(!type)
   {
      return null
   }

   return <Snackbar
         open={true}
         onClose={handleClose}
         anchorOrigin={{
            "vertical": "bottom",
            "horizontal": "center"
         }}
      >
         <Slide direction="up" in={true}>
            <Alert
               icon={getIconForAlert(type)}
               sx={{
                  ...getColorsForAlert(type),
                  "borderRadius": "0.375rem",
                  "gap": "0.75rem",
                  "alignItems": "center",
                  "minWidth": "320px"
               }}
               severity={type}
               onClose={handleClose}
            >
               {message}
            </Alert>
         </Slide>
      </Snackbar>
}
