export const getInputError = ({
   nodeName,
   valid,
   validationMessage
}) => {
   if (nodeName === "INPUT" && !valid) {
      return validationMessage
   }

   return null
}


export const getPasswordValidationErrors = (password) => {
   const min = 12;
   const max = 50;
   const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[! @#$%^&*_=.,'+\-><{}:'"?/\]\[)(`~|\\])[a-zA-Z0-9! @#$%^&*_=.,'+\-><{}:'"?/\]\[)(`~|\\]{12,50}$/

   if (password.length < min || password.length > max) {
      return "The password must be between 12 and 50 characters long"
   }

   const findInvalidCharacter = /[^a-zA-Z0-9! @#$%^&*_=.,'+\-><{}:'"?/\]\[)(`~|\\]/
   if (findInvalidCharacter.test(password)) {
      const invalidCharacters = password.match(findInvalidCharacter)
      return  `You entered an invalid character ${invalidCharacters[0]} in your password`
      // a1!@#$^&*_=.,'+-><}{][)(:'"?\|/`~Z
   }

   if (!pattern.test(password)) {
      return  "The password must contain an upper and lower case letter, a number, and a special symbol: !@#$^&*_=.,'+><-{}[]():'\"?\\|/`~"
   }
}

export const getPasswordMatchError = (password, confirmPassword) => {
   if (password !== confirmPassword) {
      return "The passwords entered do not match"
   }

   return null
}

export const isValid = (form) => {
   for (let index = 0; index < form.length; ++index) {
      const {
         localName,
         validity: {valid}
      } = form[index]

      if (localName === "input" && !valid) {
         return false
      }
   }

   return true
}

export const getFormErrors = (form) => {
   return Array.from(form.elements).reduce((formErrors, element) => {
      if(element.nodeName === "INPUT" && !element.validity.valid) {
         formErrors[element?.name] = element?.validationMessage
      }

      return formErrors
   }, {})
}
