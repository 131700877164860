import Loader from "components/common/Loader/Loader";
import Basic from "components/layout/Basic";
import React, {lazy, Suspense, useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {routes as adminRoutes} from "routing/AdminRoutes";
import {routes as basicRoutes} from "routing/BasicRoutes";
import {routes as publicRoutes} from "routing/PublicRoutes"
import {RequireAuth} from "routing/RequireAuth";
import Api from "../api/graphql/Api";
import {useUserAuthenticator} from "../components/common/authenticator/useUserAuthenticator";
import {RequireRole} from "./RequireRole";

const UserApi = new Api('User')

const Home = lazy(() => import('components/Home'))
const NotFound = lazy(() => import('components/NotFound'))

const allRoutes = {...publicRoutes, ...basicRoutes, ...adminRoutes}

export default function AppRouter() {
   const location = useLocation()
   const {authStatus, user} = useUserAuthenticator()
   const navigate = useNavigate()

   useEffect(() => {
      setPageTitle(location)
   }, [location])

   useEffect(() => {
      if (authStatus === 'authenticated' && Object.keys(user).length !== 0) {
         UserApi
            .getById(user.username)
            .then((userRecord) => {
               if (!(userRecord.firstName && userRecord.lastName)) {
                  navigate("/profile/edit")
               }
            })
      }
   }, [user, authStatus, navigate])

   return (
      <Suspense fallback={<Basic><Loader /></Basic>}>
         <Routes>
            <Route
               path="*"
               element={<Basic>{React.createElement(NotFound)}</Basic>}
            />
            <Route
               path="/"
               element={<Basic />}
            >
               <Route
                  index
                  element={React.createElement(Home)}
               />
               {Object.keys(publicRoutes).map((route) => {
                  return <Route
                     key={route}
                     element={React.createElement(publicRoutes[route].component)}
                     path={route}
                     exact={!!publicRoutes[route].exact}
                  />
               })}

               {Object.keys(basicRoutes).map((route) => {
                  const element = <RequireAuth>
                     {React.createElement(basicRoutes[route].component)}
                  </RequireAuth>

                  return <Route
                     key={route}
                     element={element}
                     path={route}
                     exact={!!basicRoutes[route].exact}
                  />
               })}

               {Object.keys(adminRoutes).map((route) => {
                  const element = <RequireAuth>
                     <RequireRole rolesToFind={["Admin"]}>
                        {React.createElement(adminRoutes[route].component)}
                     </RequireRole>
                  </RequireAuth>

                  return <Route
                      key={route}
                      element={element}
                      path={route}
                      exact={!!adminRoutes[route].exact}
                  />
               })}
            </Route>

         </Routes>
      </Suspense>
   )
}

export const setPageTitle = (location) => {
   const matches = location.pathname.match(
      /(^\/[^/]*)(\/[^/]*)?(\/[a-z0-9-.]*$)?/)

   let path
   if (typeof matches[2] !== 'undefined' &&
      matches[2].replace(/(\/[a-zA-Z]*)/, '') !== '') {
      path = `${matches[1]}/:id`
   } else if (typeof matches[3] !== 'undefined' &&
      matches[3].replace(/(\/[a-zA-Z]*)/, '') !== '') {
      path = `${matches[1]}${matches[2]}/:id`
   } else {
      path = matches[0]
   }

   const pageTitle = !!allRoutes[path]
      ? ' - ' +  allRoutes[path]?.label ?? allRoutes[path].component.className
      : ''

   document.title = `AT Network${pageTitle}`
}
