/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "a53fbdc3c4324daaa198437bfb1a19dc",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a53fbdc3c4324daaa198437bfb1a19dc",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://lxiemeobwh.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://uj3fm6savjhgfaz7kxr25kpxq4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-nosajgs3pra7jkojpksajku6wy",
    "aws_cognito_identity_pool_id": "us-east-1:82640c9e-e592-4de8-a6c7-99e2626eaec7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Yz2uiNLtT",
    "aws_user_pools_web_client_id": "5l7sp9rngl8vm8pe2mto5596mg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "12",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "atnetwork-20210614075426-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2d9wbelaq1vwo.cloudfront.net",
    "aws_user_files_s3_bucket": "atnetwork-storage113616-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_login_mechanism": []
};


export default awsmobile;
