import * as React from "react"
import {UserNav} from "components/layout/Header/UserNav"
import { useAuthStatusAuthenticator } from "components/common/authenticator/useAuthStatusAuthenticator";
import AuthorizationModal from "components/layout/AuthorizationModal/AuthorizationModal"

export default function RibbonNav() {
   const {authStatus} = useAuthStatusAuthenticator()

   return (authStatus === "authenticated"
      ? <>
         <UserNav />
      </>
      : <>
          <AuthorizationModal/>
      </>)
}
