import {Flex, ThemeProvider} from '@aws-amplify/ui-react'
import Header from "components/layout/Header/Header";
import {Outlet} from "react-router-dom";
import basicTheme from "components/layout/themes/basicTheme";
import "./Basic.css"
import Footer from "./Footer/Footer";
import * as React from "react";
import {useState} from "react";
import {Toast} from "../common/Toast/Toast";

export default function Basic({children}) {
   const mainContentRef = React.useRef(null)
   const [toast, setToast] = useState()

   return (<ThemeProvider theme={basicTheme}>
      <Header mainContentRef={mainContentRef}/>
      <Flex direction="column"
            padding={'0 3.5rem 2.0rem'}
            ref={mainContentRef}
            as="main"
            id="main"
      >
         <Toast toast={toast} setToast={setToast}/>
         {children}
         <Outlet/>
      </Flex>
      <Footer/>
   </ThemeProvider>)
}
