import {CustomQuery} from "./types";

export const getArticleIndex: CustomQuery = {
    queryKey: "listArticles",
    type: "LIST",
    query: /* GraphQL */ `
       query ListArticles(
           $filter: ModelArticleFilterInput
           $limit: Int
           $nextToken: String
       ) {
           listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
               items {
                   id
                   title
                   published
                   wordCount
                   mainImage {
                       id
                       productId
                       articleId
                       articleComponentId
                       discussionId
                       reviewId
                       url
                       prefix
                       key
                       
                       altText
                       title
                       width
                       height
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   authors {
                       items {
                           id
                           authorID
                           articleID
                           author {
                               id
                               name
                               about
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                   }
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               nextToken
               startedAt
               __typename
           }
       }
    `
}

export const getArticleView: CustomQuery = {
    queryKey: "getArticle",
    type: "GET",
    query: /* GraphQL */ `
       query GetArticle($id: ID!) {
           getArticle(id: $id) {
               id
               title
               published
               wordCount
               mainImage {
                   id
                   productId
                   articleId
                   articleComponentId
                   discussionId
                   reviewId
                   url
                   prefix
                   key
                   
                   altText
                   title
                   width
                   height
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               iso2022s {
                   items {
                       id
                       articleID
                       iso2022ID
                       iso2022 {
                           id
                           title
                           description
                           plainLanguageTitle
                           plainLanguageDescription
                           class
                           subclass
                           division
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               authors {
                   items {
                       id
                       authorID
                       articleID
                       author {
                           id
                           name
                           about
                           phone
                           internationalPhone
                           fax
                           tty
                           contactName
                           contactTitle
                           contactPhone
                           contactEmail
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
               }
               votes {
                   items {
                       id
                       userId
                       commentId
                       reviewId
                       questionId
                       articleId
                       vote
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               comments {
                   items {
                       id
                       userId
                       articleId
                       discussionId
                       parentCommentId
                       comment
                       user {
                           id
                           distributorId
                           firstName
                           lastName
                           displayName
                           profileImage {
                               id
                               productId
                               articleId
                               articleComponentId
                               discussionId
                               reviewId
                               profileUserId
                               bannerUserId
                               url
                               prefix
                               key
                               
                               altText
                               title
                               width
                               height
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                       }
                       votes {
                           items {
                               id
                               userId
                               commentId
                               reviewId
                               questionId
                               articleId
                               vote
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           nextToken
                           startedAt
                           __typename
                       }
                       comments {
                           items {
                               id
                               userId
                               articleId
                               discussionId
                               parentCommentId
                               comment
    
                               user {
                                   id
                                   distributorId
                                   firstName
                                   lastName
                                   displayName
                                   profileImage {
                                       id
                                       productId
                                       articleId
                                       articleComponentId
                                       discussionId
                                       reviewId
                                       profileUserId
                                       bannerUserId
                                       url
                                       prefix
                                       key
                                       
                                       altText
                                       title
                                       width
                                       height
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                               }
                               votes {
                                   items {
                                       id
                                       userId
                                       commentId
                                       reviewId
                                       questionId
                                       articleId
                                       vote
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               comments {
                                   items {
                                       id
                                       userId
                                       articleId
                                       discussionId
                                       parentCommentId
                                       comment
                                       user {
                                           id
                                           distributorId
                                           firstName
                                           lastName
                                           displayName
                                           profileImage {
                                               id
                                               productId
                                               articleId
                                               articleComponentId
                                               discussionId
                                               reviewId
                                               profileUserId
                                               bannerUserId
                                               url
                                               prefix
                                               key
                                               
                                               altText
                                               title
                                               width
                                               height
                                               createdAt
                                               updatedAt
                                               _version
                                               _deleted
                                               _lastChangedAt
                                               __typename
                                           }
                                       }
                                       votes {
                                           items {
                                               id
                                               userId
                                               commentId
                                               reviewId
                                               questionId
                                               articleId
                                               vote
                                               createdAt
                                               updatedAt
                                               _version
                                               _deleted
                                               _lastChangedAt
                                               __typename
                                           }
                                           nextToken
                                           startedAt
                                           __typename
                                       }
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           nextToken
                           startedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               articleSections  {
                   items {
                       id
                       articleId
                       articleSectionId
                       title
                       sortWeight
                       articleComponents {
                           items {
                               id
                               articleSectionId
                               sortWeight
                               bodies {
                                   items {
                                       id
                                       articleComponentId
                                       body
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               images {
                                   items {
                                       id
                                       productId
                                       articleId
                                       articleComponentId
                                       discussionId
                                       reviewId
                                       url
                                       prefix
                                       key
                                       
                                       altText
                                       title
                                       width
                                       height
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               videos {
                                   items {
                                       id
                                       productId
                                       articleComponentId
                                       discussionId
                                       reviewId
                                       prefix
                                       videoKey
                                       transcriptKey
                                       title
                                       length
                                       language
                                       asl
                                       openCaptions
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           nextToken
                           startedAt
                           __typename
                       }
                       articleSections {
                           items {
                               id
                               articleId
                               articleSectionId
                               title
                               sortWeight
    
                               articleComponents {
                                   items {
                                       id
                                       articleSectionId
                                       sortWeight
                                       bodies {
                                           items {
                                               id
                                               articleComponentId
                                               body
                                               createdAt
                                               updatedAt
                                               _version
                                               _deleted
                                               _lastChangedAt
                                               __typename
                                           }
                                           nextToken
                                           startedAt
                                           __typename
                                       }
                                       images {
                                           items {
                                               id
                                               productId
                                               articleId
                                               articleComponentId
                                               discussionId
                                               reviewId
                                               url
                                               prefix
                                               key
                                               
                                               altText
                                               title
                                               width
                                               height
                                               createdAt
                                               updatedAt
                                               _version
                                               _deleted
                                               _lastChangedAt
                                               __typename
                                           }
                                           nextToken
                                           startedAt
                                           __typename
                                       }
                                       videos {
                                           items {
                                               id
                                               productId
                                               articleComponentId
                                               discussionId
                                               reviewId
                                               prefix
                                               videoKey
                                               transcriptKey
                                               title
                                               length
                                               language
                                               asl
                                               openCaptions
                                               createdAt
                                               updatedAt
                                               _version
                                               _deleted
                                               _lastChangedAt
                                               __typename
                                           }
                                           nextToken
                                           startedAt
                                           __typename
                                       }
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               articleSections {
                                   items {
                                       id
                                       articleId
                                       articleSectionId
                                       title
                                       sortWeight
    
                                       articleComponents {
                                           items {
                                               id
                                               articleSectionId
                                               sortWeight
                                               bodies {
                                                   items {
                                                       id
                                                       articleComponentId
                                                       body
                                                       createdAt
                                                       updatedAt
                                                       _version
                                                       _deleted
                                                       _lastChangedAt
                                                       __typename
                                                   }
                                                   nextToken
                                                   startedAt
                                                   __typename
                                               }
                                               images {
                                                   items {
                                                       id
                                                       productId
                                                       articleId
                                                       articleComponentId
                                                       discussionId
                                                       reviewId
                                                       url
                                                       prefix
                                                       key
                                                       
                                                       altText
                                                       title
                                                       width
                                                       height
                                                       createdAt
                                                       updatedAt
                                                       _version
                                                       _deleted
                                                       _lastChangedAt
                                                       __typename
                                                   }
                                                   nextToken
                                                   startedAt
                                                   __typename
                                               }
                                               videos {
                                                   items {
                                                       id
                                                       productId
                                                       articleComponentId
                                                       discussionId
                                                       reviewId
                                                       prefix
                                                       videoKey
                                                       transcriptKey
                                                       title
                                                       length
                                                       language
                                                       asl
                                                       openCaptions
                                                       createdAt
                                                       updatedAt
                                                       _version
                                                       _deleted
                                                       _lastChangedAt
                                                       __typename
                                                   }
                                                   nextToken
                                                   startedAt
                                                   __typename
                                               }
                                               createdAt
                                               updatedAt
                                               _version
                                               _deleted
                                               _lastChangedAt
                                               __typename
                                           }
                                           nextToken
                                           startedAt
                                           __typename
                                       }
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           nextToken
                           startedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }
    `
}

export const getArticleSearch: CustomQuery = {
    queryKey: "searchArticles",
    type: "LIST",
    query: /* GraphQL */ `
       query SearchArticles(
           $filter: SearchableArticleFilterInput
           $sort: [SearchableArticleSortInput]
           $limit: Int
           $nextToken: String
           $from: Int
           $aggregates: [SearchableArticleAggregationInput]
       ) {
           searchArticles(
               filter: $filter
               sort: $sort
               limit: $limit
               nextToken: $nextToken
               from: $from
               aggregates: $aggregates
           ) {
               items {
                   id
                   title
                   published
                   wordCount
                   mainImage {
                       id
                       productId
                       articleId
                       articleComponentId
                       discussionId
                       reviewId
                       url
                       prefix
                       key
                       
                       altText
                       title
                       width
                       height
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   iso2022s {
                       items {
                           id
                           articleID
                           iso2022ID
                           iso2022 {
                               id
                               title
                               description
                               plainLanguageTitle
                               plainLanguageDescription
                               class
                               subclass
                               division
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       nextToken
                       startedAt
                       __typename
                   }
                   authors {
                       items {
                           id
                           authorID
                           articleID
                           author {
                               id
                               name
                               about
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                   }
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               nextToken
               total
               aggregateItems {
                   name
                   result {
                       ... on SearchableAggregateScalarResult {
                           value
                       }
                       ... on SearchableAggregateBucketResult {
                           buckets {
                               key
                               doc_count
                               __typename
                           }
                       }
                   }
                   __typename
               }
               __typename
           }
       }
    `
}

export const getArticleSearchByIso: CustomQuery = {
    queryKey: "getIso",
    type: "GET",
    query: /* GraphQL */ `
       query GetIso($id: ID!) {
           getIso(id: $id) {
               id
               title
               description
               plainLanguageTitle
               plainLanguageDescription
               class
               subclass
               division
               articles {
                   items {
                       article {
                           id
                           title
                           published
                           wordCount
                           mainImage {
                               id
                               productId
                               articleId
                               articleComponentId
                               discussionId
                               reviewId
                               url
                               prefix
                               key
                               
                               altText
                               title
                               width
                               height
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           iso2022s {
                               items {
                                   id
                                   articleID
                                   iso2022ID
                                   iso2022 {
                                       id
                                       title
                                       description
                                       plainLanguageTitle
                                       plainLanguageDescription
                                       class
                                       subclass
                                       division
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                               nextToken
                               startedAt
                               __typename
                           }
                           authors {
                               items {
                                   id
                                   authorID
                                   articleID
                                   author {
                                       id
                                       name
                                       about
                                       phone
                                       internationalPhone
                                       fax
                                       tty
                                       contactName
                                       contactTitle
                                       contactPhone
                                       contactEmail
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                   }
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }`
}

export const getArticlesByUser: CustomQuery = {
    queryKey: "listArticles",
    type: "LIST",
    query: /* GraphQL */ `
       query ListArticles ($authorId: ID!){
           listArticles {
               items {
                   authors(filter: {authorID: {eq: $authorId}}) {
                       items {
                           author {
                               name
                               id
                           }
                           _version
                           _deleted
                       }
                   }
                   iso2022s {
                       items {
                           iso2022 {
                               id
                               class
                               _version
                               title
                               subclass
                               createdAt
                               updatedAt
                           }
                       }
                       nextToken
                       startedAt
                   }
                   title
                   updatedAt
                   id
                   createdAt
                   articleApproval {
                       id
                       dateApproved
                       _deleted
                       _version
                   }
               }
               nextToken
               startedAt
           }
       }`
}

