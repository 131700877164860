import {createTheme} from "@aws-amplify/ui-react";
import atNetworkTheme from "./atNetworkTheme";
import expanderTheme from "components/layout/themes/expanderTheme";

const basicTheme = createTheme({
    "name": "customer-theme",
    "tokens": {
        "components": {
            "button": {
                "backgroundColor": {"value": "{colors.background.primary}"},
                "color": {"value": "{components.button._disabled.color}"},
                "fontWeight": {"value": "{fontWeights.extrabold}"},
                "_hover": {
                    "backgroundColor": {"value": "{colors.brand.primary.90.value}"},
                    "color": {"value": "{colors.brand.primary.10.value}"},
                    "borderColor": {"value": "transparent"}
                },
                "_focus": {
                    "backgroundColor": {"value": "{colors.brand.primary.90.value}"},
                    "color": {"value": "{colors.brand.primary.10.value}"},
                    "borderColor": {"value": "transparent"}
                },
                "_active": {
                    "backgroundColor": {"value": "{colors.brand.primary.90.value}"},
                    "color": {"value": "{colors.brand.primary.10.value}"},
                    "borderColor": {"value": "transparent"}
                },
                "primary": {
                    "_hover": {
                        "backgroundColor": {"value": "{colors.brand.primary.40.value}"},
                        "color": {"value": "{colors.brand.primary.90.value}"},
                        "borderColor": {"value": "transparent"}
                    },
                    "_focus": {
                        "backgroundColor": {"value": "{colors.brand.primary.40.value}"},
                        "color": {"value": "{colors.brand.primary.100.value}"},
                        "borderColor": {"value": "transparent"}
                    },
                    "_active": {
                        "backgroundColor": {"value": "{colors.brand.primary.40.value}"},
                        "color": {"value": "{colors.brand.primary.100.value}"},
                        "borderColor": {"value": "transparent"}
                    }
                }
            }
        }
    }
}, atNetworkTheme)
export default createTheme(expanderTheme, basicTheme)

