import {Dialog} from "@mui/material";
import React, {useState} from "react";
import {DialogHeading} from "../../../common/DialogHeading";
import CommunityRulesDialog from "./CommunityRulesDialog";
import ConfirmRegistration from "./ConfirmRegistration";
import ConsentFormDialog from "./ConsentFormDialog";
import {RegistrationStepper} from "./RegistrationStepper";

const getDialogBody = (setOpen, phase, setPhase, values, setValues, setRegisterOpen) => {
   switch (phase) {
      case 1:
         return <ConsentFormDialog
            setOpen={setOpen}
            setPhase={setPhase}
            setValues={setValues}
            setRegisterOpen={setRegisterOpen}
         />
      case 2:
         return <CommunityRulesDialog
            setPhase={setPhase}
            values={values}
            setValues={setValues}
         />
      case 3:
         return <ConfirmRegistration
            setOpen={setOpen}
            setPhase={setPhase}
            values={values}
            setValues={setValues}
         />
      default:
         return <></>
   }
}

const getHeading = (phase) => {
   switch (phase) {
      case 1:
         return "AT Network: Online Community of Practice Participation - Consent Form"
      case 2:
         return "AT Network Community Rules"
      case 3:
         return "Register"
      default:
         return ""
   }
}

export default function RegistrationStepDialog({
   step,
   values,
   setValues,
   setRegisterOpen,
   open,
   setOpen
}) {
   const [phase, setPhase] = useState(step)

   return (
      <Dialog
         fullWidth
         maxWidth="md"
         open={open}
         onClose={() => setOpen(false)}
         aria-labelledby="alert-dialog-title"
      >

         <DialogHeading
            heading={getHeading(phase)}
            onClose={() => {
               setOpen(false)
               setValues({
                  username: '',
                  password: '',
                  code: ''
               })
            }}
         />

         <RegistrationStepper activeStep={phase} />

         {getDialogBody(setOpen, phase, setPhase, values, setValues, setRegisterOpen)}
      </Dialog>
   )
}
