import {lazy} from "react"

const ModeratorDashboard = lazy(() => import('components/moderator/ModeratorDashboard'))

const DistributorAdd = lazy(() => import("components/distributors/add/DistributorAdd"))
const DistributorEdit = lazy(() => import("components/distributors/edit/DistributorEdit"))

const ProductAdd = lazy(() => import("components/products/add/ProductAdd"))
const ProductEdit = lazy(() => import("components/products/edit/ProductEdit"))
const ManageDistributorProducts = lazy(() => import("components/distributors/manageProducts/ManageDistributorProducts"))

const ArticleAdd = lazy(() => import("../components/resources/edit/ArticleAdd"))

const ProductImport = lazy(() => import("../components/products/import/ProductImport"))


export const routes = {
   '/moderator/dashboard': {
      label: 'Moderator Dashboard',
      component: ModeratorDashboard,
      exact: true
   },
   "/distributors": {
      label: "Distributors",
      component: ModeratorDashboard,
      exact: true
   },
   "/distributors/add": {
      label: "Add Distributor",
      component: DistributorAdd
   },
   "/distributors/edit/:id": {
      label: "Edit Distributor",
      component: DistributorEdit
   },
   "/products/add/:distributorId?": {
      label: "Add Product",
      component: ProductAdd
   },
   "/products/edit/:id/:tabIndex?": {
      label: "Edit Product",
      component: ProductEdit
   },
   "/products/import": {
      label: "Import Products",
      component: ProductImport
   },
   "/distributors/manageProducts/:id": {
      label: "Manage Distributor Products",
      component: ManageDistributorProducts
   },
   "/products/noImages": {
      label: "Products Without Images",
      component: ModeratorDashboard,
      exact: true
   },
   "/products/noIsos": {
      label: "Products Without ISOs",
      component: ModeratorDashboard,
      exact: true
   },
   "/products/noDistributors": {
      label: "Products Without Distributors",
      component: ModeratorDashboard,
      exact: true
   },
   "/resources/add": {
      label: "Add Informational Resource",
      component: ArticleAdd,
      exact: true
   },
}
