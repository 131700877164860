/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      distributorId
      firstName
      lastName
      email
      profile
      displayName
      roles
      atMostInterestedIn
      location
      displayLocation
      enabled
      createdBy
      savedProducts {
        nextToken
        startedAt
        __typename
      }
      bookmarkedArticles {
        nextToken
        startedAt
        __typename
      }
      followedDiscussions {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      articleApprovals {
        nextToken
        startedAt
        __typename
      }
      discussions {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      profileImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bannerImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      disabledUserNotifications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userProfileImageId
      userBannerImageId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByDistributor = /* GraphQL */ `
  query UsersByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDistributor = /* GraphQL */ `
  query GetDistributor($id: ID!) {
    getDistributor(id: $id) {
      id
      name
      description
      abbreviation
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      products {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      links {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      mappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDistributors = /* GraphQL */ `
  query ListDistributors(
    $filter: ModelDistributorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDistributors = /* GraphQL */ `
  query SyncDistributors(
    $filter: ModelDistributorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDistributors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuthor = /* GraphQL */ `
  query GetAuthor($id: ID!) {
    getAuthor(id: $id) {
      id
      name
      about
      phone
      internationalPhone
      fax
      tty
      contactName
      contactTitle
      contactPhone
      contactEmail
      users {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAuthors = /* GraphQL */ `
  query ListAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuthors = /* GraphQL */ `
  query SyncAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuthors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      distributorId
      hasImage
      hasIso2022
      hasDistributor
      productKey
      name
      listPrice
      manufacturer
      gtin
      asin
      sku
      submittedMakerSeller
      status
      description
      weightGrams
      variantOptions
      keywords
      details
      technicalSpecifications
      videoUrl
      internalNotes
      latestImageAddedAt
      createdAt
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      userSaves {
        nextToken
        startedAt
        __typename
      }
      variants {
        nextToken
        startedAt
        __typename
      }
      prices {
        nextToken
        startedAt
        __typename
      }
      vendorLinks {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      questions {
        nextToken
        startedAt
        __typename
      }
      reviews {
        nextToken
        startedAt
        __typename
      }
      ratings {
        nextToken
        startedAt
        __typename
      }
      productImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productsByDistributor = /* GraphQL */ `
  query ProductsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productsWithImages = /* GraphQL */ `
  query ProductsWithImages(
    $hasImage: ID!
    $latestImageAddedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsWithImages(
      hasImage: $hasImage
      latestImageAddedAt: $latestImageAddedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productsWithIso2022s = /* GraphQL */ `
  query ProductsWithIso2022s(
    $hasIso2022: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsWithIso2022s(
      hasIso2022: $hasIso2022
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productsWithDistributors = /* GraphQL */ `
  query ProductsWithDistributors(
    $hasDistributor: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsWithDistributors(
      hasDistributor: $hasDistributor
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: [SearchableProductSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProductAggregationInput]
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getVariant = /* GraphQL */ `
  query GetVariant($id: ID!) {
    getVariant(id: $id) {
      id
      productId
      distributorId
      variantKey
      listPrice
      gtin
      asin
      sku
      status
      description
      weightGrams
      prices {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVariants = /* GraphQL */ `
  query ListVariants(
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVariants = /* GraphQL */ `
  query SyncVariants(
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVariants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const variantsByProduct = /* GraphQL */ `
  query VariantsByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantsByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const variantsByDistributor = /* GraphQL */ `
  query VariantsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      productId
      variantId
      distributorId
      price
      linkText
      url
      pricingNotes
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      variant {
        id
        productId
        distributorId
        variantKey
        listPrice
        gtin
        asin
        sku
        status
        description
        weightGrams
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        variantId
        distributorId
        price
        linkText
        url
        pricingNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrices = /* GraphQL */ `
  query SyncPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productId
        variantId
        distributorId
        price
        linkText
        url
        pricingNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricesByProduct = /* GraphQL */ `
  query PricesByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricesByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        variantId
        distributorId
        price
        linkText
        url
        pricingNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricesByVariant = /* GraphQL */ `
  query PricesByVariant(
    $variantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricesByVariant(
      variantId: $variantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        variantId
        distributorId
        price
        linkText
        url
        pricingNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricesByDistributor = /* GraphQL */ `
  query PricesByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricesByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        variantId
        distributorId
        price
        linkText
        url
        pricingNotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLink = /* GraphQL */ `
  query GetLink($id: ID!) {
    getLink(id: $id) {
      id
      productId
      distributorId
      linkText
      url
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      distributor {
        id
        name
        description
        abbreviation
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLinks = /* GraphQL */ `
  query ListLinks(
    $filter: ModelLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        distributorId
        linkText
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLinks = /* GraphQL */ `
  query SyncLinks(
    $filter: ModelLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productId
        distributorId
        linkText
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const linksByProduct = /* GraphQL */ `
  query LinksByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        distributorId
        linkText
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const linksByDistributor = /* GraphQL */ `
  query LinksByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        distributorId
        linkText
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      userId
      productId
      parentQuestionId
      body
      answers {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuestions = /* GraphQL */ `
  query SyncQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const questionsByUser = /* GraphQL */ `
  query QuestionsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const questionsByProduct = /* GraphQL */ `
  query QuestionsByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const questionsByParentQuestion = /* GraphQL */ `
  query QuestionsByParentQuestion(
    $parentQuestionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByParentQuestion(
      parentQuestionId: $parentQuestionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      ratingId
      userId
      productId
      parentReviewId
      body
      responses {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentReview {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reviewsByUser = /* GraphQL */ `
  query ReviewsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reviewsByProduct = /* GraphQL */ `
  query ReviewsByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reviewsByParentReview = /* GraphQL */ `
  query ReviewsByParentReview(
    $parentReviewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByParentReview(
      parentReviewId: $parentReviewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      userId
      productId
      reviewId
      rating
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRatings = /* GraphQL */ `
  query SyncRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRatings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ratingsByUser = /* GraphQL */ `
  query RatingsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ratingsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ratingsByProduct = /* GraphQL */ `
  query RatingsByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ratingsByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ratingsByReview = /* GraphQL */ `
  query RatingsByReview(
    $reviewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ratingsByReview(
      reviewId: $reviewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        productId
        reviewId
        rating
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      type
      mainImageId
      articleApprovalId
      title
      prefix
      key
      published
      wordCount
      authorUserIds
      mainImage {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      isos {
        nextToken
        startedAt
        __typename
      }
      iso2022s {
        nextToken
        startedAt
        __typename
      }
      authors {
        nextToken
        startedAt
        __typename
      }
      userBookmarks {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleApproval {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticles = /* GraphQL */ `
  query SyncArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchArticles = /* GraphQL */ `
  query SearchArticles(
    $filter: SearchableArticleFilterInput
    $sort: [SearchableArticleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableArticleAggregationInput]
  ) {
    searchArticles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getArticleApproval = /* GraphQL */ `
  query GetArticleApproval($id: ID!) {
    getArticleApproval(id: $id) {
      id
      articleId
      approvedByUserId
      notes
      dateApproved
      approvedBy {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticleApprovals = /* GraphQL */ `
  query ListArticleApprovals(
    $filter: ModelArticleApprovalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleApprovals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticleApprovals = /* GraphQL */ `
  query SyncArticleApprovals(
    $filter: ModelArticleApprovalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticleApprovals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const articleApprovalByArticle = /* GraphQL */ `
  query ArticleApprovalByArticle(
    $articleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticleApprovalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleApprovalByArticle(
      articleId: $articleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const articleApprovalsByUser = /* GraphQL */ `
  query ArticleApprovalsByUser(
    $approvedByUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticleApprovalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleApprovalsByUser(
      approvedByUserId: $approvedByUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        approvedByUserId
        notes
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArticleSection = /* GraphQL */ `
  query GetArticleSection($id: ID!) {
    getArticleSection(id: $id) {
      id
      articleId
      articleSectionId
      title
      sortWeight
      articleSections {
        nextToken
        startedAt
        __typename
      }
      articleComponents {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentArticleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticleSections = /* GraphQL */ `
  query ListArticleSections(
    $filter: ModelArticleSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticleSections = /* GraphQL */ `
  query SyncArticleSections(
    $filter: ModelArticleSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticleSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const articleSectionsByArticle = /* GraphQL */ `
  query ArticleSectionsByArticle(
    $articleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticleSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleSectionsByArticle(
      articleId: $articleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const articleSectionsByParentArticleSection = /* GraphQL */ `
  query ArticleSectionsByParentArticleSection(
    $articleSectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticleSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleSectionsByParentArticleSection(
      articleSectionId: $articleSectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArticleComponent = /* GraphQL */ `
  query GetArticleComponent($id: ID!) {
    getArticleComponent(id: $id) {
      id
      articleSectionId
      sortWeight
      bodies {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      articleSection {
        id
        articleId
        articleSectionId
        title
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticleComponents = /* GraphQL */ `
  query ListArticleComponents(
    $filter: ModelArticleComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticleComponents = /* GraphQL */ `
  query SyncArticleComponents(
    $filter: ModelArticleComponentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticleComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const articleComponentsByArticleSection = /* GraphQL */ `
  query ArticleComponentsByArticleSection(
    $articleSectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticleComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleComponentsByArticleSection(
      articleSectionId: $articleSectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBody = /* GraphQL */ `
  query GetBody($id: ID!) {
    getBody(id: $id) {
      id
      articleComponentId
      body
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBodies = /* GraphQL */ `
  query ListBodies(
    $filter: ModelBodyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBodies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleComponentId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBodies = /* GraphQL */ `
  query SyncBodies(
    $filter: ModelBodyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBodies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articleComponentId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const bodiesByArticleComponent = /* GraphQL */ `
  query BodiesByArticleComponent(
    $articleComponentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBodyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bodiesByArticleComponent(
      articleComponentId: $articleComponentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleComponentId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchBodies = /* GraphQL */ `
  query SearchBodies(
    $filter: SearchableBodyFilterInput
    $sort: [SearchableBodySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBodyAggregationInput]
  ) {
    searchBodies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        articleComponentId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDiscussion = /* GraphQL */ `
  query GetDiscussion($id: ID!) {
    getDiscussion(id: $id) {
      id
      userId
      topicId
      title
      body
      popularity
      userFollows {
        nextToken
        startedAt
        __typename
      }
      images {
        nextToken
        startedAt
        __typename
      }
      videos {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      topic {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDiscussions = /* GraphQL */ `
  query ListDiscussions(
    $filter: ModelDiscussionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscussions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDiscussions = /* GraphQL */ `
  query SyncDiscussions(
    $filter: ModelDiscussionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscussions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const discussionsByUser = /* GraphQL */ `
  query DiscussionsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscussionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discussionsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const discussionsByTopic = /* GraphQL */ `
  query DiscussionsByTopic(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscussionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discussionsByTopic(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchDiscussions = /* GraphQL */ `
  query SearchDiscussions(
    $filter: SearchableDiscussionFilterInput
    $sort: [SearchableDiscussionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDiscussionAggregationInput]
  ) {
    searchDiscussions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      userId
      articleId
      discussionId
      parentCommentId
      comment
      comments {
        nextToken
        startedAt
        __typename
      }
      votes {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      parentComment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      userSubscriptions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentsByUser = /* GraphQL */ `
  query CommentsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentsByArticle = /* GraphQL */ `
  query CommentsByArticle(
    $articleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByArticle(
      articleId: $articleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentsByDiscussion = /* GraphQL */ `
  query CommentsByDiscussion(
    $discussionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByDiscussion(
      discussionId: $discussionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentsByParentComment = /* GraphQL */ `
  query CommentsByParentComment(
    $parentCommentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByParentComment(
      parentCommentId: $parentCommentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchComments = /* GraphQL */ `
  query SearchComments(
    $filter: SearchableCommentFilterInput
    $sort: [SearchableCommentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCommentAggregationInput]
  ) {
    searchComments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getVote = /* GraphQL */ `
  query GetVote($id: ID!) {
    getVote(id: $id) {
      id
      userId
      commentId
      reviewId
      questionId
      articleId
      discussionId
      vote
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      question {
        id
        userId
        productId
        parentQuestionId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listVotes = /* GraphQL */ `
  query ListVotes(
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVotes = /* GraphQL */ `
  query SyncVotes(
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votesByUser = /* GraphQL */ `
  query VotesByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votesByComment = /* GraphQL */ `
  query VotesByComment(
    $commentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByComment(
      commentId: $commentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votesByReview = /* GraphQL */ `
  query VotesByReview(
    $reviewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByReview(
      reviewId: $reviewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votesByQuestion = /* GraphQL */ `
  query VotesByQuestion(
    $questionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByQuestion(
      questionId: $questionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votesByArticle = /* GraphQL */ `
  query VotesByArticle(
    $articleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByArticle(
      articleId: $articleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votesByDiscussion = /* GraphQL */ `
  query VotesByDiscussion(
    $discussionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votesByDiscussion(
      discussionId: $discussionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        commentId
        reviewId
        questionId
        articleId
        discussionId
        vote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      productId
      articleId
      articleComponentId
      discussionId
      reviewId
      distributorId
      profileUserId
      bannerUserId
      url
      prefix
      key
      altText
      title
      width
      height
      position
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      bannerUser {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImages = /* GraphQL */ `
  query SyncImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByProduct = /* GraphQL */ `
  query ImagesByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByArticle = /* GraphQL */ `
  query ImagesByArticle(
    $articleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByArticle(
      articleId: $articleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByArticleComponent = /* GraphQL */ `
  query ImagesByArticleComponent(
    $articleComponentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByArticleComponent(
      articleComponentId: $articleComponentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByDiscussion = /* GraphQL */ `
  query ImagesByDiscussion(
    $discussionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByDiscussion(
      discussionId: $discussionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByReview = /* GraphQL */ `
  query ImagesByReview(
    $reviewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByReview(
      reviewId: $reviewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByDistributor = /* GraphQL */ `
  query ImagesByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleId
        articleComponentId
        discussionId
        reviewId
        distributorId
        profileUserId
        bannerUserId
        url
        prefix
        key
        altText
        title
        width
        height
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      productId
      articleComponentId
      discussionId
      reviewId
      distributorId
      prefix
      videoKey
      transcriptKey
      title
      length
      language
      asl
      openCaptions
      videoCaptions {
        nextToken
        startedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      articleComponent {
        id
        articleSectionId
        sortWeight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      review {
        id
        ratingId
        userId
        productId
        parentReviewId
        body
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVideos = /* GraphQL */ `
  query SyncVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const videosByProduct = /* GraphQL */ `
  query VideosByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videosByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const videosByArticleComponent = /* GraphQL */ `
  query VideosByArticleComponent(
    $articleComponentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videosByArticleComponent(
      articleComponentId: $articleComponentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const videosByDicussion = /* GraphQL */ `
  query VideosByDicussion(
    $discussionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videosByDicussion(
      discussionId: $discussionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const videosByReview = /* GraphQL */ `
  query VideosByReview(
    $reviewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videosByReview(
      reviewId: $reviewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const videosByDistributor = /* GraphQL */ `
  query VideosByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videosByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVideoCaption = /* GraphQL */ `
  query GetVideoCaption($id: ID!) {
    getVideoCaption(id: $id) {
      id
      videoId
      distributorId
      captionKey
      language
      video {
        id
        productId
        articleComponentId
        discussionId
        reviewId
        distributorId
        prefix
        videoKey
        transcriptKey
        title
        length
        language
        asl
        openCaptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVideoCaptions = /* GraphQL */ `
  query ListVideoCaptions(
    $filter: ModelVideoCaptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoCaptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoId
        distributorId
        captionKey
        language
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVideoCaptions = /* GraphQL */ `
  query SyncVideoCaptions(
    $filter: ModelVideoCaptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoCaptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoId
        distributorId
        captionKey
        language
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const videoCaptionsByVideo = /* GraphQL */ `
  query VideoCaptionsByVideo(
    $videoId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoCaptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoCaptionsByVideo(
      videoId: $videoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoId
        distributorId
        captionKey
        language
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const videoCaptionsByDistributor = /* GraphQL */ `
  query VideoCaptionsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoCaptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoCaptionsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoId
        distributorId
        captionKey
        language
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIso = /* GraphQL */ `
  query GetIso($id: ID!) {
    getIso(id: $id) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIsos = /* GraphQL */ `
  query ListIsos(
    $filter: ModelIsoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIsos = /* GraphQL */ `
  query SyncIsos(
    $filter: ModelIsoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIsos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIso2022 = /* GraphQL */ `
  query GetIso2022($id: ID!) {
    getIso2022(id: $id) {
      id
      title
      description
      plainLanguageTitle
      plainLanguageDescription
      class
      subclass
      division
      products {
        nextToken
        startedAt
        __typename
      }
      articles {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIso2022s = /* GraphQL */ `
  query ListIso2022s(
    $filter: ModelIso2022FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIso2022s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIso2022s = /* GraphQL */ `
  query SyncIso2022s(
    $filter: ModelIso2022FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIso2022s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const isosByClassAndSubclass = /* GraphQL */ `
  query IsosByClassAndSubclass(
    $class: Int!
    $subclass: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIso2022FilterInput
    $limit: Int
    $nextToken: String
  ) {
    isosByClassAndSubclass(
      class: $class
      subclass: $subclass
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      title
      description
      discussions {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTopics = /* GraphQL */ `
  query SyncTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTopics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserSubscription = /* GraphQL */ `
  query GetUserSubscription($id: ID!) {
    getUserSubscription(id: $id) {
      id
      articleId
      productId
      commentId
      discussionId
      userId
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      comment {
        id
        userId
        articleId
        discussionId
        parentCommentId
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserSubscriptions = /* GraphQL */ `
  query ListUserSubscriptions(
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserSubscriptions = /* GraphQL */ `
  query SyncUserSubscriptions(
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userSubscriptionsByArticle = /* GraphQL */ `
  query UserSubscriptionsByArticle(
    $articleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSubscriptionsByArticle(
      articleId: $articleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userSubscriptionsByProduct = /* GraphQL */ `
  query UserSubscriptionsByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSubscriptionsByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userSubscriptionsByComment = /* GraphQL */ `
  query UserSubscriptionsByComment(
    $commentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSubscriptionsByComment(
      commentId: $commentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userSubscriptionsByDiscussion = /* GraphQL */ `
  query UserSubscriptionsByDiscussion(
    $discussionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSubscriptionsByDiscussion(
      discussionId: $discussionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userSubscriptionsByUser = /* GraphQL */ `
  query UserSubscriptionsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSubscriptionsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      message
      url
      subscribedActivities {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActivities = /* GraphQL */ `
  query SyncActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSubscribedActivity = /* GraphQL */ `
  query GetSubscribedActivity($id: ID!) {
    getSubscribedActivity(id: $id) {
      id
      userId
      userSubscriptionId
      activityId
      read
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      activity {
        id
        message
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userSubscription {
        id
        articleId
        productId
        commentId
        discussionId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSubscribedActivities = /* GraphQL */ `
  query ListSubscribedActivities(
    $filter: ModelSubscribedActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribedActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSubscriptionId
        activityId
        read
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSubscribedActivities = /* GraphQL */ `
  query SyncSubscribedActivities(
    $filter: ModelSubscribedActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscribedActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        userSubscriptionId
        activityId
        read
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const subscribedActivitiesByUser = /* GraphQL */ `
  query SubscribedActivitiesByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscribedActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscribedActivitiesByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSubscriptionId
        activityId
        read
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const subscribedActivitiesByUserSubscription = /* GraphQL */ `
  query SubscribedActivitiesByUserSubscription(
    $userSubscriptionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscribedActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscribedActivitiesByUserSubscription(
      userSubscriptionId: $userSubscriptionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSubscriptionId
        activityId
        read
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const subscribedActivitiesByActivity = /* GraphQL */ `
  query SubscribedActivitiesByActivity(
    $activityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscribedActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscribedActivitiesByActivity(
      activityId: $activityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userSubscriptionId
        activityId
        read
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDisabledUserNotifications = /* GraphQL */ `
  query GetDisabledUserNotifications($id: ID!) {
    getDisabledUserNotifications(id: $id) {
      id
      userId
      resource
      activityType
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDisabledUserNotifications = /* GraphQL */ `
  query ListDisabledUserNotifications(
    $filter: ModelDisabledUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisabledUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        resource
        activityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDisabledUserNotifications = /* GraphQL */ `
  query SyncDisabledUserNotifications(
    $filter: ModelDisabledUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDisabledUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        resource
        activityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const disabledUserNotificationsByUser = /* GraphQL */ `
  query DisabledUserNotificationsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDisabledUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    disabledUserNotificationsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        resource
        activityType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImport = /* GraphQL */ `
  query GetImport($id: ID!) {
    getImport(id: $id) {
      id
      distributorId
      importId
      prefix
      key
      status
      startTime
      endTime
      productImports {
        nextToken
        startedAt
        __typename
      }
      variantImports {
        nextToken
        startedAt
        __typename
      }
      imports {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImports = /* GraphQL */ `
  query ListImports(
    $filter: ModelImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        distributorId
        importId
        prefix
        key
        status
        startTime
        endTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImports = /* GraphQL */ `
  query SyncImports(
    $filter: ModelImportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        distributorId
        importId
        prefix
        key
        status
        startTime
        endTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const importsByDistributor = /* GraphQL */ `
  query ImportsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    importsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        importId
        prefix
        key
        status
        startTime
        endTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const importsByParentImport = /* GraphQL */ `
  query ImportsByParentImport(
    $importId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    importsByParentImport(
      importId: $importId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        importId
        prefix
        key
        status
        startTime
        endTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProductImport = /* GraphQL */ `
  query GetProductImport($id: ID!) {
    getProductImport(id: $id) {
      id
      productId
      importId
      distributorId
      productKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProductImports = /* GraphQL */ `
  query ListProductImports(
    $filter: ModelProductImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        importId
        distributorId
        productKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProductImports = /* GraphQL */ `
  query SyncProductImports(
    $filter: ModelProductImportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductImports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productId
        importId
        distributorId
        productKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productImportsByProduct = /* GraphQL */ `
  query ProductImportsByProduct(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productImportsByProduct(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        importId
        distributorId
        productKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productImportsByImport = /* GraphQL */ `
  query ProductImportsByImport(
    $importId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productImportsByImport(
      importId: $importId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        importId
        distributorId
        productKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productImportsByDistributor = /* GraphQL */ `
  query ProductImportsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productImportsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        importId
        distributorId
        productKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVariantImport = /* GraphQL */ `
  query GetVariantImport($id: ID!) {
    getVariantImport(id: $id) {
      id
      variantId
      importId
      distributorId
      productKey
      variantKey
      new
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVariantImports = /* GraphQL */ `
  query ListVariantImports(
    $filter: ModelVariantImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariantImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        variantId
        importId
        distributorId
        productKey
        variantKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVariantImports = /* GraphQL */ `
  query SyncVariantImports(
    $filter: ModelVariantImportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVariantImports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        variantId
        importId
        distributorId
        productKey
        variantKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const variantImportsByVariant = /* GraphQL */ `
  query VariantImportsByVariant(
    $variantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVariantImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantImportsByVariant(
      variantId: $variantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        variantId
        importId
        distributorId
        productKey
        variantKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const variantImportsByImport = /* GraphQL */ `
  query VariantImportsByImport(
    $importId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVariantImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantImportsByImport(
      importId: $importId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        variantId
        importId
        distributorId
        productKey
        variantKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const variantImportsByDistributor = /* GraphQL */ `
  query VariantImportsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVariantImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantImportsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        variantId
        importId
        distributorId
        productKey
        variantKey
        new
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMapping = /* GraphQL */ `
  query GetMapping($id: ID!) {
    getMapping(id: $id) {
      id
      distributorId
      name
      productKeyFields
      variantKeyFields
      variantOptionFields
      variantValueFields
      categoryFields
      fieldMappings {
        nextToken
        startedAt
        __typename
      }
      isoMappings {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMappings = /* GraphQL */ `
  query ListMappings(
    $filter: ModelMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        distributorId
        name
        productKeyFields
        variantKeyFields
        variantOptionFields
        variantValueFields
        categoryFields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMappings = /* GraphQL */ `
  query SyncMappings(
    $filter: ModelMappingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        distributorId
        name
        productKeyFields
        variantKeyFields
        variantOptionFields
        variantValueFields
        categoryFields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mappingsByDistributor = /* GraphQL */ `
  query MappingsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mappingsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributorId
        name
        productKeyFields
        variantKeyFields
        variantOptionFields
        variantValueFields
        categoryFields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIsoMapping = /* GraphQL */ `
  query GetIsoMapping($id: ID!) {
    getIsoMapping(id: $id) {
      id
      mappingId
      isoId
      iso2022Id
      distributorId
      distributorCategory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIsoMappings = /* GraphQL */ `
  query ListIsoMappings(
    $filter: ModelIsoMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIsoMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mappingId
        isoId
        iso2022Id
        distributorId
        distributorCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIsoMappings = /* GraphQL */ `
  query SyncIsoMappings(
    $filter: ModelIsoMappingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIsoMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mappingId
        isoId
        iso2022Id
        distributorId
        distributorCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const isoMappingsByMapping = /* GraphQL */ `
  query IsoMappingsByMapping(
    $mappingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIsoMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isoMappingsByMapping(
      mappingId: $mappingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mappingId
        isoId
        iso2022Id
        distributorId
        distributorCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const isoMappingsByIso = /* GraphQL */ `
  query IsoMappingsByIso(
    $isoId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIsoMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isoMappingsByIso(
      isoId: $isoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mappingId
        isoId
        iso2022Id
        distributorId
        distributorCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const isoMappingsByIso2022 = /* GraphQL */ `
  query IsoMappingsByIso2022(
    $iso2022Id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIsoMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isoMappingsByIso2022(
      iso2022Id: $iso2022Id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mappingId
        isoId
        iso2022Id
        distributorId
        distributorCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const isoMappingsByDistributor = /* GraphQL */ `
  query IsoMappingsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIsoMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isoMappingsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mappingId
        isoId
        iso2022Id
        distributorId
        distributorCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFieldMapping = /* GraphQL */ `
  query GetFieldMapping($id: ID!) {
    getFieldMapping(id: $id) {
      id
      mappingId
      distributorId
      internalField
      distributorField
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFieldMappings = /* GraphQL */ `
  query ListFieldMappings(
    $filter: ModelFieldMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFieldMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mappingId
        distributorId
        internalField
        distributorField
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFieldMappings = /* GraphQL */ `
  query SyncFieldMappings(
    $filter: ModelFieldMappingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFieldMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mappingId
        distributorId
        internalField
        distributorField
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const fieldMappingsByMapping = /* GraphQL */ `
  query FieldMappingsByMapping(
    $mappingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFieldMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fieldMappingsByMapping(
      mappingId: $mappingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mappingId
        distributorId
        internalField
        distributorField
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const fieldMappingsByDistributor = /* GraphQL */ `
  query FieldMappingsByDistributor(
    $distributorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFieldMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fieldMappingsByDistributor(
      distributorId: $distributorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mappingId
        distributorId
        internalField
        distributorField
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTempProductIso = /* GraphQL */ `
  query GetTempProductIso($id: ID!) {
    getTempProductIso(id: $id) {
      id
      productID
      isoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTempProductIsos = /* GraphQL */ `
  query ListTempProductIsos(
    $filter: ModelTempProductIsoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTempProductIsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productID
        isoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTempProductIsos = /* GraphQL */ `
  query SyncTempProductIsos(
    $filter: ModelTempProductIsoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTempProductIsos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productID
        isoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSavedProduct = /* GraphQL */ `
  query GetSavedProduct($id: ID!) {
    getSavedProduct(id: $id) {
      id
      userID
      productID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const listSavedProducts = /* GraphQL */ `
  query ListSavedProducts(
    $filter: ModelSavedProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSavedProducts = /* GraphQL */ `
  query SyncSavedProducts(
    $filter: ModelSavedProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSavedProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBookmarkedArticle = /* GraphQL */ `
  query GetBookmarkedArticle($id: ID!) {
    getBookmarkedArticle(id: $id) {
      id
      userID
      articleID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const listBookmarkedArticles = /* GraphQL */ `
  query ListBookmarkedArticles(
    $filter: ModelBookmarkedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookmarkedArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        articleID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookmarkedArticles = /* GraphQL */ `
  query SyncBookmarkedArticles(
    $filter: ModelBookmarkedArticleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookmarkedArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        articleID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFollowedDiscussion = /* GraphQL */ `
  query GetFollowedDiscussion($id: ID!) {
    getFollowedDiscussion(id: $id) {
      id
      userID
      discussionID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      discussion {
        id
        userId
        topicId
        title
        body
        popularity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const listFollowedDiscussions = /* GraphQL */ `
  query ListFollowedDiscussions(
    $filter: ModelFollowedDiscussionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowedDiscussions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        discussionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFollowedDiscussions = /* GraphQL */ `
  query SyncFollowedDiscussions(
    $filter: ModelFollowedDiscussionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollowedDiscussions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        discussionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuthorUser = /* GraphQL */ `
  query GetAuthorUser($id: ID!) {
    getAuthorUser(id: $id) {
      id
      userID
      authorID
      user {
        id
        distributorId
        firstName
        lastName
        email
        profile
        displayName
        roles
        atMostInterestedIn
        location
        displayLocation
        enabled
        createdBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userProfileImageId
        userBannerImageId
        __typename
      }
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdBy
      __typename
    }
  }
`;
export const listAuthorUsers = /* GraphQL */ `
  query ListAuthorUsers(
    $filter: ModelAuthorUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthorUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        authorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuthorUsers = /* GraphQL */ `
  query SyncAuthorUsers(
    $filter: ModelAuthorUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuthorUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        authorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdBy
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArticleAuthor = /* GraphQL */ `
  query GetArticleAuthor($id: ID!) {
    getArticleAuthor(id: $id) {
      id
      authorID
      articleID
      author {
        id
        name
        about
        phone
        internationalPhone
        fax
        tty
        contactName
        contactTitle
        contactPhone
        contactEmail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticleAuthors = /* GraphQL */ `
  query ListArticleAuthors(
    $filter: ModelArticleAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorID
        articleID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticleAuthors = /* GraphQL */ `
  query SyncArticleAuthors(
    $filter: ModelArticleAuthorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticleAuthors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        authorID
        articleID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProductIso = /* GraphQL */ `
  query GetProductIso($id: ID!) {
    getProductIso(id: $id) {
      id
      productID
      isoID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProductIsos = /* GraphQL */ `
  query ListProductIsos(
    $filter: ModelProductIsoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductIsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productID
        isoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProductIsos = /* GraphQL */ `
  query SyncProductIsos(
    $filter: ModelProductIsoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductIsos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productID
        isoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProductIso2022 = /* GraphQL */ `
  query GetProductIso2022($id: ID!) {
    getProductIso2022(id: $id) {
      id
      productID
      iso2022ID
      product {
        id
        distributorId
        hasImage
        hasIso2022
        hasDistributor
        productKey
        name
        listPrice
        manufacturer
        gtin
        asin
        sku
        submittedMakerSeller
        status
        description
        weightGrams
        variantOptions
        keywords
        details
        technicalSpecifications
        videoUrl
        internalNotes
        latestImageAddedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProductIso2022s = /* GraphQL */ `
  query ListProductIso2022s(
    $filter: ModelProductIso2022FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductIso2022s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productID
        iso2022ID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProductIso2022s = /* GraphQL */ `
  query SyncProductIso2022s(
    $filter: ModelProductIso2022FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductIso2022s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        productID
        iso2022ID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArticleIso = /* GraphQL */ `
  query GetArticleIso($id: ID!) {
    getArticleIso(id: $id) {
      id
      articleID
      isoID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticleIsos = /* GraphQL */ `
  query ListArticleIsos(
    $filter: ModelArticleIsoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleIsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleID
        isoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticleIsos = /* GraphQL */ `
  query SyncArticleIsos(
    $filter: ModelArticleIsoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticleIsos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articleID
        isoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArticleIso2022 = /* GraphQL */ `
  query GetArticleIso2022($id: ID!) {
    getArticleIso2022(id: $id) {
      id
      articleID
      iso2022ID
      article {
        id
        type
        mainImageId
        articleApprovalId
        title
        prefix
        key
        published
        wordCount
        authorUserIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      iso2022 {
        id
        title
        description
        plainLanguageTitle
        plainLanguageDescription
        class
        subclass
        division
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArticleIso2022s = /* GraphQL */ `
  query ListArticleIso2022s(
    $filter: ModelArticleIso2022FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleIso2022s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleID
        iso2022ID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArticleIso2022s = /* GraphQL */ `
  query SyncArticleIso2022s(
    $filter: ModelArticleIso2022FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticleIso2022s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        articleID
        iso2022ID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
