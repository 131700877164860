const Product = {
    id: 1,
    title: "Hey Dude Women's Wendy Sox Stone White Size 5 | Women's Shoes | Women's Lace Up Loafers | Comfortable & Light-Weight",
    price: '$59.95',
    details: [
        "Made in USA or Imported",
        "Leather sole",
        "Rounded Toe Design and Flex & Fold: This supports healthy feet with its unique shape giving complete freedom of movement. The Hey Dude Wendy will be an instant favorite in your modern-casual shoe collection and are the perfect women’s shoes."
    ],
    description: "Look great. feel great. the smooth, super-light build of this cap blends sport style and fashion. vertical 3-stripes on the back complement an outlined adidas badge of sport on the front.",
    rating: 4.8,
    thumbnails: [
        {
            id: 1,
            key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
            altText: 'Description of product'
        },
        {
            id: 2,
            key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
            altText: 'Description of product'
        },
        {
            id: 3,
            key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
            altText: 'Description of product'
        },
        {
            id: 4,
            key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
            altText: 'Description of product'
        },
        {
            id: 5,
            key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
            altText: 'Description of product'
        },
        {
            id: 6,
            key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
            altText: 'Description of product'
        }
    ],
    questions: [
        {
            id: 1,
            question: 'Some question',
            votes: 208,
            answers: [
                {
                    id: 1,
                    answer: 'Answer to the question'
                }
            ]
        },
        {
            id: 2,
            question: 'Another Question',
            votes: 2,
            answers: [
                {
                    id: 1,
                    answer: 'Answer to the question'
                },
                {
                    id: 2,
                    answer: 'Another answer to the question'
                }
            ]
        },

    ],
    reviews: [
        {
            id: 1,
            author: 'Anon',
            review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            numberOfLikes: 2,
            dateCreated: new Date().toLocaleDateString(),
            rating: 3,
            images: [
                {
                    id: 1,
                    key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
                    altText: 'Review image 1'
                },
                {
                    id: 2,
                    key: 'https://m.media-amazon.com/images/I/81hmrUHUTJL._AC_UY500_.jpg',
                    altText: 'Review image 2'
                }
            ]
        },
        {
            id: 2,
            author: 'Anon',
            review: 'Review 2 of product.',
            numberOfLikes: 5,
            dateCreated: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toLocaleDateString(),
            rating: 5,
            images: []
        }
    ]
}

export default Product
