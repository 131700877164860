export function resendInvite(api, values) {
   api.getByField('email', values.email)
      .then((user) => {
         api.mutate({
            id: user[0].id,
            resendInvite: true,
            _version: user[0]._version
         })
      })
      .then(() => alert(`Your invite expired. A new invite was sent to ${values.email}`))
      .catch((err) => {
         console.log("Error while resending invite ", err)
      })
}
