import {Button, Flex, Heading} from "@aws-amplify/ui-react";
import CloseIcon from "@mui/icons-material/Close";
import React from 'react'

export const DialogHeading = ({
   heading,
   onClose
}) => {
   return <Flex
      direction="row"
      width="100%"
      gap="1rem"
      paddingLeft="2rem"
      alignItems="center"
   >
      <Heading
         level="4"
         as="h2"
         paddingTop="2rem"
         color="var(--amplify-colors-font-interactive)"
         id="alert-dialog-title"
         width="100%"
      >
         {heading}
      </Heading>

      <Flex
         justifyContent="flex-end"
         alignItems="center"
      >
         <Button
            gap="0.2rem"
            onClick={onClose}
            style={{
               "border": "0px",
               "margin": "1rem 1rem",
               "padding": "1rem 1rem",
               "height": "fit-content"
            }}
         >
            <CloseIcon />
         </Button>
      </Flex>
   </Flex>;
}
