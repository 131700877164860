import {Button, Flex, Text, TextField} from "@aws-amplify/ui-react";
import {Dialog} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {confirmRegister, sendEmailVerification} from "../../../../api/auth";
import {DialogHeading} from "../../../common/DialogHeading";
import {displayMessage} from "../../../common/Toast/toastUtilities";

export default function VerifyEmailDialog({
   open,
   setOpen,
   setSignInOpen,
   values,
   setValues
}) {
   const [error, setError] = useState("You must verify your email account to continue.")
   const [verifying, setVerifying] = useState(false)
   const [codeResent, setCodeResent] = useState(false)

   const handleChange = (event) => {
      setValues({
         ...values,
         [event.target.name]: event.target.value
      })
   }

   const handleSubmit = (event) => {
      event.preventDefault()
      setVerifying(true)
      setError("")
      setCodeResent(false)

      for (let index = 0; index < event.target.form.length; ++index) {
         if (event.target.form[index].localName === "input") {
            if (!event.target.form[index].validity.valid) {
               setVerifying(false)
               return
            }
         }
      }

      if (values.username !== "") {
         confirmRegister(values)
            .then(() => {
               setValues({
                  username: '',
                  password: '',
                  code: ''
               })
            })
            .catch((error) => {
               console.log("Error", error)
               switch (error.code) {
                  case "CodeMismatchException":
                     setError("Sorry, the code you entered did not match.")
                     break
                  default:
                     setError("Sorry, we have encountered an error")
               }
            })
            .finally(() => {
               setVerifying(false)
            })
      } else {
         setError("An email hasn't been provided")
      }
   }

   const handleResend = () => {
      setError("")

      sendEmailVerification(values)
         .then(() => {
            setCodeResent(true)
         })
         .catch((error) => {
            console.log("Error", error)
            setError("Sorry, we have encountered an error")
         })
         .finally(() => {
            setVerifying(false)
         })
   }

   return (<>
      <Dialog
         fullWidth
         maxWidth="md"
         open={open}
         onClose={() => setOpen(false)}
         aria-labelledby="alert-dialog-title"
      >
         <Flex
            direction="column"
            width="100%"
            gap="1rem"
         >

            <DialogHeading
               heading="Verify Email"
               onClose={() => setOpen(false)}
            />

            {error === ""
               ? null
               : displayMessage(() => {
                  setError("")
               }, "error", error)}

            {codeResent
               ? displayMessage(() => {
                  setCodeResent(false)
               }, "info", `A new verification code was sent to ${values.username}`)
               : null}

            <Flex
               direction="column"
               width="100%"
               gap="1rem"
               padding="2rem"
               paddingTop="1rem"
               as="form"
            >
               <TextField
                  name="code"
                  label={<Text>
                     Verification Code
                     <Text
                        as="span"
                        fontSize="0.8rem"
                        color="red"
                        aria-hidden={true}
                     >
                        {' '} (required)
                     </Text>
                  </Text>}
                  placeholder="Enter your code"
                  autoComplete="code"
                  isRequired={true}
                  value={values.code}
                  onChange={handleChange}
               />

               <Text
                  fontSize="0.8rem"
                  fontStyle="italics"
               >
                  Need a new code? Click <Link
                  to=""
                  onClick={(event) => {
                     event.preventDefault()
                     handleResend(event)
                  }}
               >here</Link> to send a new one.
               </Text>

               <Button
                  variation="primary"
                  size="small"
                  autoFocus
                  type="submit"
                  disabled={verifying}
                  onClick={(event) => {
                     event.preventDefault()
                     handleSubmit(event)
                  }}
               >
                  {verifying
                     ? "Verifying ... "
                     : "Verify"}
               </Button>

               <Button
                  variation="link"
                  onClick={() => {
                     setOpen(false)
                     setSignInOpen(true)
                  }}
               >Back to Sign In</Button>
            </Flex>
         </Flex>
      </Dialog>
   </>)
}


