import * as React from "react"
import {View, Image} from "@aws-amplify/ui-react"
import {Link} from "react-router-dom";
import AtNetworkLogo from '../../common/assets/images/at-network-logo.svg'


export default function Logo() {
   return <View
      width="unset"
      height="100px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      grow="1"
      shrink="1"
      basis="0"
      position="relative"
      padding="0px 0px 0px 0px"
   >
      <View
         height="100px"
         display="block"
      >
         <Link to="/">
            <Image
               alt="AT Network logo"
               src={AtNetworkLogo}
               objectFit="cover"
               paddingLeft="1rem"
               height="100%"
               opacity="100%"
            />
         </Link>
      </View>
   </View>
}
