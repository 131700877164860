import {Collection} from "@aws-amplify/ui-react";
import MenuItem from "./MenuItem";

export default function MenuItems({menuItems=[], type="grid", direction="column"}) {
    return (
        <Collection
            type={type}
            direction={direction}
            templateColumns="1fr 1fr"
            columnGap="1rem"
            autoFlow="row"
            alignItems="stretch"
            justifyContent="flex-start"
            items={menuItems}
        >
            {(menuItem , index) => (
                <MenuItem
                    key={menuItem.id}
                    state={menuItem.state ?? 'Unselected'}
                    title={menuItem.title}
                    path={menuItem.path}
                    itemIndex={index}
                />
            )}
        </Collection>
    )
}
