import {Button, createTheme, Flex, Text, ThemeProvider} from '@aws-amplify/ui-react';
import * as React from "react";
import {useEffect, useState} from "react";
import {MdBookmarkBorder, MdOutlineAccountCircle} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {signOut} from "../../../api/auth";
import {useRoles} from "../../common/authenticator/useRoles";
import {Menu, MenuItem} from "@mui/material";
import {useUserAuthenticator} from "../../common/authenticator/useUserAuthenticator";
import Divider from '@mui/material/Divider';

const userNavTheme = createTheme({
   "name": 'user-nav-theme',
   "tokens": {
      "components": {
         "button": {
            "link": {
               "_hover": {
                  "backgroundColor": {"value": '{colors.brand.primary.90.value}'}
               },
               "_focus": {
                  "backgroundColor": {"value": '{colors.brand.primary.90.value}'}
               }
            }
         }
      }
   }
})

export const UserNav = () => {
   const navigate = useNavigate();
   const {hasRole} = useRoles()
   const {user} = useUserAuthenticator()

   const [profileAnchorEl, setProfileAnchorEl] = useState(null)
   const [profileOpen, setProfileOpen] = useState(false)
   const [isAdmin, setIsAdmin] = useState(hasRole(["Admin"]))
   const [navigationLocation, setNavigationLocation] = useState(null)

   const featureFlag = false

   useEffect(() => {
      setIsAdmin(hasRole(["Admin"]))
   }, [hasRole]);

   useEffect(() => {
      if (navigationLocation) {
         setProfileOpen(false)
         navigate(navigationLocation)
      }
   }, [navigationLocation]);

   const handleClickProfileMenu = (event) => {
      setProfileAnchorEl(event.currentTarget)
      setProfileOpen(!profileOpen)
   }

   const handleProfileClose = () => {
      setProfileOpen(false)
   }

   return (<ThemeProvider theme={userNavTheme}>
      <Flex
         direction="row"
         gap="1rem"
         alignItems="flex-start"
         justifyContent="flex-start"
      >
         <Button
            variation="link"
            onClick={() => navigate('/bookmarks')}
            fontWeight="400"
            color="white"
         >
            <MdBookmarkBorder
               color="white"
               size="2rem"
            />
            Bookmarks
         </Button>

         <Button
            id="profile-button"
            variation="link"
            aria-controls={profileOpen
               ? 'profile-menu'
               : undefined}
            aria-haspopup="true"
            aria-expanded={profileOpen
               ? 'true'
               : undefined}
            onClick={handleClickProfileMenu}
         >
            <MdOutlineAccountCircle
               color="white"
               fontSize="2rem"
            />
         </Button>

         <Menu
            id="profile-menu"
            anchorEl={profileAnchorEl}
            open={profileOpen}
            onClose={handleProfileClose}
            MenuListProps={{
               'aria-labelledby': 'profile-button',
            }}
         >
            <Text
               style={{
                  "fontSize": "1.2rem",
                  "padding": ".25rem 1rem"
               }}
            >
               {user?.attributes?.given_name} {user?.attributes?.family_name}
            </Text>

            <Divider
               sx={{
                  "width": "90%",
                  "margin": "auto"
               }}
            />

            <MenuItem
               onClick={() => {
                  handleProfileClose()
                  setNavigationLocation("/profile")
               }}
            >
               Profile
            </MenuItem>

            {isAdmin
               && <MenuItem
                  onClick={() => {
                     handleProfileClose()
                     setNavigationLocation("/moderator/dashboard")
                  }}
               >
                  Moderator Dashboard
               </MenuItem>}

            {featureFlag
               && <MenuItem
                  onClick={() => {
                     handleProfileClose()
                     setNavigationLocation("/articleDashboard")
                  }}
               >
                  Article Dashboard
               </MenuItem>}

            {featureFlag
               && <>
                  <MenuItem
                     onClick={() => {
                        handleProfileClose()
                     }}
                  >
                     Discussion Dashboard
                  </MenuItem>

                  <MenuItem
                     onClick={() => {
                        handleProfileClose()
                     }}
                  >
                     Product Dashboard
                  </MenuItem>
               </>}

            <Divider
               sx={{
                  "width": "90%",
                  "margin": "auto"
               }}
            />

            {featureFlag
               && <>
                  <MenuItem
                     onClick={() => {
                        handleProfileClose()
                     }}
                  >
                     Help and Feedback
                  </MenuItem>
               </>}

            <MenuItem
               onClick={() => {
                  handleProfileClose()
                  signOut()
                     .then(() => {
                        console.log("Logged out")
                     })
               }}
            >
               Logout
            </MenuItem>
         </Menu>
      </Flex>
   </ThemeProvider>);
};
