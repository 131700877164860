import {Flex} from "@aws-amplify/ui-react";
import * as React from "react";
import FooterContact from "./FooterContact";
import FooterNav from "./FooterNav";
import SocialMediaContact from "./SocialMediaContact";

export default function Footer() {
   return (
      <Flex
         direction="column"
         width="100%"
         justifyContent="space-evenly"
         padding="44px 56px 16px 56px"
         backgroundColor="rgba(104,112,120,1)"
      >
         <Flex
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            backgroundColor="rgba(104,112,120,1)"
         >
            <FooterNav />
            <FooterContact />
         </Flex>

         <SocialMediaContact />

      </Flex>
   )
}
