import {CustomQuery} from "./types";

export const getDiscussionsIndex: CustomQuery = {
   queryKey: 'listDiscussions',
   type: "LIST",
   query: /* GraphQL */ `
       query ListDiscussions(
           $filter: ModelDiscussionFilterInput
           $limit: Int
           $nextToken: String
       ) {
           listDiscussions(filter: $filter, limit: $limit, nextToken: $nextToken) {
               items {
                   id
                   userId
                   topicId
                   title
                   body
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
                   user {
                       id
                       firstName
                       lastName
                       displayName
                       profileImage {
                           id
                           productId
                           articleId
                           articleComponentId
                           discussionId
                           reviewId
                           profileUserId
                           bannerUserId
                           url
                           prefix
                           key
                           
                           altText
                           title
                           width
                           height
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                   }
                   comments {
                       items {
                           id
                       }
                   }
                   topic {
                       title
                   }
                   userFollows {
                       items {
                           id
                       }
                   }
                   votes {
                       items {
                           id
                           vote
                       }
                   }
               }
               nextToken
               startedAt
               __typename
           }
       }
   `
};

export const getDiscussionSearch: CustomQuery = {
   queryKey: "searchDiscussions",
   type: "LIST",
   query: /* GraphQL */ `
       query SearchDiscussions(
           $filter: SearchableDiscussionFilterInput
           $sort: [SearchableDiscussionSortInput]
           $limit: Int
           $nextToken: String
           $from: Int
           $aggregates: [SearchableDiscussionAggregationInput]
       ) {
           searchDiscussions(
               filter: $filter
               sort: $sort
               limit: $limit
               nextToken: $nextToken
               from: $from
               aggregates: $aggregates
           ) {
               items {
                   id
                   __typename
               }
               nextToken
               total
           }
       }
   `
}

export const getDiscussionSearchResult: CustomQuery = {
   queryKey: "getDiscussion",
   type: "GET",
   query: /* GraphQL */ `
       query GetDiscussion($id: ID!) {
           getDiscussion(id: $id) {
               id
               userId
               topicId
               title
               body
               user {
                   id
                   firstName
                   lastName
                   displayName
                   profileImage {
                       id
                       productId
                       articleId
                       articleComponentId
                       discussionId
                       reviewId
                       profileUserId
                       bannerUserId
                       url
                       prefix
                       key
                       
                       altText
                       title
                       width
                       height
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
               }
               comments {
                   items {
                       id
                   }
               }
               topic {
                   id
                   title
               }
               userFollows {
                   items {
                       id
                   }
               }
               votes {
                   items {
                       id
                       vote
                   }
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }
   `
}

export const getDiscussionView: CustomQuery = {
   queryKey: "getDiscussion",
   type: "GET",
   query: /* GraphQL */ `
       query GetDiscussion($id: ID!) {
           getDiscussion(id: $id) {
               id
               userId
               topicId
               title
               body
               user {
                   id
                   firstName
                   lastName
                   displayName
                   profileImage {
                       id
                       productId
                       articleId
                       articleComponentId
                       discussionId
                       reviewId
                       profileUserId
                       bannerUserId
                       url
                       prefix
                       key
                       
                       altText
                       title
                       width
                       height
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
               }
               comments {
                   items {
                       id
                       comment
                       createdAt
                       user {
                           id
                           firstName
                           lastName
                           displayName
                           profileImage {
                               id
                               productId
                               articleId
                               articleComponentId
                               discussionId
                               reviewId
                               profileUserId
                               bannerUserId
                               url
                               prefix
                               key
                               
                               altText
                               title
                               width
                               height
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                       }
                       votes {
                           items {
                               id
                               vote
                           }
                       }
                       comments {
                           items {
                               id
                               comment
                               createdAt
                               user {
                                   id
                                   firstName
                                   lastName
                                   displayName
                                   profileImage {
                                       id
                                       productId
                                       articleId
                                       articleComponentId
                                       discussionId
                                       reviewId
                                       profileUserId
                                       bannerUserId
                                       url
                                       prefix
                                       key
                                       
                                       altText
                                       title
                                       width
                                       height
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                               }
                               votes {
                                   items {
                                       id
                                       vote
                                   }
                               }
                               comments {
                                   items {
                                       id
                                       comment
                                       createdAt
                                       user {
                                           id
                                           firstName
                                           lastName
                                           displayName
                                           profileImage {
                                               id
                                               productId
                                               articleId
                                               articleComponentId
                                               discussionId
                                               reviewId
                                               profileUserId
                                               bannerUserId
                                               url
                                               prefix
                                               key
                                               
                                               altText
                                               title
                                               width
                                               height
                                               createdAt
                                               updatedAt
                                               _version
                                               _deleted
                                               _lastChangedAt
                                               __typename
                                           }
                                       }
                                       votes {
                                           items {
                                               id
                                               vote
                                           }
                                       }
                                   }
                               }
                           }
                       }
                   }
               }
               topic {
                   id
                   title
               }
               userFollows {
                   items {
                       id
                   }
               }
               votes {
                   items {
                       id
                       vote
                   }
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }
   `
}
