import {Button, Flex, Text, TextField} from "@aws-amplify/ui-react";
import {Dialog} from "@mui/material";
import {sendForgotPassword, verifyForgotPassword} from 'api/auth'
import Api from 'api/graphql/Api'
import React, {useEffect, useState} from 'react'
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import {DialogHeading} from "../../../common/DialogHeading";
import {displayMessage} from "../../../common/Toast/toastUtilities";
import {
   getFormErrors, getInputError,
   getPasswordMatchError,
   getPasswordValidationErrors,
   isValid
} from "../../../common/validationUtilities";
import {resendInvite} from "./signInUtilities";

const api = new Api('User')

export default function ResetPasswordVerification({
   setSignInOpen,
   open,
   setOpen,
   email = ""
}) {
   const [values, setValues] = useState(
      {
         email,
         code: "",
         password: "",
         confirmPassword: ""
      })
   const [errors, setErrors] = useState({})
   const [resetting, setResetting] = useState(false)
   const [showPassword, setShowPassword] = useState(false)

   useEffect(() => {
      setValues((currentValues) => {
         return {
            ...currentValues,
            email
         }
      })
   }, [email])


   const handleChange = ({target}) => {
      const {
         name,
         value
      } = target

      setValues(
         {
            ...values,
            [name]: value
         })

      if (["password", "confirmPassword"].includes(name)) {
         const password = name === "password"
            ? value
            : values.password
         const confirmPassword = name === "confirmPassword"
            ? value
            : values.confirmPassword

         const newErrors = {
            ...errors,
            [name]: getPasswordValidationErrors(value)
         }

         newErrors.confirmPassword = newErrors.confirmPassword === "The passwords entered do not match"
            ? getPasswordMatchError(password, confirmPassword)
            : newErrors.confirmPassword ?? getPasswordMatchError(password, confirmPassword)

         setErrors(newErrors)

      } else {
         setErrors((currentErrors) => {
            return {
               ...currentErrors,
               [name]: getInputError(target)
            }
         })
      }
   }

   const buildErrors = (name, error) => {
      setErrors((errors) => {
         return {
            ...errors,
            [name]: error
         }
      })
   }

   const handleErrors = (error) => {
      if (error.code === "InvalidParameterException") {
         try {
            resendInvite(api, values)
         }
         catch (resendError) {
            setErrors((currentErrors) => {
               return {
                  ...currentErrors,
                  general: "Sorry, we have encountered an error"
               }
            })
         }
      } else {
         if (["LimitExceededException", "UserNotFoundException"].includes(error.code)) {
            setErrors((currentErrors) => {
               return {
                  ...currentErrors,
                  general: error.message
               }
            })
         } else {
            setErrors((currentErrors) => {
               return {
                  ...currentErrors,
                  general: "Sorry, we have encountered an error"
               }
            })
         }
      }
   }

   const handleSend = (event) => {
      if (values.email === "") {
         setErrors(({
            general,
            ...currentErrors
         }) => {
            return {
               ...currentErrors,
               email: "Please fill out this field."
            }
         })

         return
      }

      sendForgotPassword(values)
         .catch((error) => {
            handleErrors(error)
         })
   }

   const handleSubmit = (event) => {
      event.preventDefault()
      setErrors({})
      setResetting(true)

      if (isValid(event.target.form)) {
         const passwordValidationErrors = getPasswordValidationErrors(values.password)

         if (passwordValidationErrors) {
            buildErrors("password", passwordValidationErrors)
            setResetting(false)

            return
         }

         const passwordMatchErrors = getPasswordMatchError(values.password, values.confirmPassword)
         if (passwordMatchErrors) {
            buildErrors("password", passwordMatchErrors)
            setResetting(false)

            return
         }

         verifyForgotPassword(values)
            .then(() => {
               setOpen(false)
               setSignInOpen(true)
               setValues({
                  ...values,
                  email: "",
                  code: "",
                  password: "",
                  confirmPassword: ""
               })
            })
            .catch(handleErrors)
            .finally(() => {
               setResetting(false)
            })
      } else {
         setErrors(getFormErrors(event.target.form))
         setResetting(false)
      }
   }

   return <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
   >
      <Flex
         direction="column"
         width="100%"
         gap="1rem"
      >
         <DialogHeading
            heading="Reset Password"
            onClose={() => setOpen(false)}
         />

         {!!errors?.general
            ? displayMessage(() => {
               setErrors(({
                  general,
                  ...currentErrors
               }) => {
                  return currentErrors
               })
            }, "error", errors.general)
            : null
         }


         <Text
            fontSize="0.8rem"
            paddingLeft="2rem"
         >
            If you have an account on this platform, you should have received an e-mail with a verification code from
            us
         </Text>

         <Flex
            direction="column"
            width="100%"
            gap="1rem"
            padding="2rem"
            paddingTop="1rem"
            as="form"
         >
            <TextField
               name="email"
               label={<Text>
                  Email
                  <Text
                     as="span"
                     fontSize="0.8rem"
                     color="red"
                     aria-hidden={true}
                  >
                     {' '} (required)
                  </Text>
               </Text>}
               placeholder="Enter your Email"
               autoComplete="email"
               type="email"
               isRequired={true}
               value={values.email}
               hasError={!!errors?.email}
               errorMessage={errors?.email ?? ""}
               onChange={handleChange}
            />

            <TextField
               name="code"
               label={<Text>
                  Verification Code
                  <Text
                     as="span"
                     fontSize="0.8rem"
                     color="red"
                     aria-hidden={true}
                  >
                     {' '} (required)
                  </Text>
               </Text>}
               placeholder="Enter your code"
               autoComplete="code"
               isRequired={true}
               value={values.code}
               hasError={!!errors?.code}
               errorMessage={errors?.code ?? ""}
               onChange={handleChange}
            />

            <ul>
               <li>Your password must contain an upper and lower case letter and a number</li>
               <li>Your password must contain a space or special character from the following
                  list: <br />{"! @ # $ ^ & * _ = . , ' + > < - { } [ ] ( ) : ' \" ? \\ | / ` ~"}</li>
               <li>Your password must have 12-50 characters</li>
            </ul>

            <TextField
               name="password"
               label={<Text>
                  New password
                  <Text
                     as="span"
                     fontSize="0.8rem"
                     color="red"
                     aria-hidden={true}
                  >
                     {' '} (required)
                  </Text>
               </Text>}
               placeholder="Enter your password"
               type={showPassword
                  ? "text"
                  : "password"}
               autoComplete="new-password"
               isRequired={true}
               outerEndComponent={<Button onClick={() => setShowPassword(!showPassword)}>{showPassword
                  ? <MdVisibilityOff title="Hide password" />
                  : <MdVisibility title="Show password" />}</Button>}
               value={values.password}
               hasError={!!errors?.password}
               errorMessage={errors?.password ?? ""}
               onChange={handleChange}
            />

            <TextField
               name="confirmPassword"
               label={<Text>
                  Confirm password
                  <Text
                     as="span"
                     fontSize="0.8rem"
                     color="red"
                     aria-hidden={true}
                  >
                     {' '} (required)
                  </Text>
               </Text>}
               placeholder="Confirm your password"
               type={showPassword
                  ? "text"
                  : "password"}
               autoComplete="new-password"
               isRequired={true}
               outerEndComponent={<Button onClick={() => setShowPassword(!showPassword)}>{showPassword
                  ? <MdVisibilityOff title="Hide password" />
                  : <MdVisibility title="Show password" />}</Button>}
               value={values.confirmPassword}
               hasError={!!errors?.confirmPassword}
               errorMessage={errors?.confirmPassword ?? ""}
               onChange={handleChange}
            />

            <Button
               variation="primary"
               size="small"
               autoFocus
               type="submit"
               disabled={resetting}
               onClick={handleSubmit}
            >
               {
                  resetting
                     ? "Resetting ... "
                     :
                     "Reset password"
               }
            </Button>

            <Button
               variation="link"
               size="small"
               onClick={(event) => {
                  handleSend(event)
               }}
            >
               Resend Code
            </Button>

            <Button
               variation="link"
               size="small"
               onClick={() => {
                  setOpen(false)
                  setSignInOpen(true)
               }}
            >
               Back to Sign In
            </Button>
         </Flex>
      </Flex>
   </Dialog>
}
